<template>
  <div>
    <div v-show="showFirstPage">
      <TileDataContent
        :fids="fids"
        :forecast-label="forecastLabel"
        :dataset="dataset"
        :data-by-id="dataById"
      >
        <template #content-header>
          <TileDataContentHeader>
            <template #second-column>
              <b> {{ $t('components.map.tiles.ElaadLogistics.eVans') }} </b>
            </template>
            <template #third-column>
              <b> {{ $t('components.map.tiles.ElaadLogistics.eTruck') }} </b>
            </template>
          </TileDataContentHeader>
        </template>

        <template #sub-title>
          <p>
            <span class="currentView"> {{ $t('components.map.tiles.ElaadLogistics.pageOne') }} </span> |
            <span
              class="otherView"
              @click="toggleView"
            > {{ $t('components.map.tiles.ElaadLogistics.pageTwo') }} </span>
          </p>
        </template>

        <br>
        <template #content="{ showData }">
          <!-- COUNT -->
          <TileDataContentGroupThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.ElaadLogistics.amount') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('aantal_ebestelautos', year), decimals: 0 }) }}
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('aantal_etrucks', year), decimals: 0 }) }}
              </template>
            </TileDataContentRowThreeColumn>
          </TileDataContentGroupThreeColumn>

          <!-- Depot -->
          <TileDataContentGroupThreeColumn>
            <template #header>
              {{ $t('components.map.tiles.ElaadLogistics.depot') }}
            </template>

            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.TileData.chargingStations') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('laadpunten_bestelautos_depot', year), decimals: 0 }) }}
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('laadpunten_trucks_depot', year), decimals: 0 }) }}
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.ElaadLogistics.unit') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('elektriciteitsvraag_bestelautos_depot', year), decimals: 1 }) }}
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('elektriciteitsvraag_trucks_depot', year), decimals: 1 }) }}
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                MW
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('vermogensvraag_bestelautos_depot', year), decimals: 2 }) }}
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('vermogensvraag_trucks_depot', year), decimals: 2 }) }}
              </template>
            </TileDataContentRowThreeColumn>
          </TileDataContentGroupThreeColumn>

          <!-- Logistieke laadpleinen -->
          <TileDataContentGroupThreeColumn>
            <template #header>
              {{ $t('components.map.tiles.ElaadLogistics.logisticHubs') }}
            </template>

            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.TileData.chargingStations') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('laadpunten_bestelautos_laadpleinen', year), decimals: 0 }) }}
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('laadpunten_trucks_laadpleinen', year), decimals: 0 }) }}
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.ElaadLogistics.unit') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('elektriciteitsvraag_bestelautos_laadpleinen', year), decimals: 1 }) }}
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('elektriciteitsvraag_trucks_laadpleinen', year), decimals: 1 }) }}
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                MW
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('vermogensvraag_bestelautos_laadpleinen', year), decimals: 2 }) }}
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('vermogensvraag_trucks_laadpleinen', year), decimals: 2 }) }}
              </template>
            </TileDataContentRowThreeColumn>
          </TileDataContentGroupThreeColumn>

          <!-- Thuisladen -->
          <TileDataContentGroupThreeColumn>
            <template #header>
              {{ $t('components.map.tiles.ElaadLogistics.homeCharging') }}
            </template>

            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.TileData.chargingStations') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('thuislaadpunten_bestelautos', year), decimals: 0 }) }}
              </template>
              <template #third-column>
                -
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.ElaadLogistics.unit') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('elektriciteitsvraag_bestelautos_thuis', year), decimals: 1 }) }}
              </template>
              <template #third-column>
                -
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                MW
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('vermogensvraag_bestelautos_thuis', year), decimals: 2 }) }}
              </template>
              <template #third-column>
                -
              </template>
            </TileDataContentRowThreeColumn>
          </TileDataContentGroupThreeColumn>
        </template>
        <template #footer>
          ID: {{ fids[0] }}
        </template>
      </TileDataContent>
    </div>

    <div v-show="!showFirstPage">
      <TileDataContent
        :fids="fids"
        :forecast-label="forecastLabel"
        :dataset="dataset"
        :data-by-id="dataById"
      >
        <template #content-header>
          <TileDataContentHeader>
            <template #second-column>
              {{ $t('components.map.tiles.ElaadLogistics.eVans') }}
            </template>
            <template #third-column>
              {{ $t('components.map.tiles.ElaadLogistics.eTruck') }}
            </template>
          </TileDataContentHeader>
        </template>

        <template #sub-title>
          <p>
            <span
              class="otherView"
              @click="toggleView"
            > {{ $t('components.map.tiles.ElaadLogistics.pageOne') }} </span> |
            <span class="currentView"> {{ $t('components.map.tiles.ElaadLogistics.pageTwo') }} </span>
          </p>
        </template>

        <template #content="{ showData }">
          <!-- Publiek -->
          <TileDataContentGroupThreeColumn>
            <template #header>
              {{ $t('accessibility.public') }}
            </template>

            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.TileData.chargingStations') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('publieke_laadpunten_bestelautos', year), decimals: 0 }) }}
              </template>
              <template #third-column>
                -
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.ElaadLogistics.unit') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('elektriciteitsvraag_bestelautos_publiek', year), decimals: 1 }) }}
              </template>
              <template #third-column>
                -
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                MW
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('vermogensvraag_bestelautos_publiek', year), decimals: 2 }) }}
              </template>
              <template #third-column>
                -
              </template>
            </TileDataContentRowThreeColumn>
          </TileDataContentGroupThreeColumn>

          <!-- Binnenstedelijk snelladen -->
          <TileDataContentGroupThreeColumn>
            <template #header>
              {{ $t('components.map.tiles.ElaadLogistics.fastUrban') }}
            </template>

            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.TileData.chargingStations') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('snellaadpunten_bestelautos_binnenstedelijk', year), decimals: 0 }) }}
              </template>
              <template #third-column>
                -
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.ElaadLogistics.unit') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('elektriciteitsvraag_bestelautos_binnenstedelijk', year), decimals: 1 }) }}
              </template>
              <template #third-column>
                -
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                MW
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('vermogensvraag_bestelautos_binnenstedelijk', year), decimals: 2 }) }}
              </template>
              <template #third-column>
                -
              </template>
            </TileDataContentRowThreeColumn>
          </TileDataContentGroupThreeColumn>

          <!-- VZP -->
          <TileDataContentGroupThreeColumn>
            <template #header>
              {{ $t('components.map.tiles.ElaadLogistics.fastVZP') }}
            </template>

            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.TileData.chargingStations') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('snellaadpunten_bestelautos_vzps', year), decimals: 0 }) }}
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('laadpunten_trucks_vzps', year), decimals: 0 }) }}
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.ElaadLogistics.unit') }}
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('elektriciteitsvraag_bestelautos_vzps', year), decimals: 1 }) }}
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('elektriciteitsvraag_trucks_vzps', year), decimals: 1 }) }}
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                MW
              </template>
              <template #second-column>
                {{ showData({ field: generateFieldName('vermogensvraag_bestelautos_vzps', year), decimals: 2 }) }}
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('vermogensvraag_trucks_vzps', year), decimals: 2 }) }}
              </template>
            </TileDataContentRowThreeColumn>
          </TileDataContentGroupThreeColumn>

          <!-- Truckparkings -->
          <TileDataContentGroupThreeColumn>
            <template #header>
              {{ $t('components.map.tiles.ElaadLogistics.truckParking') }}
            </template>

            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.TileData.chargingStations') }}
              </template>
              <template #second-column>
                -
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('laadpunten_trucks_truckparkings', year), decimals: 0 }) }}
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                {{ $t('components.map.tiles.ElaadLogistics.unit') }}
              </template>
              <template #second-column>
                -
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('elektriciteitsvraag_trucks_truckparkings', year), decimals: 1 }) }}
              </template>
            </TileDataContentRowThreeColumn>
            <TileDataContentRowThreeColumn>
              <template #first-column>
                MW
              </template>
              <template #second-column>
                -
              </template>
              <template #third-column>
                {{ showData({ field: generateFieldName('vermogensvraag_trucks_truckparkings', year), decimals: 2 }) }}
              </template>
            </TileDataContentRowThreeColumn>
          </TileDataContentGroupThreeColumn>
        </template>
        <template #footer>
          ID: {{ fids[0] }}
        </template>
      </TileDataContent>
    </div>
  </div>
</template>

<script>
import TileDataContent from '@/components/map/tiles/TileDataContent'
import TiledataMixin from '@/mixins/map/tiledata-mixin'
import TileDataContentHeader from '@/components/map/tiles/TileDataContentHeader'
import TileDataContentRowThreeColumn from '@/components/map/tiles/TileDataContentRowThreeColumn'
import TileDataContentGroupThreeColumn from '@/components/map/tiles/TileDataContentGroupThreeColumn'

export default {
  name: 'TileDataElaadLogs',
  components: {
    TileDataContentRowThreeColumn,
    TileDataContentHeader,
    TileDataContent,
    TileDataContentGroupThreeColumn,
  },
  mixins: [TiledataMixin],
  data() {
    return {
      showFirstPage: true,
    }
  },
  methods: {
    generateFieldName(baseName, year) {
      const suffix = this.forecast.endsWith('low')
        ? 'L'
        : this.forecast.endsWith('mid')
        ? 'M'
        : this.forecast.endsWith('high')
        ? 'H'
        : ''
      return `${baseName}_${suffix}_${year}`
    },
    toggleView() {
      console.log('tickie')
      this.showFirstPage = !this.showFirstPage
    },
  },
}
</script>

<style>
.currentView {
  color: var(--text-muted);
}

.otherView {
  cursor: pointer;

  &:hover {
    color: var(--cta);
  }
}
</style>

