<template>
  <ul class="m-0 p-0 list-unstyled">
    <li
      v-for="item in legendItems"
      :key="item.label + Math.random().toString()"
      class="Legend-Sub-Item d-flex align-items-center"
    >
      <!--  display one of both, with the preference for the icon -->
      <img
        v-if="item.icon"
        :src="item.icon.slug"
        :width="item.icon.width"
      >

      <!-- Dashed square -->
      <div
          v-else-if="item.color && item.type === 'dashed'"
          class="Legend-Item-ColorIndicator dashed"
          :style="`--legend-color: ${item.color}`"
      />

      <div
        v-else-if="item.color"
        class="Legend-Item-ColorIndicator solid"
        :style="`--legend-color: ${item.color}`"
      />

      <!-- always display the label -->
      <div
        class="ml-2 mr-3 Legend-Item-Label"
        v-text="$t(item.label)"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'InMapLegendList',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    legendItems() {
      return this.items.filter(item => (item.label && (item.icon || item.color)))
    },
  },
}
</script>

<style lang="scss">
.Legend-Sub-Item {
  margin: 0.75rem 0;
  max-width: 87%;
}
.Legend-Item-Label {
  max-width: 100%;
}
</style>
