<template>
  <div class="editor-container">
    <vue-editor
      :id="text"
      v-model="content"
    />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-quill-editor'
export default {
  name: 'QuillEditor',
  components: { VueEditor },
  props: {
    initialContent: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      content: this.initialContent,
    }
  },
  watch: {
    initialContent(newValue) {
      if (newValue !== this.content) {
        this.content = newValue
      }
    },
    content(newValue) {
        this.$emit('content-changed', {
          text: this.text,
          content: newValue,
          index: this.index,
        })
    },
  },
}
</script>

<style lang="scss">
.editor-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  margin-right: 12px;
  padding: 1em;
  border: 1px solid lightgray;
  border-radius: 8px;
  background-color: white;

  .ql-toolbar {
    background-color: #f0f0f0;
  }

  .ql-container {
    max-height: 300px;
    overflow-y: auto !important;
  }
}
</style>
