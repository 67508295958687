<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'

import { EventBus } from '@/services/eventbus'

export default {
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
    layerVisibility: {
      type: Boolean,
      default: false,
    },
    minZoom: {
      required: true,
      type: Number,
    },
    maxZoom: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      layerName: 'lb_ms_kabel',
      type: 'line',
      icon: '',
      paint: {
        'line-color': [
          'match',
          ['to-string', ['get', 'Spanningsniveau']],
          '3', 'rgba(0,255,52,0.7)',
          '6', 'rgba(43,171,61,0.7)',
          '10', 'rgba(152,1,157,0.7)',
          '20', 'rgba(88,212,255,0.7)',
          /* default value */ '#000'
        ],
        'line-width': 1.5,
      },
      layout: {
        'visibility': this.layerVisibility ? 'visible' : 'none',
      },
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
    ]),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    source() {
      return {
        url: `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        layer: this.sourceName,
      }
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
  },
  watch: {
    loaded: {
      immediate: true,
      handler(loaded) {
        if (!loaded) return

        this.addLayer()
      },
    },
    layerVisibility: {
      handler(visible) {
        !visible ? this.removeLayerAndSource() : this.addLayer()
      },
    },
  },
  methods: {
    /**
     * Add the layer
     */
    addLayer() {
      this.removeLayerAndSource()

      if (! this.sourceName) return

      if (! this.source.url || ! this.source.layer) return

      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
      }
      if (this.$store.map.getSource(this.sourceName)) {
        this.$store.map.removeSource(this.sourceName)
      }
      this.$nextTick(() => {
        this.$store.map.addSource(this.sourceName, {
          type: 'vector',
          url: this.source.url,
        })

        this.$store.map.addLayer({
          'id': this.layerName,
          'type': this.type,
          'source': this.sourceName,
          'source-layer': this.source.layer,
          'minzoom': this.minZoom,
          'maxzoom': this.maxZoom,
          'paint': {
            ...this.paint
          },
          'layout': {
            ...this.layout
          },
        }, this.labelLayer)
        
        EventBus.$emit('layers.national.loaded', {
          name: this.layerName,
        })
      })
    },
    removeLayerAndSource () {
      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
        this.$store.map.removeSource(this.sourceName)
      }
    },
  },
}
</script>
