/**
 * Format postalcode in 0000 AA format
 *
 * @param {*} postalcode
 * @returns
 */
import { isValidPostalcode } from '@/services/validation'
const isVlaanderen = process.env.VUE_APP_DEPLOYMENT === 'be'

export const formatPostalCode = (postalcode) => {

  if (!isValidPostalcode(postalcode) || isVlaanderen) {
    return postalcode
  }

  return `${postalcode.substr(0, 4)} ${postalcode.substr(-2, 2)}`
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * This also returns true on strings that start with a 0
 */
export const isNumeric = (string) => {
  return /^\d+$/.test(string)
}

export const stringToArrayBuffer = ({ string }) => {
  let buf = new ArrayBuffer(string.length)
  let view = new Uint8Array(buf)
  for (let i=0; i!=string.length; ++i) view[i] = string.charCodeAt(i) & 0xFF
  return buf
}

export const stripSpecialChars = ({ string }) => {
  return string.replace(/[^\w\s]/gi, '')
}
