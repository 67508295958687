<template>
  <div class="UploadDashboard d-flex flex-column w-100 align-items-center">
    <div class="ExportPanelWrapper mt-5 mb-4 flex-grow-1">
      <header class="PanelHeader d-flex justify-content-between py-3 px-4 align-items-center">
        {{ header }}
      </header>

      <Feedback
        v-if="!dismissed"
        :feedback="{ variant: 'logo'}"
        class="mx-4 mt-4 pt-4 pb-4"
        @dismissed="dismissed = true"
      >
        <div style="max-width: 800px">
          {{ $t('best') }} {{ username }},
          <br><br>
          {{ $t('components.export.ExportNationalPanel.msg.line1') }}
          <br>
          <br>
          {{ $t('closingMessage.greetings') }}
          <br>
          {{ $t('closingMessage.evtools') }}
          <br><br>
          <span class="text-muted">
            {{ $t('closingMessage.contact') }} <MailtoSupport />.
          </span>
        </div>

        <b-alert
          show
          variant="warning"
          class="mt-3"
        >
          {{ $t('components.export.ExportNationalPanel.dataAvailableMsg') }}
        </b-alert>
      </Feedback>

      <Feedback
        class="mx-4 mt-4"
        :feedback="feedback"
      />

      <div class="d-flex flex-column p-4">
        <div class="ExportPanel d-flex mt-3 pb-3">
          <aside class="ExportPanel__Aside flex-shrink-0 mr-3">
            <h3 class="ExportPanel__Title">
              {{ $t('components.export.ExportNationalPanel.header') }}
            </h3>
            <div class="w-75 mt-3">
              <p>{{ $t('components.export.ExportNationalPanel.description') }}</p>
            </div>
          </aside>

          <div class="ExportPanel__Main flex-grow-1 mb-3">
            <div class="d-flex align-items-center mt-3 mb-3">
              <span class="ExportPanel__Label mr-3">
                GeoJSON
              </span>

              <b-button
                :disabled="busy"
                variant="cta"
                class="ml-3"
                @click="downloadGeoJSON"
              >
                {{ $t('download') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Feedback from '@/components/form/Feedback'
import MailtoSupport from '@/components/common/MailtoSupport'

import { mapGetters } from 'vuex'
import { saveAs } from 'file-saver'

import { getDateStamp } from '@/helpers/date'
import { statusSlugToNumber } from '@/../shared/services/statusTranslations'
import { checkStatus, returnJson } from '@/helpers/api'
import { Bugfender } from '@bugfender/sdk'
import Vue from 'vue'
import { SOURCE } from '@/services/sourceTranslation'
import { introducedBy } from '@/services/introductionSource'
import { splitNumberAndSuffix } from '../../../shared/helpers/address'
import { isString } from 'lodash'

const countryAbbreviationToLabel = {
  NL: 'Nederland',
  BE: 'België',
}

export default {
  name: 'ExportNationalPanel',
  components: {
    MailtoSupport,
    Feedback,
  },
  data() {
    return {
      country: process.env.VUE_APP_DEPLOYMENT.toUpperCase(),
      chargingpoints: [],
      busy: false,

      dismissed: false,
      feedback: {},
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('config', ['getPhases']),
    username() {
      return this.$auth.user.name
    },
    header() {
      return `Export Portaal (${countryAbbreviationToLabel[this.country]})`
    },
  },
  methods: {
    /***********************************************************************************
     * Obtain Charging points details
     **********************************************************************************/

    /**
     * Load the charging points
     */
    loadChargingPoints: async function () {

      this.busy = true
      this.feedback = {
        message: this.$i18n.t('components.export.ExportNationalPanel.loadingFeedback', { country: countryAbbreviationToLabel[this.country] }),
        variant: 'info',
      }

      // retrieve data recursively. the attribute is after of the response object
      let afterID = null

      do {
        const response = await this.fetchData({ afterID })

        this.chargingpoints = this.chargingpoints.concat(response.chargingpoints)

        afterID = response.afterID
      } while (afterID)

      this.busy = false
      this.feedback = {
        message: this.$i18n.t('components.export.ExportNationalPanel.dataAvailableFeedback', { country: countryAbbreviationToLabel[this.country] }),
        variant: 'info',
      }
    },

    fetchData: async function ({ afterID }) {
      const token = await this.$auth.getTokenSilently()

      return fetch('/api/chargingpoints-export', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          source: SOURCE.ECO_MOVEMENT,
          country: this.country,
          afterID,
        }),
      })
        .then(checkStatus)
        .then(returnJson)
        .catch((e) => {
          Bugfender.error('national export loadChargingPoints: ', e)

          Vue.notify({
            type: 'error',
            title: 'Er is iets misgegaan!',
            text: 'De locaties konden niet worden geladen',
          })
        })
    },

    /***********************************************************************************
     * Generate download files
     **********************************************************************************/

    generateGeoJSON() {
      let geojson = {
        type: 'FeatureCollection',
        features: [],
      }

      /**
       * Add the entries to the GEOJSON
       */
      geojson.features = this.chargingpoints.map((entry) => {
        const { number, suffix } = splitNumberAndSuffix({ number: entry.data.address?.number })
        const code = entry.data.code

        let properties = {
          id: `${code}-${entry.data.properties.id}`,
          uuid: `${entry.data.uuid}`,
          lat: entry.data.coordinates[1]+''.replace('.', ','),
          lng: entry.data.coordinates[0]+''.replace('.', ','),
          street: entry.data.address ? entry.data.address.street : null,
          streetnumber: number,
          streetsuffix: suffix,
          postalcode: entry.data.address && entry.data.address.postalcode ? entry.data.address.postalcode.replace(/\s/g, '') : null,
          municipality: entry.data.address ? entry.data.address.municipality : null,
          city: entry.data.address ? entry.data.address.city : null,
          reference: entry.data.properties.vkb || '',
          source: introducedBy(entry),
          remark: entry.data.properties.remark,
          status: entry.data.properties.status || null,
          statusnumber: statusSlugToNumber({ status: entry.data.properties.status }),
          statusdescription: this.$i18n.t(`participationPanel.options.statusStations.regularLabels.${entry.data.properties.status}`),
          rolloutphase: this.getRolloutPhase({ entry }),
          rolloutschedule: entry.data.prio ? entry.data.prio.order : null,
          gridconnection: ['definitive'].includes(entry.data.properties.status) ? '3*25A' : '',
          nroutlets: entry.data.properties.nroutlets || 2,
          cpo: this.getCpo({ entry }),
        }

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: entry.data.coordinates.map(coord => isString(coord) ? parseFloat(coord) : coord),
          },
          properties,
        }
      })

      return geojson
    },

    // copied from export panel
    getCpo({ entry }) {
      // Stakholder value (manual input) has a priority over imported one //
      return entry.data.properties.stakeholders?.find(stakeholder => stakeholder.type === 'cpo')?.name || entry.data.properties.operator
    },

    // copied from export panel
    getRolloutPhase({ entry }) {
      if (! entry.data.prio) return null

      if (entry.data.prio.customPhase) {
        return this.getPhases.find(phase => phase.uuid === entry.data.prio.customPhase)?.label
      }

      return entry.data.prio.fase
    },

    async downloadGeoJSON() {
      try {
        await this.loadChargingPoints()

        if (! this.chargingpoints.length) {
          throw Error('No charging points available')
        }

        if (this.chargingpoints.some(cp => cp.data.deleted_at)) {
          throw Error('Some charging points are deleted')
        }

        let content = new Blob([JSON.stringify(this.generateGeoJSON(), null, 4)], { type: 'text/plain;charset=utf-8' })
        saveAs(content, `${this.country}-EcoMovement-Laadpalen-${getDateStamp()}.geojson`)
      } catch (e) {
        Bugfender.error('national export downloadGeoJSON: ', e)

        this.$notify({
          type: 'error',
          title: 'Er is iets misgegaan!',
          text: 'Probeer het later opnieuw. Als het probleem zich blijft voordoen, neem dan contact op met de support.',
        })
      }
    },
  },
}
</script>

<style lang="scss">


/**
 * This component combines the html & css of three layered admin components,
 *  because it doesn't need any of the logic of those components
 * TODO: Fix html & CSS so that it makes sense as one component
 */
.UploadDashboard {
  &__Panel, &__Select {
    // width: calc(100% - 2rem);
    min-width: 1100px;
    max-width: 1100px;
  }

  &__Panel {
    // border: 1px solid red;
    background: white;
    box-shadow: 0px 1px 3px 0px #dfe2e5;
  }

  .vSelect {
    min-width: 300px;
  }
}

.ExportPanelWrapper {

  min-width: 1100px;
  max-width: 1100px;
  background: white;
  box-shadow: 0px 1px 3px 0px #dfe2e5;

  header {
    background: var(--primary);
    color: white;
    font-size: 1.5rem;
  }

  form {
    label {
      font-size: 1.1rem;
    }

    small {
      outline: none !important;
      font-size: 1rem;
    }
  }
}

.ExportPanel {
  &__Aside {
    width: 275px;
  }

  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }

    .multiselect {
      width: 455px;
    }
  }

  &__Label {
    width: 175px;
  }

  &__Input {
    position: relative;
    width: 360px;

    &:after {
      content: attr(data-intructions);
      position: absolute;
      top: 3.1rem;
      font-size: 1rem;
      left: 0;
      color: #7F8FA4;

    }
  }

  &__Download {
    text-decoration: underline;
    color: var(--cta);
  }

  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Selecteer';
  }
}
</style>
