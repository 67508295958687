export const defaultBulkTrafficDecisionText = `
<p><strong>VERKEERSBESLUIT GEMEENTE <u>&lt;NAAM GEMEENTE&gt;
</u></strong></p><p><br></p><p><strong>Datum</strong>: <strong><u>&lt;datum&gt;</u></strong>
    </p><p><strong>Registratienummer</strong>: <strong><u>&lt;nummer&gt;</u></strong></p><p><br></p><p><strong>Aanwijzen parkeergelegenheden uitsluitend bestemd voor het opladen van elektrische voertuigen op de</strong></p><ul class=chargingPointsList><li><strong><u>&lt;opsomming locaties&gt;</u></strong></li></ul><p><br></p><p>Het college van burgemeesters en wethouders is, overeenkomstig artikel 18, lid 1 onder d van de Wegenverkeerswet 1994, in deze het bevoegd gezag voor het nemen van dit verkeersbesluit;
    </p><p><strong>&lt;Deze bevoegdheid is op grond van de Algemene mandaatregeling <u>&lt;naam gemeente&gt; &lt;jaartal&gt;</u>, gemandateerd aan de <u>&lt;functieomschrijving ambtenaar, bijv. medewerker beheer Civiele Techniek&gt;</u>.&gt;</strong></p><p>
</p><p><strong>Wettelijk kader</strong></p><p>
</p><p>Wegenverkeerswet 1994, Besluit administratieve bepalingen inzake het wegverkeer (BABW), Reglement verkeersregels en verkeerstekens 1990 (RVV 1990), Uitvoeringsvoorschriften BABW en regels Algemene wet bestuursrecht.</p><p>
</p><p><strong>Aanleiding</strong></p><p> </p><ul><li>Ter uitvoering van de gemeentelijke visie &lt;naam visie laadinfrastructuur&gt; en het gemeentelijk beleid &lt;naam beleidsregel&gt; en om een bijdrage te leveren aan het behalen van (inter)nationale (klimaat)doelstellingen wil de gemeente emissieloos vervoer stimuleren. Oplaadvoorzieningen voor elektrisch vervoer maakt hier onderdeel van uit;</li><li><br></li><li>Elektrisch vervoer draagt bij aan een beter klimaat, het verminderen van geluidhinder en een verbeterde luchtkwaliteit;</li></ul><p><br></p><p><strong>Motivering
</strong></p><ul><li>Bij de in de aanleiding genoemde afweging voor de locatiekeuzes is rekening gehouden met de verkeersbelangen conform de Wegenverkeerswet. Deze afweging maakt integraal onderdeel uit van deze motivering;</li></ul><p>
</p><p><strong>Advies politie</strong></p><p><br></p><p>Op grond van artikel 24 van het BABW is overleg gepleegd met de korpschef, te weten afdeling Verkeer van de <strong><u>&lt;naam politieregio&gt;</u></strong>. De verkeerscoördinator heeft namens de korpschef positief geadviseerd, zoals is opgenomen in Bijlage 1 bij dit besluit.</p><p>
</p><p class="ql-align-center"><strong>BESLUIT:</strong></p><p><br></p><p>Het aanwijzen van twee parkeerplaatsen als parkeergelegenheid alleen bestemd voor het opladen van elektrische voertuigen, door plaatsing van de borden model E8c van het Reglement verkeersregels en verkeerstekens 1990 (RVV 1990) met, indien van toepassing, een onderbord model OB 504 (schuine pijl links en/of rechts), om de parkeerplaatsen aan te duiden, één en ander conform bijgevoegde situatietekening in Bijlage 3, op de volgende locaties in de gemeente <strong><u>&lt;naam gemeente&gt;</u></strong>:</p><ol><li><strong><u>&lt;straatnaam&gt;</u></strong><em>,</em> ter hoogte van nummer <strong><u>&lt;huisnummer&gt;</u></strong><em>;</em></li><li><strong><u>&lt;idem&gt;</u></strong></li></ol><p>    </p><p>onder de opschortende voorwaarde dat:
    </p><p>Het besluit voor een specifieke locatie, zijnde een besluitonderdeel, pas in werking treedt op het moment dat gemeente <strong><u>&lt;naam gemeente&gt;</u></strong> de opdracht geeft tot plaatsing van een openbare laadpaal op die locatie aan een aanbieder;</p><p>
</p><p>Dit besluit heeft een uitvoeringstermijn van twee jaar. Indien binnen twee jaar na onherroepelijk verkeersbesluit nog geen dergelijke opdracht is gegeven en dus nog geen uitvoering is gegeven aan een locatie door plaatsing van een openbare laadpaal en de hierboven genoemde bebording, dan worden van dit besluit de bebording niet is geplaatst, en vervalt de aanwijzing van de twee parkeervakken voor die specifieke locatie.</p><p>
<span style="color: rgb(0, 0, 0); background-color: transparent;">Ter verduidelijking van dit besluit is van iedere afzonderlijke locatie een foto opgenomen in Bijlage 2. De bijlagen maken deel uit van dit besluit.</span>
    </p><p><strong><u>&lt;naam gemeente&gt;, &lt;datum&gt;</u></strong>
    </p><p>BURGEMEESTER EN WETHOUDERS VAN <strong><u>&lt;NAAM GEMEENTE&gt;</u></strong>,</p><p>
</p><p><strong><u>&lt;namens dezen, </u></strong></p><p><strong><u>&lt;functieomschrijving ambtenaar&gt;&gt;</u></strong>
    </p><p><strong><u>&lt;naam ambtenaar&gt;</u></strong></p><p>
</p><p><strong>Bezwaarclausule</strong></p><p>
</p><p>Tegen dit besluit kan degene wiens belang rechtstreeks bij dit besluit is betrokken binnen zes weken na de dag van verzending van dit besluit een gemotiveerd bezwaarschrift indienen bij de burgemeester/het college van burgemeester en wethouders/de gemeenteraad van <strong><u>&lt;naam gemeente&gt;</u></strong>, Postbus <strong><u>&lt;nummer&gt;</u></strong>, <strong><u>&lt;postcode en plaatsnaam&gt;</u></strong>. Het bezwaarschrift moet worden ondertekend en moet tenminste bevatten:</p><p><br></p><ul><li><span style="color: rgb(0, 0, 0); background-color: transparent;">de naam en het adres van de indiener;</span></li><li><span style="color: rgb(0, 0, 0); background-color: transparent;">de dagtekening;</span></li><li><span style="color: rgb(0, 0, 0); background-color: transparent;">een omschrijving van het besluit waartegen het bezwaar is gericht;</span></li><li><span style="color: rgb(0, 0, 0); background-color: transparent;">de gronden van het bezwaar.</span></li></ul><p><br></p><p>Dit bezwaarschrift schorst de werking van het besluit niet. U kunt de voorzieningenrechter verzoeken een voorlopige voorziening te treffen indien onverwijlde spoed, gelet op de betrokken belangen, dat vereist. Voorwaarde is dat verzoeker ook een bezwaarschrift heeft ingediend. Een verzoek moet worden ingediend bij de voorzieningenrechter van de sector bestuursrecht van de Rechtbank <strong><u>&lt;naam rechtbank&gt;</u></strong>, Postbus <strong><u>&lt;nummer&gt;</u></strong>, <strong><u>&lt;postcode en plaatsnaam&gt;</u></strong>.
    </p><p>Dit besluit ligt gedurende de termijn van zes weken na de dag van bekendmaking ter inzage bij het &lt;locatie gemeentehuis&gt;. Het kan worden ingezien op <strong><u>&lt;dagen&gt;</u></strong> van <strong><u>&lt;tijdstip&gt;</u></strong> uur tot <strong><u>&lt;tijdstip&gt;</u></strong> uur en op <strong><u>&lt;avond&gt;</u></strong> van <strong><u>&lt;tijdstip&gt;</u></strong> uur tot <strong><u>&lt;tijdstip&gt;</u></strong> uur.
</p><p>
</p><p><u style="color: rgb(0, 0, 0); background-color: transparent;">Bijlage 1</u></p><p><strong style="color: rgb(0, 0, 0);"><u>&lt;advies korpschef&gt;</u></strong></p><p><br></p><p><u style="color: rgb(0, 0, 0);">Bijlage 2.1 </u><strong style="color: rgb(0, 0, 0);"><u>&lt;nummer afhankelijk van aantal locaties op plankaart&gt;</u></strong></p><p><strong style="color: rgb(0, 0, 0);"><u>&lt;locatiebesluit per locatie&gt;</u></strong></p>
`;
