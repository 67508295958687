<template>
  <div class="LocatieInformatie__Content">
    <div class="LocatieInformatie__Attachments mb-2">
      <strong>{{ $t('components.sidebar.LocatieInformatieContent.attachments') }}</strong>
      <template v-if="!isLoadingAws">
        <Lightbox
          v-if="images.length > 0"
          :gallery="images"
        >
          <template #silentbox-item="{ item }">
            {{ $t(item.description) }}
            <div class="ratio ratio-16x9">
              <div class="inner">
                <div
                  class="LocatieInformatie__Attachments__Image__Inline"
                  title="Download afbeelding"
                  @click.stop
                >
                  <a
                    :href="item.src"
                    :download="item.filename"
                  >
                    <SvgIcon icon="cloud-download-alt-solid" />
                  </a>
                </div>
                <img :src="item.src">
              </div>
            </div>
          </template>
        </Lightbox>
        <p v-else>
          {{ $t('components.sidebar.LocatieInformatieContent.noAttachments') }}
        </p>
      </template>
      <p v-else>
        {{ $t('loading') }} <b-spinner
          small
          variant="primary"
          label="Spinning"
        />
      </p>
    </div>

    <p v-if="remark">
      <strong>{{ $t('remark') }}</strong><br>
      {{ remark }}
    </p>

    <template v-if="address">
      <p>
        <strong>
          {{ $t('address.title') }} <ButtonCopyValue :value="address.formatted_address || ''" />
        </strong>

        <br>

        {{ address.simple_address }} <br>
        {{ address.postalcode }} {{ address.city }}
      </p>

      <p v-if="address.source">
        <strong>{{ $t('components.sidebar.LocatieInformatieContent.addressSource') }}</strong> <br>
        {{ addressSource }}
      </p>

      <p v-if="evse">
        <strong>{{ $t('evse') }}</strong><br>
        {{ evse }}
      </p>

      <p v-if="evse_ids.length">
        <strong>{{ $t('evse_ids') }}</strong><br>
        {{ evse_ids.join(', ') }}
      </p>

      <p v-if="coordinates.lat && coordinates.lng">
        <strong>
          {{ $t('address.coordinates') }} <SvgIcon
            v-if="importedWithoutCoordinates"
            id="no-coordinates-hint"
            icon="info-circle-regular"
          />
        </strong> <br>
        {{ coordinates.lat }}, <br>
        {{ coordinates.lng }}
      </p>
      <b-tooltip
        v-if="importedWithoutCoordinates"
        target="no-coordinates-hint"
        triggers="hover"
      >
        {{ $t('components.sidebar.LocatieInformatieContent.coordatesTooltip') }}
      </b-tooltip>
    </template>
    <p v-else>
      <HintAddressIncomplete />

      <strong>{{ $t('address.title') }}</strong><br>
      Onbekend
    </p>

    <p v-if="status">
      <strong>{{ $t('mapLayersPanel.status.title') }}</strong><br>
      {{ $t(`participationPanel.options.statusStations.regularLabels.${statusLabel}`) }}
    </p>

    <p>
      <strong>{{ $t('components.sidebar.LocatieInformatieContent.currentCPO') }}</strong><br>
      {{ currentCpo.uuid ? currentCpo.name : $t('none') }}
    </p>

    <p v-if="parkinglotIds">
      <strong>{{ $t('mapLayersPanel.municipality.parkingSpacesAmsterdam.title') }}</strong><br>
      {{ parkinglotIds }}
    </p>

    <div v-if="prio && prio.order">
      <strong>{{ $t('components.sidebar.PlanModeSearch.priority.label') }}</strong>
      <p>{{ prio.order }}</p>
    </div>

    <div v-if="hasPhase">
      <strong>{{ $t('phase') }}</strong>
      <p>{{ phase }}</p>
    </div>

    <div v-if="trafficDecision">
      <strong>{{ $t(`${translationsPath}.trafficDecision`) }}</strong>
      <p
        v-for="(date, key) in trafficDecision"
        :key="key"
      >
        <span class="d-block">{{ $t(`${translationsPath}.${key}`) }}</span>
        {{ formatDate(date) }}
      </p>
    </div>

    <p v-if="introducedBy">
      <strong>{{ $t('introducedBy') }}</strong><br>
      {{ introducedBy }}
    </p>

    <p v-if="power">
      <strong>{{ $t('components.sidebar.PlanModeSearch.power') }}</strong><br>
      max. {{ power | formatTokW }}
    </p>

    <p v-if="nroutlets">
      <strong>{{ $t('numChargers') }}</strong><br>
      {{ nroutlets }}
    </p>

    <p v-if="types">
      <strong>{{ $t('components.sidebar.LocatieInformatieContent.connectorTypes') }}</strong><br>
      <span
        v-for="type of types"
        :key="type"
      >
        {{ type }}<br></span>
    </p>

    <AlternativeLocation :predecessor="predecessor" />

    <p>
      <strong>{{ $t('components.sidebar.LocatieInformatieContent.locationManagement') }}</strong>
    </p>

    <p v-if="closingDateParticipation">
      <strong>
        {{
          $t(
            'components.sidebar.LocatieInformatieContent.closingDateParticipation'
          )
        }} </strong><br>
      {{ reversedDateParticipation }}
    </p>

    <p>
      <strong>{{ $t('administrator') }}</strong><br>
      {{ beheerder ? beheerder.name : $t('none') }}
    </p>

    <div class="the-validators">
      <p class="mb-0">
        <strong>{{ $t('validators') }}</strong>
      </p>

      <ul v-if="hasValidators">
        <li
          v-for="validator in validators"
          :key="validator.user_id"
        >
          {{ validator.name }}
        </li>
      </ul>

      <span v-else>
        {{ $t('none') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { reverseDate } from '@/helpers/date'
import { phaseToArray, phaseLabelByUuid } from '@/helpers/phases'
import chargingpointDataMixin from '@/mixins/chargingpoint/chargingpointDataMixin'
import chargingpointEditMixin from '@/mixins/chargingpoint/chargingpointEditMixin'

import chargingpointAttachmentsMixin from '@/mixins/chargingpoint/chargingpointAttachmentsMixin'
import Lightbox from '@/components/common/lightbox/Lightbox'
import SvgIcon from '@/components/common/SvgIcon'

import { attachmentIDToDescription } from '@/../shared/valueholders/attachments'
import ButtonCopyValue from '@/components/common/ButtonCopyValue'
import HintAddressIncomplete from '@/components/map/sidebar/HintAddressIncomplete'
import AlternativeLocation from '@/components/map/sidebar/AlternativeLocation'

export default {
  components: { AlternativeLocation, HintAddressIncomplete, Lightbox, SvgIcon, ButtonCopyValue },
  mixins: [chargingpointDataMixin, chargingpointAttachmentsMixin, chargingpointEditMixin],
  data () {
    return {
      translationsPath: 'components.sidebar.LocatieInformatieContent',
    }
  },
  computed: {
    ...mapGetters('config', {
      config: 'config',
      participationConfigs: 'participationConfigs',
      getPhases: 'getPhases',
    }),
    images() {
      return Object.values(this.attachments)
          .filter(attachment => !!attachment.url)
          .map(attachment => ({
            src: attachment.url,
            description: attachmentIDToDescription({ id: attachment.id }),
            filename: attachment.filename.replace('-edited', ''),
          }))
    },
    hasPhase() {
      return phaseToArray(this.prio?.customPhase).length || phaseToArray(this.prio?.fase).length
    },
    phase() {
      const phases = phaseToArray(this.prio?.fase)
      const customPhases = phaseToArray(this.prio?.customPhase)
      const selectedPhases = [
        ...phases.map(phase => `Fase ${phaseLabelByUuid(this.getPhases, phase)}`),
        ...customPhases.map(phase => phaseLabelByUuid(this.getPhases, phase)),
      ]

      return selectedPhases.length ? selectedPhases.join(', ') : this.$t('none')
    },
    parkinglotIds() {
      return this.chargingpoint.data.properties.parkinglotIds?.join(', ')
    },
    closingDatesStation() {
      return this.chargingpoint.data.participation?.closingDates || []
    },
    closingDateParticipation() {
      return this.closingDatesStation.at(-1)
    },
    reversedDateParticipation() {
      return reverseDate(this.closingDateParticipation)
    },
  },
  watch: {
    id() {
      this.$_chargingpointAttachmentsMixin_cleanFetchAttachments()
    },
  },
  created() {
    this.$_chargingpointAttachmentsMixin_cleanFetchAttachments()
  },
  methods: {
    formatDate (date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale)
    },
  },
}
</script>
