import {image} from '@/helpers/assets'
import {USER_ROLE} from '@/../shared/valueholders/userRoles'
import {GEOPGRAPHY_TYPE} from '@/../shared/valueholders/geography-type'
import {planmodeLayerDetails} from '@/data/planmodeLayerDetails'

export const layers = [
  /**
   * Planmode layers (statuses)
   */
  ...planmodeLayerDetails,

  /**
   * The results layer (RegularPrognoseLayer)
   */
  {
    id: 'hexagons',
    label: 'mapLayersPanel.forecast.results.title',
    ignore: true,
    required: true,
    type: 'results',
    sourceNameHint: 'prognose',
    tooltip: 'mapLayersPanel.forecast.results.tooltip',
  },

  /**
   * UI layers
   */
  {
    id: 'line-with-distance',
    label: 'Afstandsmeting lijn',
    ignore: false,
    required: false,
    type: 'ui',
    sourceNameHint: 'charger-geocoder-line',
    tooltip: 'a line connecting the chosen location and the geocoded location',
  },

  /**
   * forecast layers
   */
  {
    id: 'determinative',
    label: 'components.map.tiles.NeighborhoodData.determinative',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: false,
    },
  },
  {
    id: 'fastcharge_elaad',
    label: 'components.map.tiles.NeighborhoodData.fastcharge_elaad',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataElaadFastcharge',
      isLimitedForecast: true,
      availableYears: [2025, 2030, 2035],
      dataset: ['laadpunt'],
      propertyWithData: 'laadpunt_',
      propertyWithTileID: 'buurtcode',
    },
  },
  {
    id: 'fastcharge_overmorgen_neighborhoods',
    label: 'components.map.tiles.NeighborhoodData.fastcharge_overmorgen_neighborhoods',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataOMNeighborhoods',
      isLimitedForecast: true,
      availableYears: [2025, 2030],
      propertyWithData: 'ev_kortp_binnencor_',
      propertyWithTileID: 'bu_code',
      breakpoints: [7, 14, 28],
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.REGION,
            slug: 'west-nederland',
          },
        ],
      },
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
  {
    id: 'fastcharge_overmorgen_corridors',
    label: 'components.map.tiles.NeighborhoodData.fastcharge_overmorgen_corridors',
    ignore: true,
    required: false,
    status: 'primary',
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataOMCorridors',
      isLimitedForecast: true,
      availableYears: [2025, 2030],
      propertyWithData: 'kWh_jr_km_',
      propertyWithTileID: 'corridor_nummer',
      unit: '*',
      unitDescription: '* kWh / jaar / km, beiden rijrichtingen',
      breakpoints: [55000, 125000, 180000],
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.REGION,
            slug: 'west-nederland',
          },
        ],
      },
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
  {
    id: 'logistics_elaad',
    label: 'components.map.tiles.NeighborhoodData.logistics_elaad',
    ignore: true,
    required: false,
    status: 'primary',
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataElaadLogistics',
      isLimitedForecast: true,
      availableYears: [2025, 2030, 2035, 2040, 2045, 2050],
      propertyWithData: 'mwj_tot_trucks_bestel_',
      propertyWithTileID: 'bu_code',
      breakpoints: [50, 150, 300],
      unit: 'MWh/jaar',
    },
  },
  {
    id: 'goral_logistiek',
    label: 'components.map.tiles.NeighborhoodData.goral_logistiek',
    ignore: true,
    required: false,
    status: 'primary',
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataGoralLogistics',
      isLimitedForecast: true,
      isConfigurable: true, // this forecast layers' source has to be configured in the layer config panel
      availableYears: [2025, 2030, 2035],
      propertyWithData: 'bedrijfsterrein_mwh_tot_depot_',
      propertyWithTileID: 'bt_terrein',
      breakpoints: [25, 50, 75],
      unit: 'MWh/jaar',
    },
    sourceNameHint: 'logistiek_goral', // source name hint is used in this way: -> nl_g_0202_${sourceNameHint}
    tooltip: 'De resultaten van de GORAL prognose voor bedrijventerreinen, deze zijn op kavelniveau',
  },
  {
    id: 'logistics_overmorgen',
    label: 'components.map.tiles.NeighborhoodData.logistics_overmorgen',
    ignore: true,
    required: false,
    status: 'primary',
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataOMLogistics',
      isLimitedForecast: true,
      availableYears: [2025, 2026, 2027, 2028, 2029, 2030],
      propertyWithData: 'mwj_e-bestel_e-trucks_depot_',
      propertyWithTileID: 'bu_code',
      breakpoints: [50, 150, 300],
      unit: 'MWh/jaar',
    },
  },
  {
    id: 'logistics_overmorgen_ac',
    label: 'components.map.tiles.NeighborhoodData.logistics_overmorgen_ac',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataRegularChargingNeighborhood',
      isLimitedForecast: true,
      availableYears: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      propertyWithData: 'ldpt_tot_',
      propertyWithTileID: 'bu_code',
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.MUNICIPALITY,
            code: '0202',
            slug: 'arnhem',
          },
        ],
      },
    },
  },
  {
    id: 'logistics_overmorgen_ac_provinces',
    label: 'components.map.tiles.NeighborhoodData.logistics_overmorgen_ac_provinces',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataRegularChargingNeighborhood',
      isLimitedForecast: true,
      availableYears: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      propertyWithData: 'ldpt_tot_',
      propertyWithTileID: 'bu_code',
      province: true,
    },
  },
  {
    id: 'inhabitants',
    label: 'participants.inhabitants',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: false,
    },
  },
  {
    id: 'visitors',
    label: 'participants.visitors',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: false,
    },
  },
  {
    id: 'commuters',
    label: 'participants.commuters',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: false,
    },
  },
  {
    id: 'gemeenteprognoses',
    label: 'components.map.tiles.NeighborhoodData.municipalityForecast',
    ignore: true,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      forecastDataTemplateComponent: 'TileDataGemeenteprognose',
      isLimitedForecast: true,
      availableYears: [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      dataset: [''],
      propertyWithData: 'laadpunt_',
      propertyWithTileID: 'gemeentecode',
    },
  },
  {
    id: 'carsharing',
    label: 'components.map.tiles.NeighborhoodData.carsharing',
    ignore: true,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      dataset: ['deelauto'],
      propertyWithData: 'deelauto_ev_',
      propertyWithTileID: 'buurtcode',
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.PROVINCE,
            code: 28,
            slug: 'zuid-holland',
          },
        ],
      },
    },
  },
  {
    id: 'logistics',
    label: 'components.map.tiles.NeighborhoodData.logistics',
    ignore: true,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      propertyWithData: 'logistiek_totaal_ev_',
      propertyWithTileID: 'buurtcode',
      dataset: ['logistiek_totaal', 'bouw', 'detailhandel', 'post'],
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.PROVINCE,
            code: 28,
            slug: 'zuid-holland',
          },
        ],
      },
    },
  },
  {
    id: 'taxis',
    label: 'components.map.tiles.NeighborhoodData.taxis',
    ignore: true,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      propertyWithData: 'taxi_ev_',
      propertyWithTileID: 'buurtcode',
      dataset: ['taxi'],
    },
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.PROVINCE,
            code: 28,
            slug: 'zuid-holland',
          },
        ],
      },
    },
  },
  {
    id: 'elaad_buurt_low',
    label: 'components.map.tiles.NeighborhoodData.elaadNeighborhoodL',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      forecastDataTemplateComponent: 'TileDataElaadForecast',
      propertyWithData: 'aantal_evs_L_',
      propertyWithTileID: 'BU_CODE',
      availableYears: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2035, 2040, 2045, 2050],
      breakpoints: [150, 350, 850],
    },
  },
  {
    id: 'elaad_buurt_mid',
    label: 'components.map.tiles.NeighborhoodData.elaadNeighborhoodM',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      forecastDataTemplateComponent: 'TileDataElaadForecast',
      propertyWithData: 'aantal_evs_M_',
      propertyWithTileID: 'BU_CODE',
      availableYears: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2035, 2040, 2045, 2050],
      breakpoints: [150, 350, 850],
    },
  },
  {
    id: 'elaad_buurt_high',
    label: 'components.map.tiles.NeighborhoodData.elaadNeighborhoodH',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      forecastDataTemplateComponent: 'TileDataElaadForecast',
      propertyWithData: 'aantal_evs_H_',
      propertyWithTileID: 'BU_CODE',
      availableYears: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2035, 2040, 2045, 2050],
      breakpoints: [150, 350, 850],
    },
  },
  {
    id: 'elaad_logistics_low',
    label: 'components.map.tiles.NeighborhoodData.elaadLogisticsL',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      forecastDataTemplateComponent: 'TileDataElaadLogs',
      propertyWithData: 'MWh/Jaar_Total_L_',
      propertyWithTileID: 'bu_code',
      availableYears: [2025, 2026, 2027, 2028, 2029, 2030, 2035, 2040, 2045, 2050],
      breakpoints: [0, 50, 100],
      unit: 'MWh/jaar',
    },
  },
  {
    id: 'elaad_logistics_mid',
    label: 'components.map.tiles.NeighborhoodData.elaadLogisticsM',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      forecastDataTemplateComponent: 'TileDataElaadLogs',
      propertyWithData: 'MWh/Jaar_Total_M_',
      propertyWithTileID: 'bu_code',
      availableYears: [2025, 2026, 2027, 2028, 2029, 2030, 2035, 2040, 2045, 2050],
      breakpoints: [0, 60, 120],
      unit: 'MWh/jaar',
    },
  },
  {
    id: 'elaad_logistics_high',
    label: 'components.map.tiles.NeighborhoodData.elaadLogisticsH',
    ignore: true,
    required: false,
    type: 'forecast',
    config: {
      isNeighborhoodForecast: true,
      isLimitedForecast: true,
      forecastDataTemplateComponent: 'TileDataElaadLogs',
      propertyWithData: 'MWh/Jaar_Total_H_',
      propertyWithTileID: 'bu_code',
      availableYears: [2025, 2026, 2027, 2028, 2029, 2030, 2035, 2040, 2045, 2050],
      breakpoints: [0, 75, 150],
      unit: 'MWh/jaar',
    },
  },
  /**
   * Data Layers
   */
  {
    id: 'fuelstations',
    label: 'mapLayersPanel.municipality.gasStation.title',
    icon: {
      slug: image({name: 'legend/fuelpump.svg'}),
      width: 20,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'tankstations',
    tooltip: 'mapLayersPanel.municipality.gasStation.tooltip',
  },
  {
    id: 'fossilfuel_stations',
    label: 'mapLayersPanel.national.gasStation.title',
    icon: {
      slug: image({name: 'legend/fuelpump.svg'}),
      width: 20,
    },
    visible: true,
    status: 'secondary',
    group: 'national',
    type: 'national',
    tooltip: 'mapLayersPanel.national.gasStation.tooltip',
  },
  {
    id: 'lngcng',
    label: 'mapLayersPanel.municipality.LNG_CNG.title',
    icon: {
      slug: image({name: 'legend/fuelpump.svg'}),
      width: 20,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'lngcng',
    tooltip: 'mapLayersPanel.municipality.LNG_CNG.tooltip',
  },
  {
    id: 'pplusr',
    label: 'mapLayersPanel.municipality.p+r.title',
    icon: {
      svg: false,
      slug: image({name: 'legend/Parkandride-icon.svg'}),
      width: 20,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'penr',
    tooltip: 'mapLayersPanel.municipality.p+r.tooltip',
  },
  {
    id: 'park_n_ride',
    label: 'mapLayersPanel.national.p+r.title',
    icon: {
      svg: false,
      slug: image({name: 'legend/Parkandride-icon.svg'}),
      width: 20,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    // sourceNameHint: 'penr',
    tooltip: 'mapLayersPanel.national.p+r.tooltip',
  },
  {
    id: 'hoppin',
    label: 'mapLayersPanel.national.hoppin.title',
    icon: {
      slug: image({name: 'legend/hoppin.png'}),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'national',
    group: 'national',
    sourceNameHint: 'hoppin',
    tooltip: 'mapLayersPanel.national.hoppin.tooltip',
  },
  // this one is for municipalities to configure
  {
    id: 'ls',
    label: 'mapLayersPanel.municipality.lowVoltage.title',
    icon: {
      slug: image({name: 'legend/ls.png'}),
      width: 15,
    },
    visible: true,
    layers: ['ls', 'ovkasten', 'ovkasten-labels'],
    status: 'primary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'ls_kabels',
    tooltip: 'mapLayersPanel.municipality.lowVoltage.tooltip',
  },
  // this one is the national layer, which is actually used most of the time
  {
    id: 'ls_kabels',
    label: 'mapLayersPanel.national.lowVoltage.title',
    icon: {
      slug: image({name: 'legend/ls.png'}),
      width: 15,
    },
    visible: true,
    layers: ['ls_kabels', 'ovkasten', 'ovkasten-labels'],
    status: 'primary',
    type: 'national',
    group: 'energy',
    tooltip: 'mapLayersPanel.national.lowVoltage.tooltip',
  },
  {
    id: 'ms_kabels',
    label: 'mapLayersPanel.national.mediumVoltage.title',
    icon: {
      slug: image({name: 'legend/middenspanning.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'energy',
    // sourceNameHint: 'middenspanning',
    tooltip: 'mapLayersPanel.national.mediumVoltage.tooltip',
  },
  {
    id: 'ls_stations',
    label: 'mapLayersPanel.national.LVcabinets.title',
    icon: {
      slug: image({name: 'legend/middenspanning.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'energy',
    // sourceNameHint: 'middenspanning',
    tooltip: 'mapLayersPanel.national.LVcabinets.tooltip',
  },
  {
    id: 'ms_stations',
    label: 'mapLayersPanel.national.MVcabinets.title',
    icon: {
      slug: image({name: 'legend/middenspanning.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'energy',
    // sourceNameHint: 'middenspanning',
    tooltip: 'mapLayersPanel.national.MVcabinets.tooltip',
  },
  {
    id: 'ms_ls_stations',
    label: 'mapLayersPanel.national.LMVcabinets.title',
    icon: {
      slug: image({name: 'legend/middenspanning.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'energy',
    // sourceNameHint: 'middenspanning',
    tooltip: 'mapLayersPanel.national.LMVcabinets.tooltip',
  },
  {
    id: 'nwegen',
    label: 'mapLayersPanel.municipality.NRoads.title',
    icon: {
      slug: image({name: 'legend/ls.png'}),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'nwegen',
    tooltip: 'mapLayersPanel.municipality.NRoads.tooltip',
  },
  {
    id: 'verkeersstromen',
    label: 'mapLayersPanel.national.trafficFlows.title',
    icon: {
      slug: image({name: 'legend/trafficflow.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    // sourceNameHint: 'gemeentelijke_eigendom',
    tooltip: 'mapLayersPanel.national.trafficFlows.tooltip',
  },
  {
    id: 'zero_emissiezones',
    label: 'mapLayersPanel.national.zero_emissiezones.title',
    icon: {
      slug: image({name: 'legend/zero_emissiezones.jpg'}),
      width: 15,
    },
    layers: ['zero_emissiezones', 'zero_emissiezones-text'],
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    tooltip: 'mapLayersPanel.national.zero_emissiezones.tooltip',
    sourceNameHint: 'zero_emissiezones',
  },
  {
    id: 'zero_emissiezones-text',
    ignore: true,
    status: 'secondary',
    type: 'national',
  },
  {
    id: 'buurtlaag',
    label: 'mapLayersPanel.national.neighborhoods.title',
    icon: {
      slug: image({name: 'legend/neighborhoods.jpg'}),
      width: 15,
    },
    type: 'national',
    group: 'national',
    status: 'secondary',
    visible: false,
    sourceNameHint: 'buurten',
  },
  {
    id: 'wijklaag',
    label: 'mapLayersPanel.national.districts.title',
    icon: {
      slug: image({ name: 'legend/neighborhoods.jpg' }),
      width: 15,
    },
    type: 'national',
    group: 'national',
    status: 'secondary',
    visible: false,
    sourceNameHint: 'wijken',
  },
  {
    id: 'milieuzones',
    label: 'mapLayersPanel.national.milieuzones.title',
    icon: {
      slug: image({name: 'legend/milieuzones.jpg'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    tooltip: 'mapLayersPanel.national.milieuzones.tooltip',
    sourceNameHint: 'milieuzones',
  },
  {
    id: 'witte_vlekken_plan',
    label: 'mapLayersPanel.national.NAL.title',
    icon: {
      slug: image({name: 'legend/white-spots.png'}),
      width: 15,
    },
    visible: false,
    status: 'secondary',
    group: 'external_analysis',
    type: 'national',
    tooltip: 'mapLayersPanel.national.NAL.tooltip',
    sourceNameHint: 'witte_vlekken_plan',
    legendTitle: 'NAL vlakken',
    legendItems: [
      {
        label: 'Public laadpunt bij stedelijkheidsklasse 1-4',
        color: 'green',
      }, {
        label: 'Semi-publiek laadpunt bij stedelijkheidsklasse 1-4',
        color: 'blue',
      }, {
        label: 'Geen (semi)publiek laadpunt bij stedelijkheidsklasse 1-4',
        color: 'white',
      }, {
        label: 'Publiek laadpunt bij stedelijkheidsklasse 5',
        color: 'lightgreen',
      }, {
        label: 'Semi-publiek laadpunt bij stedelijkheidsklasse 5',
        color: 'lightblue',
      }, {
        label: 'Geen (semi)publiek laadpunt bij stedelijkheidsklasse 5',
        color: 'grey',
      },
    ],
  },
  {
    id: 'capaciteit_afname',
    label: 'mapLayersPanel.national.capacity.title',
    type: 'national',
    group: 'national',
    tooltip: 'mapLayersPanel.national.capacity.tooltip',
    status: 'secondary',
    visible: false,
    sourceNameHint: 'Pdok capaciteit afname (Mva)',
    legendTitle: 'components.map.tiles.TileDataPdok.title',
    icon: {
      slug: image({name: 'legend/pdok-capacity.png'}),
      width: 15,
    },
    config: {
      isNeighborhoodForecast: false,
    },
    legendItems: [
      {
        label: 'components.map.tiles.TileDataPdok.legend.item0',
        color: 'hsla(0, 0%, 100%, 0.5)',
      },
      {
        label: 'components.map.tiles.TileDataPdok.legend.item1',
        color: 'hsla(292, 40%, 89%, 0.5)',
      }, {
        label: 'components.map.tiles.TileDataPdok.legend.item2',
        color: 'hsla(288, 39%, 83%, 0.5)',
      }, {
        label: 'components.map.tiles.TileDataPdok.legend.item3',
        color: 'hsla(283, 40%, 76%, 0.5)',
      }, {
        label: 'components.map.tiles.TileDataPdok.legend.item4',
        color: 'hsla(281, 39%, 69%, 0.5)',
      }, {
        label: 'components.map.tiles.TileDataPdok.legend.item5',
        color: 'hsla(273, 38%, 53%, 0.5)',
      }, {
        label: 'components.map.tiles.TileDataPdok.legend.item6',
        color: 'hsla(265, 38%, 41%, 0.5)',
      },
    ],
  },
  {
    id: 'onderstations',
    label: 'mapLayersPanel.national.substations.title',
    type: 'national',
    group: 'national',
    tooltip: 'mapLayersPanel.national.substations.tooltip',
    status: 'secondary',
    visible: false,
    sourceNameHint: 'onderstations',
    legendTitle: 'mapLayersPanel.national.substations.title',
    icon: {
      slug: image({name: 'legend/pdok-onderstation.png'}),
      width: 15,
    },
    legendItems: [
      {
        label: 'mapLayersPanel.national.substations.legendItem1',
        color: '#000000',
      },
      {
        label: 'mapLayersPanel.national.substations.legendItem2',
        color: '	hsla(188, 100%, 29%, 0.5)',
      },
    ],
  },
  {
    id: 'parking',
    label: 'mapLayersPanel.municipality.parkingSpaces.title',
    icon: {
      slug: image({name: 'legend/parkeren.png'}),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'parkeervlakken',
    tooltip: 'mapLayersPanel.municipality.parkingSpaces.tooltip',
  },

  {
    id: 'nl_g_0363_vergunningsgebieden',
    label: 'mapLayersPanel.municipality.permitAreas.title',
    icon: {
      slug: image({name: 'legend/permit-areas.png'}),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'context',
    group: 'municipal',
    layers: [
      'nl_g_0363_vergunningsgebieden',
      'nl_g_0363_vergunningsgebieden_line',
      'nl_g_0363_vergunningsgebieden_text',
    ],
    sourceNameHint: 'vergunningsgebieden',
    tooltip: 'mapLayersPanel.municipality.permitAreas.tooltip',
  },

  {
    id: 'nl_g_0363_vergunningsgebieden_text',
    label: '',
    visible: true,
    ignore: true,
    required: false,
    type: 'context',
  },

  {
    id: 'nl_g_0363_vergunningsgebieden_line',
    label: '',
    visible: true,
    ignore: true,
    required: false,
    type: 'context',
  },

  {
    id: 'parkinglot',
    label: 'mapLayersPanel.municipality.parkingSpacesAmsterdam.title',
    icon: {
      slug: image({name: 'legend/parkinglot.png'}),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'context',
    group: 'municipal',
    layers: ['parkinglot', 'parkinglot_text', 'parkinglot_text_type'],
    sourceNameHint: 'parkeervakken',
    tooltip: 'mapLayersPanel.municipality.parkingSpacesAmsterdam.tooltip',
    legendTitle: 'Parkeervakken',
    legendItems: [
      {
        label: 'Regulier parkeervak',
        color: 'hsla(0,0%,22%,0.4)',
      }, {
        label: 'Geselecteerd parkeervak',
        color: 'orange',
      }, {
        label: 'Zoekresultaat parkeervak',
        color: 'hsla(0,0%,22%, 0.8)',
      }, {
        label: 'eType E8',
        color: 'hsla(0,100%,50%,0.4)',
      }, {
        label: 'eType E10',
        color: 'hsla(230,100%,21%,0.4)',
      }, {
        label: 'Gereserveerd voor andere doeleinden',
        color: 'hsla(196,100%,62%,0.4)',
      },
    ],
  },

  {
    id: 'parkinglot_text',
    label: '',
    visible: true,
    ignore: true,
    required: false,
    type: 'context',
  },

  {
    id: 'parkinglot_text_type',
    label: '',
    visible: true,
    required: false,
    type: 'context',
  },

  {
    id: 'parkeerdruk',
    label: 'mapLayersPanel.municipality.parkingPressure.title',
    icon: {
      slug: image({name: 'legend/parkeerdruk.png'}),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'parkeerdruk',
    tooltip: 'mapLayersPanel.municipality.parkingPressure.tooltip',
  },
  {
    id: 'trees',
    label: 'mapLayersPanel.municipality.tree.title',
    icon: {
      slug: image({name: 'legend/boom.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'bomenregister',
    tooltip: 'mapLayersPanel.municipality.tree.tooltip',
  },
  {
    id: 'trees_zone',
    label: 'mapLayersPanel.municipality.treeZone.title',
    icon: {
      slug: image({name: 'legend/boom.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    layers: ['trees_zone', 'trees_zone_z'],
    sourceNameHint: 'boombeschermzone_p',
    tooltip: 'mapLayersPanel.municipality.treeZone.tooltip',
  },
  {
    id: 'zh_nieuwbouwlocaties',
    label: 'mapLayersPanel.national.constructionSites.title',
    icon: {
      slug: image({name: 'legend/construction-regular.png'}),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'national',
    group: 'national',
    tooltip: 'mapLayersPanel.national.constructionSites.tooltip',
  },
  {
    id: 'zh_toplocaties',
    label: 'mapLayersPanel.national.primeLocations.title',
    icon: {
      slug: image({name: 'legend/top-location.png'}),
      width: 15,
    },
    visible: true,
    status: 'primary',
    type: 'national',
    group: 'national',
    tooltip: 'mapLayersPanel.national.primeLocations.tooltip',
  },

  {
    id: 'mrae_plankaart',
    label: 'mapLayersPanel.national.spol.title',
    icon: {
      slug: image({name: 'chargingpoint/svg/disabled.svg'}),
      width: 15,
    },
    visible: false,
    status: 'secondary',
    type: 'national',
    group: 'national',
    tooltip: 'mapLayersPanel.national.spol.tooltip',
    restrictions: {
      include: {
        roles: [USER_ROLE.ADMIN],
        regional: [
          {
            type: GEOPGRAPHY_TYPE.REGION,
            slug: 'mrae',
          },
        ],
      },
    },
  },

  {
    id: 'visitorlocations',
    label: 'mapLayersPanel.municipality.visitorLocation.title',
    icon: {
      svg: false,
      slug: image({name: 'legend/locaties.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'bezoekers',
    tooltip: 'mapLayersPanel.municipality.visitorLocation.tooltip',
  },
  {
    id: 'worklocations',
    label: 'mapLayersPanel.municipality.workLocation.title',
    icon: {
      svg: false,
      slug: image({name: 'legend/werklocaties.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'forenzen',
    tooltip: 'mapLayersPanel.municipality.workLocation.tooltip',
  },
  {
    id: 'licht_infrastructuur',
    label: 'mapLayersPanel.municipality.lightInfrastructure.title',
    icon: {
      svg: false,
      slug: image({name: 'legend/licht-infrastructuur.png'}),
      width: 15,
    },
    layers: [
      'lichtnet',
    ],
    legendItems: [
      {
        label: 'Lichtmasten',
        icon: {
          svg: false,
          slug: image({name: 'legend/licht-infrastructuur.png'}),
          width: 15,
        },
      },
      {
        label: 'lichtnet',
        icon: {
          svg: false,
          slug: image({name: 'legend/lichtnet.png'}),
          width: 15,
        },
      },
    ],
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'licht_infrastructuur',
    tooltip: 'mapLayersPanel.municipality.lightInfrastructure.tooltip',
  },
  {
    id: 'lichtnet',
    label: 'Lichtnet',
    ignore: true,
    status: 'secondary',
    type: 'context',
    sourceNameHint: 'lichtnet',
    tooltip: 'Lichtnet',
  },
  {
    id: 'straatmeubilair',
    label: 'mapLayersPanel.municipality.streetFurniture.title',
    icon: {
      svg: false,
      slug: image({name: 'legend/locaties.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'straatmeubilair',
    tooltip: 'mapLayersPanel.municipality.streetFurniture.tooltip',
  },

  {
    id: 'nieuwbouw',
    label: 'mapLayersPanel.municipality.areaDevelopment.title',
    icon: {
      slug: image({name: 'legend/nieuwbouw.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'nieuwbouw_locaties',
    tooltip: 'mapLayersPanel.municipality.areaDevelopment.tooltip',
  },

  {
    id: 'monuments',
    label: 'mapLayersPanel.municipality.municipalMonuments.title',
    icon: {
      slug: image({name: 'legend/monuments.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'gemeente_monument',
    tooltip: 'mapLayersPanel.municipality.municipalMonuments.tooltip',
  },
  {
    id: 'deelgebied_vlak',
    label: 'mapLayersPanel.municipality.subAreas.title',
    icon: {
      slug: image({name: 'legend/deelgebieden.png'}),
      width: 15,
    },
    layers: ['deelgebied_vlak', 'deelgebied_vlak_text', 'deelgebied_lijn'],
    visible: true,
    status: 'primary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'deelgebied_vlak',
    tooltip: 'mapLayersPanel.municipality.subAreas.tooltip',
  },
  {
    id: 'deelgebied_lijn',
    label: 'Deelgebiedgrensen',
    ignore: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'deelgebied_lijn',
    tooltip: 'Gestippelde lijn die de grensen van de deelgebieden markeren',
  },

  {
    id: 'snellaadzoekgebieden',
    label: 'mapLayersPanel.municipality.fastChargingSearchAreas.title',
    icon: {
      slug: image({name: 'legend/deelgebieden.png'}),
      width: 15,
    },
    layers: ['snellaadzoekgebieden'], // @prop layers: When selected, will be visible in MapLegend //
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'snellaadzoekgebieden',
    tooltip: 'mapLayersPanel.municipality.fastChargingSearchAreas.tooltip',
  },
  {
    id: 'grondeigendom_gemeente',
    label: 'mapLayersPanel.municipality.landOwnershipMunicipality.title',
    icon: {
      slug: image({name: 'legend/grondeigendom_gemeente.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'gemeente_eigendom',
    tooltip: 'mapLayersPanel.municipality.landOwnershipMunicipality.tooltip',
  },

  {
    id: 'grondeigendom_provincie',
    label: 'mapLayersPanel.national.LOprovince.title',
    icon: {
      slug: image({name: 'legend/grondeigendom_provincie.png'}),
      width: 15,
    },
    layers: [
      'p_ut_grondeigendom',
      'p_fl_grondeigendom',
      'p_nh_grondeigendom',
    ],
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    tooltip: 'mapLayersPanel.national.LOprovince.tooltip',
    restrictions: {
      include: {
        regional: [
          {
            type: GEOPGRAPHY_TYPE.REGION,
            slug: 'have-provincial-grondeigendom',
          },
        ],
      },
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
  {
    id: 'p_ut_grondeigendom',
    ignore: true,
    status: 'secondary',
    type: 'national',
  },
  {
    id: 'p_fl_grondeigendom',
    ignore: true,
    status: 'secondary',
    type: 'national',
  },
  {
    id: 'p_nh_grondeigendom',
    ignore: true,
    status: 'secondary',
    type: 'national',
  },

  {
    id: 'grondeigendom_rws',
    label: 'mapLayersPanel.national.LOrijkswaterstaat.title',
    icon: {
      slug: image({name: 'legend/grondeigendom_rws.png'}),
      width: 15,
    },
    visible: false,
    status: 'secondary',
    type: 'national',
    group: 'national',
    tooltip: 'mapLayersPanel.national.LOrijkswaterstaat.tooltip',
    restrictions: {
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
  {
    id: 'nl_l_rws_vzp',
    label: 'mapLayersPanel.national.RWS.title',
    icon: {
      slug: image({name: 'legend/rws-parking.png'}),
      width: 15,
    },
    visible: false,
    status: 'secondary',
    type: 'national',
    group: 'national',
    tooltip: 'mapLayersPanel.national.RWS.tooltip',
  },

  {
    id: 'highway_rest_areas',
    label: 'mapLayersPanel.national.serviceAreas.title',
    icon: {
      slug: image({name: 'legend/car-parking.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    sourceNameHint: 'highway_rest_areas',
    tooltip: 'mapLayersPanel.national.serviceAreas.tooltip',
  },

  {
    id: 'car_pool_parking_areas',
    label: 'mapLayersPanel.national.carpool.title',
    icon: {
      slug: image({name: 'legend/car-pool-parking.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    sourceNameHint: 'car_pool_parking_areas',
    tooltip: 'mapLayersPanel.national.carpool.tooltip',
  },

  {
    id: 'truckparkings',
    label: 'Truckparkings',
    icon: {
      slug: image({name: 'legend/truck-parking.png'}),
      width: 20,
    },
    ignore: true,
    status: 'primary',
    type: 'national',
    group: 'national',
    tooltip: 'Vrachtwagens mogen hier parkeren.',
  },

  {
    id: 'truckparkings_secured',
    label: 'Beveiligde truckparkings',
    icon: {
      slug: image({name: 'legend/truck-parking-secured.png'}),
      width: 20,
    },
    ignore: true,
    status: 'primary',
    type: 'national',
    group: 'national',
    tooltip: 'Vrachtwagens mogen hier beveiligd worden geplaatst.',
  },

  {
    id: 'almere_leidingen',
    label: 'mapLayersPanel.municipality.cables&pipes.title',
    icon: {
      slug: image({name: 'legend/almere_leidingen.png'}),
      width: 15,
    },
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'almere_leidingen',
    tooltip: 'mapLayersPanel.municipality.cables&pipes.tooltip',
  },

  {
    id: 'riool',
    label: 'mapLayersPanel.municipality.sewer.title',
    icon: {
      slug: image({name: 'legend/riool-line.png'}),
      width: 18,
    },
    layers: ['riool'],
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'riool',
    tooltip: 'mapLayersPanel.municipality.sewer.tooltip',
  },

  {
    id: 'telecom',
    label: 'mapLayersPanel.municipality.telecom.title',
    icon: {
      slug: image({name: 'legend/telecom-line.png'}),
      width: 18,
    },
    layers: ['telecom'],
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'telecom',
    tooltip: 'mapLayersPanel.municipality.telecom.tooltip',
  },

  {
    id: 'ecowater',
    label: 'mapLayersPanel.municipality.ecoWater.title',
    icon: {
      slug: image({name: 'legend/ecowater-line.png'}),
      width: 18,
    },
    layers: ['ecowater'],
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'ecowater',
    tooltip: 'mapLayersPanel.municipality.ecoWater.tooltip',
  },

  {
    id: 'gasleiding',
    label: 'mapLayersPanel.municipality.gas.title',
    icon: {
      slug: image({name: 'legend/gasleiding-line.png'}),
      width: 18,
    },
    layers: ['gasleiding'],
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'gasleiding',
    tooltip: 'mapLayersPanel.municipality.gas.tooltip',
  },

  {
    id: 'rioolpers',
    label: 'mapLayersPanel.municipality.sewerDischarge.title',
    icon: {
      slug: image({name: 'legend/rioolpers-line.png'}),
      width: 18,
    },
    layers: ['rioolpers'],
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'rioolpers',
    tooltip: 'mapLayersPanel.municipality.sewerDischarge.tooltip',
  },

  {
    id: 'stadsverwarming',
    label: 'mapLayersPanel.municipality.districtHeating.title',
    icon: {
      slug: image({name: 'legend/stadsverwarming-line.png'}),
      width: 18,
    },
    layers: ['stadsverwarming'],
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'stadsverwarming',
    tooltip: 'mapLayersPanel.municipality.districtHeating.tooltip',
  },

  {
    id: 'milieuprocedure',
    label: 'mapLayersPanel.municipality.environmentalProceduresSoil.title',
    icon: {
      slug: image({name: 'legend/milieuprocedure.png'}),
      width: 15,
    },
    legendItems: [
      {
        label: 'Automatisch',
        color: '#005000',
      }, {
        label: 'Handmatig',
        color: '#000050',
      },
    ],
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'milieuprocedure',
    tooltip: 'mapLayersPanel.municipality.environmentalProceduresSoil.tooltip',
  },

  {
    id: 'stortingskaart_bodem',
    label: 'mapLayersPanel.municipality.depositCardSoil.title',
    icon: {
      slug: image({name: 'legend/stortingskaart_bodem.png'}),
      width: 15,
    },
    legendItems: [
      {
        label: 'Basishygiëne',
        color: '#808000',
      }, {
        label: 'Voorzorgsmaatregelen',
        color: '#800000',
      },
    ],
    visible: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'stortingskaart_bodem',
    tooltip: 'mapLayersPanel.municipality.depositCardSoil.tooltip',
  },

  {
    id: 'truckparkings_bundle',
    label: 'mapLayersPanel.national.truckParking.title',
    icon: {
      slug: image({name: 'legend/truck-parking.png'}),
      width: 20,
    },
    layers: [
      'truckparkings',
      'truckparkings_secured',
    ],
    visible: false,
    status: 'secondary',
    type: 'national',
    group: 'national',
  },

  {
    id: 'visitorslocations_bundle',
    label: 'mapLayersPanel.national.clusterVL.title',
    icon: {
      slug: image({name: 'legend/cluster-visitorslocations.png'}),
      width: 15,
    },
    layers: [
      'bl_winkelgebieden',
      'bl_bedrijventerrein',
      'bl_grotewinkels',
      'bl_restaurants_langs_weg',
    ],
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    restrictions: {
      exclude: {
        roles: [USER_ROLE.CPO],
      },
    },
  },
  {
    id: 'bl_winkelgebieden',
    label: 'Winkelgebied',
    icon: {
      slug: image({name: 'legend/winkelgebieden.png'}),
      width: 15,
    },
    ignore: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    sourceNameHint: 'bl_winkelgebieden',
    tooltip: 'Cluster van Bedrijventerreinen',
  },
  {
    id: 'bl_bedrijventerrein',
    label: 'Bedrijventerreinen',
    icon: {
      slug: image({name: 'legend/bedrijventerrein.png'}),
      width: 15,
    },
    ignore: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    sourceNameHint: 'bl_bedrijventerrein',
    tooltip: 'Cluster van Bedrijventerreinen',
  },
  {
    id: 'bl_grotewinkels',
    label: 'Grote winkel',
    icon: {
      slug: image({name: 'legend/grotewinkels.png'}),
      width: 15,
    },
    ignore: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    sourceNameHint: 'bl_grotewinkels',
    tooltip: 'Cluster van Grote winkel',
  },
  {
    id: 'bl_restaurants_langs_weg',
    label: 'Restaurants langs de weg',
    icon: {
      slug: image({name: 'legend/restaurants_langs_weg.png'}),
      width: 15,
    },
    ignore: true,
    status: 'secondary',
    type: 'national',
    group: 'national',
    sourceNameHint: 'bl_restaurants_langs_weg',
    tooltip: 'Cluster van Restaurants langs de weg (500m)',
  },

  /**
   * LIANDER
   */
  {
    id: 'liander_bundle',
    label: 'mapLayersPanel.national.lianderBundle.context',
    icon: {
      slug: image({name: 'legend/cluster-visitorslocations.png'}),
      width: 15,
    },
    layers: [
      'lb_ls_kabel',
      'lb_ls_station',
      'lb_ms_kabel',
      'lb_ms_station',
      'lb_hs_kabel',
      'lb_hs_kabel_boven',
      'lb_hs_station',
      'lb_gebouw',
      'lb_terrein',
    ],
    visible: true,
    status: 'secondary',
    type: 'national',
    group: 'energy',
  },
  {
    id: 'lb_ls_kabel',
    label: 'mapLayersPanel.national.lianderBundle.lb_ls_kabel',
    status: 'secondary',
    legendTitle: 'mapLayersPanel.national.lianderBundle.lb_ls_kabel',
    legendItems: [
      {
        label: '0.23 kV',
        color: '#FF0091FF',
      }, {
        label: '0.4 kV',
        color: '#FF0000FF',
      }, {
        label: 'Overig',
        color: '#B3B3B3FF',
      },
    ],
  },
  {
    id: 'lb_ls_station',
    label: 'mapLayersPanel.national.lianderBundle.lb_ls_station',
    ignore: true,
    status: 'secondary',
    icon: {
      slug: image({name: 'legend/lb_ls_station.png'}),
      width: 15,
    },
  },
  {
    id: 'lb_ms_kabel',
    label: 'mapLayersPanel.national.lianderBundle.lb_ms_kabel',
    ignore: true,
    status: 'secondary',
    legendTitle: 'mapLayersPanel.national.lianderBundle.lb_ms_kabel',
    legendItems: [
      {
        label: '3 kV',
        color: '#00FF33FF',
      }, {
        label: '6 kV',
        color: '#2BAB3EFF',
      }, {
        label: '10 kV',
        color: '#98019DFF',
      }, {
        label: '20 kV',
        color: '#58d4ff',
      }, {
        label: 'Overig',
        color: '#7C7B7BFF',
      },
    ],
  },
  {
    id: 'lb_ms_station',
    label: 'mapLayersPanel.national.lianderBundle.lb_ms_station',
    ignore: true,
    status: 'secondary',
    icon: {
      slug: image({name: 'legend/lb_ms_station.png'}),
      width: 15,
    },
  },
  {
    id: 'lb_hs_kabel',
    label: 'mapLayersPanel.national.lianderBundle.lb_hs_kabel',
    ignore: true,
    status: 'secondary',
    legendTitle: 'mapLayersPanel.national.lianderBundle.lb_hs_kabel',
    legendItems: [
      {
        label: '0 kV',
        type: 'dashed',
        color: '#FF0091',
      }, {
        label: '10 kV',
        color: '#e575da',
      }, {
        label: '20 kV',
        type: 'dashed',
        color: '#a6c868',
      }, {
        label: '50 kV',
        type: 'dashed',
        color: '#5bb0e5',
      }, {
        label: '110 kV',
        type: 'dashed',
        color: '#2a4fe1',
      }, {
        label: '150 kV',
        type: 'dashed',
        color: '#388c66',
      }, {
        label: '150 kV',
        type: 'dashed',
        color: '#eded56',
      }, {
        label: 'Overig',
        color: '#7C7B7B',
      },
    ]
  },
  {
    id: 'lb_hs_kabel_boven',
    label: 'mapLayersPanel.national.lianderBundle.lb_hs_kabel_boven',
    ignore: true,
    status: 'secondary',
    legendTitle: 'mapLayersPanel.national.lianderBundle.lb_hs_kabel_boven',
    legendItems: [
      {
        label: '50 kV',
        color: '#00AFFF',
      },
      {
        label: '110 kV',
        color: '#006699',
      },
      {
        label: '150 kV',
        color: '#66CC66',
      },
      {
        label: '380 kV',
        color: '#FFFF00',
      },
      {
        label: 'Overig',
        color: '#B3B3B3',
      },
    ],
  },
  {
    id: 'lb_hs_station',
    label: 'mapLayersPanel.national.lianderBundle.lb_hs_station',
    ignore: true,
    status: 'secondary',
    icon: {
      slug: image({name: 'legend/lb_hs_station.png'}),
      width: 15,
    },
  },
  {
    id: 'lb_gebouw',
    label: 'mapLayersPanel.national.lianderBundle.lb_gebouw',
    ignore: true,
    status: 'secondary',
    icon: {
      slug: image({name: 'legend/lb_gebouw.svg'}),
      width: 15,
    },
  },
  {
    id: 'lb_terrein',
    label: 'mapLayersPanel.national.lianderBundle.lb_terrein',
    ignore: true,
    status: 'secondary',
    icon: {
      slug: image({name: 'legend/lb_terrein.svg'}),
      width: 15,
    },
  },

  /**
   * Ignore - added for Admin panel
   */
  {
    id: 'ovkasten',
    label: 'mapLayersPanel.municipality.lvCabinets.title',
    icon: {
      slug: image({name: 'legend/ovkasten.png'}),
      width: 15,
    },
    ignore: true,
    status: 'secondary',
    type: 'context',
    group: 'municipal',
    sourceNameHint: 'ov_kasten',
    tooltip: 'mapLayersPanel.municipality.lvCabinets.tooltip',
  },
  {
    id: 'trees_zone_z',
    label: 'Bomen - beschermzone',
    ignore: true,
    type: 'context',
    group: 'municipal',
    status: 'secondary',
    sourceNameHint: 'boombeschermzone',
    tooltip: 'De beschermzone om bomen.',
  },
]
