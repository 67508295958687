<template>
  <div class="jw-map-wrapper">
    <div
      id="map"
      ref="container"
    />
    <slot v-if="loaded" />
  </div>
</template>

<script>
/**
 * @copyright MIT
 * @author Wouter van Dam (wouter@journeyworks.nl)
 *
 * This is a basic, generic Vue wrapper component for MapBox.
 * This module is included in the source code of the application itself for simplicity and reliability
 */

import 'mapbox-gl/dist/mapbox-gl.css'
import { mapGetters, mapMutations } from 'vuex'
import { Bugfender } from '@bugfender/sdk'

/**
 * The Mapbox instance should not be reactive
 * NOTE: This caused issues when two or more maps were used in a single application
 */

/**
 * A Generic MapBox wrapper component
 */
export default {
  name: 'MapBox',
  provide() {
    const self = this
    return {
      get mapbox() {
        return self.mapbox
      },
      get map() {
        return self.map
      },
      get loaded() {
        return self.loaded
      },
    }
  },
  props: {
    accessToken: {
      type: String,
      required: true,
    },
    mapStyle: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      loaded: false,
      map: null,
      mapbox: null,
    }
  },
  computed: {
    ...mapGetters('layers', [
      'getContextLayerById',
      'getForecastLayerDetails',
      'getResultsLayerDetails',
    ]),
    ...mapGetters('prognose', [
      'visibility',
    ]),
    ...mapGetters('app', [
      'isMapReady',
    ]),
  },
  async mounted() {
    this.debugInDevMode('Mounting Mapbox')

    const { default: mapboxgl } = await import('mapbox-gl') // Dynamically import mapbox-gl
    this.mapbox = mapboxgl
    this.mapbox.accessToken = this.accessToken

    this.map = new this.mapbox.Map(
      Object.assign({
        dragRotate: false,
      }, this.options, {
        container: this.$refs.container,
        style: this.mapStyle,
        preserveDrawingBuffer: true, // Enables export to png
      }),
    )

    // TODO: This solution is not reliable. Find a solution that captures 'empty' tiles
    this.map.on('load', () => {
      this.debugInDevMode('Mapbox Load event')

      this.loaded = true
      this.$emit('load', { map: this.map })
    })

    // make sure that legend and map are in sync
    // NOTE: this does not watch the planmode layers, since they are never really "invisible" but rather more transparent.
    this.map.on('data', this.keepMapInSync)

    // broadcast if chargingpoint source is loaded which means our apps first time load is done and user can interact with the layer
    this.map.on('idle', this.onMapIdle)

    // display grabbing cursor whenever mouse button is pressed
    this.map.on('mousedown', this.handleMousedown)
    this.map.on('mouseup', this.handleMouseup)
  },
  beforeDestroy() {
    this.debugInDevMode('Destroying Mapbox')
    this.setMapReady({ value: false })

    this.$nextTick(() => {
      if (this.map) this.map.remove()
    })

    // deactivate event listener after first (initial) load
    this.map.off('idle', this.onMapIdle)
    this.map.off('mousedown', () => this.handleMousedown)
    this.map.off('mouseup', () => this.handleMouseup)
  },
  methods: {
    ...mapMutations('app', [
      'setAppReady',
      'setMapReady',
    ]),
    handleMousedown() {
      this.map.getCanvas().style.cursor = 'grabbing'
    },
    handleMouseup() {
      this.map.getCanvas().style.cursor = ''
    },
    keepMapInSync(event) {
      if (! this.isMapReady) return
      if (! this.isRelevantUpdate({ event })) return

      // find the updated layer in mapbox and our store
      const mapboxLayer = Object.values(event.style._layers).find(layer => layer.source === event.sourceId)

      // if layer is removed
      if (! mapboxLayer) return

      const isMapboxLayerVisible = mapboxLayer.visibility === 'visible'

      const layer = this.getLayerByMapboxLayer({ event, mapboxLayer })
      if (! layer) {
        this.debugInDevMode('layer not found or active for event:', event)
        return
      }

      // if the layers are out of sync, set mapbox to what it should be... the legend is leading
      const isOutOfSync = isMapboxLayerVisible !== layer.visible
      if (isOutOfSync) {
       //  todo:: see what we want with this.
       // this.handleOutOfSync({ event, layer, mapboxLayer })
      }
    },
    onMapIdle() {
      {
        if (this.map?.getSource('chargingpoints')) {
          this.setAppReady({ value: true })
          // also set map ready, with which the layers are interacting
          this.setMapReady({ value: true })
        }
      }
    },
    debugInDevMode(text, ...args) {
      if (process.env.NODE_ENV === 'development') {
        /* eslint-disable-next-line no-console */
        console.log(text, ...args)
      }
    },
    isRelevantUpdate({ event }) {
      if (event.sourceDataType !== 'visibility') return false

      const mapboxSourceId = 'composite'
      const chargingpointsSourceId = 'chargingpoints'
      const mapLineLayerId = 'line-with-distance'

      if (event.sourceId === mapboxSourceId) return false
      if (event.sourceId === chargingpointsSourceId) return false
      if (event.sourceId.startsWith(mapLineLayerId)) return

      return true
    },
    getLayerByMapboxLayer({ event, mapboxLayer }) {
      let layer = this.getContextLayerById({ id: mapboxLayer.id })
      
      // forecast layers visibility is saved in the prognose store
      const isForecastLayer = (
        this.getForecastLayerDetails
          .concat(this.getResultsLayerDetails)
          .map(layerDetails => layerDetails.id)
          .findIndex(id => id === event.sourceId)
      ) !== -1

      if (isForecastLayer) {
        layer = { visible: this.visibility }
      }

      return layer
    },
    handleOutOfSync({ event, layer, mapboxLayer }) {
      this.$notify({
        type: 'warn',
        title: 'De legenda status komt niet meer overeen met de getoonde kaartlagen',
        text: 'Wij hebben geprobeerd dit weer te herstellen. Mocht je toch een probleem ervaren, ververs dan de pagina.',
      })

      Bugfender.error(`layer out of sync ${mapboxLayer.id}`, event, layer)

      // todo:: see how we want to actually handle this, since it's reseting a bundled layer to the default => not visible
      // if (this.$store.map.getLayer(mapboxLayer.id)) {
      //   let state = layer.visible ? 'visible' : 'none'
      //   this.map.setLayoutProperty(mapboxLayer.id, 'visibility', state)
      // }
    },
  },
}
</script>

<style>
.jw-map-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  user-select: none;
}

.mapboxgl-map {
  width: 100% !important;
  height: 100% !important;
}

</style>
