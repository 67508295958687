const config = {
  nl: {
    code: 'nl',
    country: 'nl',
    // config
    emailAddressSupport: 'support@evtools.nl',
    prognoseModels: ['NAL', 'Sparkcity'],
    // mapbox
    sat: 'https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?bbox={bbox-epsg-3857}&styles=&format=image/jpeg&service=WMS&version=2.0&request=GetMap&crs=EPSG:3857&transparent=true&width=256&height=256&layers=Actueel_orthoHR',
    layers: {
      buurtlaag: 'nl_l_CBS_buurten',
      wijklaag: 'nl_l_CBS_wijken',
      capaciteit_afname: 'nl_l_capaciteitafname',
      elaad_buurt_low: 'nl_l_elaad_buurtprognose_low',
      elaad_buurt_mid: 'nl_l_elaad_buurtprognose_mid',
      elaad_buurt_high: 'nl_l_elaad_buurtprognose_high',
      elaad_logistics_low: 'nl_l_elaad_logistiek_low',
      elaad_logistics_mid: 'nl_l_elaad_logistiek_mid',
      elaad_logistics_high: 'nl_l_elaad_logistiek_high',
      fastcharge_elaad: 'nl_l_elaadnl_prognoses_snelladen',
      fastcharge_overmorgen_neighborhoods: 'nl_r_wnl_snellaadprognose_buurt',
      fastcharge_overmorgen_corridors: 'nl_r_wnl_snellaadprognose_corr',
      logistics_elaad: 'nl_l_elaadnl_logistiek',
      logistics_overmorgen: 'nl_l_westnl_logistiek',
      logistics_overmorgen_ac: 'nl_g_0202_ac_overmorgen',
      logistics_overmorgen_ac_provinces: 'nl_l_ac_overmorgen',
      gemeentegrenzen: 'nl_l_gemeentegrenzen',
      witte_vlekken_plan: 'nl_l_witte_vlekken_plan',
      ls_kabels: 'nl_l_ls_kabels',
      ls_stations: 'nl_l_ls_stations',
      ms_kabels: 'nl_l_ms_kabels',
      milieuzones: 'nl_l_Milieuzones',
      zero_emissiezones: 'nl_l_ZeroEmissiezones',
      ms_stations: 'nl_l_ms_stations',
      ms_ls_stations: 'nl_l_ms_ls_stations',
      fossilfuel_stations: 'nl_l_tankstations',
      onderstations: 'nl_l_onderstations',
      park_n_ride: 'nl_l_penr',
      grondeigendom_rws: 'nl_l_rws_beheergebied',
      nl_l_rws_vzp: 'nl_l_rws_vzp',
      verkeersstromen: 'nl_l_verkeersstromen',
      zh_nieuwbouwlocaties: 'nl_p_zh_nieuwbouwlocaties',
      zh_toplocaties: 'nl_p_zh_toplocaties',
      mrae_plankaart: 'nl_r_mrae_spol',
      hoppin: false,
      carsharing: 'nl_p_zh_deelauto',
      logistics: 'nl_p_zh_stadslogistiek',
      taxis: 'nl_p_zh_taxi',
      gemeenteprognoses: false,
      highway_rest_areas: false,
      car_pool_parking_areas: false,
      visitorslocations_bundle: true,
      truckparkings_bundle: true,
      bl_winkelgebieden: 'nl_l_winkelgebieden',
      bl_bedrijventerrein: 'nl_l_bedrijventerrein',
      bl_grotewinkels: 'nl_l_grotewinkels',
      bl_restaurants_langs_weg: 'nl_l_restaurants_500m_anweg',
      p_ut_grondeigendom: 'nl_p_ut_grondeigendom',
      p_fl_grondeigendom: 'nl_p_fl_grondeigendom',
      p_nh_grondeigendom: 'nl_p_nh_grondeigendom',
      truckparkings_secured: 'nl_l_truckparkings_beveiligd',
      truckparkings: 'nl_l_truckstoplocaties',
      // LIANDER BUNDLE
      liander_bundle: true,
      lb_ls_kabel: 'nl_l_liander_lskabel',
      lb_ls_station: 'nl_l_liander_lsstation',
      lb_ms_kabel: 'nl_l_liander_mskabel',
      lb_ms_station: 'nl_l_liander_msstation',
      lb_hs_kabel: 'nl_l_liander_hskabel',
      lb_hs_kabel_boven: 'nl_l_liander_hskabelboven',
      lb_hs_station: 'nl_l_liander_hsstation',
      lb_gebouw: 'nl_l_liander_gebouw',
      lb_terrein: 'nl_l_liander_terrein',
    }
  },
  be: {
    code: 'be',
    country: 'be',
    // config
    emailAddressSupport: 'support@evtools.be',
    prognoseModels: ['VL'],
    // mapbox
    sat: 'https://geo.api.vlaanderen.be/ofw/wms?bbox={bbox-epsg-3857}&styles=&format=image/jpeg&service=WMS&version=2.0&request=GetMap&crs=EPSG:3857&transparent=true&width=256&height=256&layers=OFW',
    layers: {
      fastcharge_elaad: false,
      fastcharge_overmorgen_neighborhoods: false,
      fastcharge_overmorgen_corridors: false,
      logistics_elaad: false,
      logistics_overmorgen: false,
      gemeentegrenzen: true,
      ls_kabels: false,
      ls_kasten: false,
      ms_kabels: 'be_l_ms_kabels',
      ms_stations: false,
      ms_ls_stations: false,
      milieuzones: false,
      zero_emissiezones: false,
      fossilfuel_stations: 'be_l_tankstations', // false, //
      park_n_ride: 'be_r_vlg_penr',
      grondeigendom_rws: false,
      nl_l_rws_vzp: false,
      verkeersstromen: false,
      zh_nieuwbouwlocaties: false,
      zh_toplocaties: false,
      mrae_plankaart: false,
      hoppin: 'be_r_vlg_hoppin',
      carsharing: false,
      logistics: false,
      taxis: false,
      gemeenteprognoses: 'be_r_vlg_gemeenteprognoses',
      highway_rest_areas: 'be_l_dienstenzones',
      car_pool_parking_areas: 'be_l_carpoolplaatsen',
      visitorslocations_bundle: false,
      bl_winkelgebieden: false,
      bl_bedrijventerrein: false,
      bl_grotewinkels: false,
      bl_restaurants_langs_weg: false,
      p_ut_grondeigendom: false,
      p_fl_grondeigendom: false,
      p_nh_grondeigendom: false,
      truckparkings_secured: false,
      truckparkings: false,
      // LIANDER BUNDLE
      liander_bundle: false,
      lb_ls_kabel: false,
      lb_ls_station: false,
      lb_ms_kabel: false,
      lb_ms_station: false,
      lb_hs_kabel: false,
      lb_hs_kabel_boven: false,
      lb_hs_station: false,
      lb_gebouw: false,
      lb_terrein: false,
    }
  },
}

module.exports = config
