<template>
  <div v-if="visible">  
    <LBTerreinLayer
        :loaded="loaded"
        :layer-visibility="layerVisibility"
        :min-zoom="zoomLevels.ms.min"
        :max-zoom="zoomLevels.ls.max"
    />

    <LBGebouwLayer
        :loaded="loaded"
        :layer-visibility="layerVisibility"
        :min-zoom="zoomLevels.ms.min"
        :max-zoom="zoomLevels.ls.max"
    />
    
    <LBLsCableLayer
      :loaded="loaded"
      :layer-visibility="layerVisibility"
      :min-zoom="zoomLevels.ls.min"
      :max-zoom="zoomLevels.ls.max"
    />
    <LBLsStationLayer
      :loaded="loaded"
      :layer-visibility="layerVisibility"
      :min-zoom="zoomLevels.ls.min"
      :max-zoom="zoomLevels.ls.max"
    />
    <LBMsCableLayer
      :loaded="loaded"
      :layer-visibility="layerVisibility"
      :min-zoom="zoomLevels.ms.min"
      :max-zoom="zoomLevels.ms.max"
    />
    <LBMsStationLayer
        :loaded="loaded"
        :layer-visibility="layerVisibility"
        :min-zoom="zoomLevels.ms.min"
        :max-zoom="zoomLevels.ms.max"
    />
    <LBHsCableLayer
      :loaded="loaded"
      :layer-visibility="layerVisibility"
      :min-zoom="zoomLevels.hs.min"
      :max-zoom="zoomLevels.hs.max"
    />
    <LBHsCableBovenLayer
      :loaded="loaded"
      :layer-visibility="layerVisibility"
      :min-zoom="zoomLevels.hs.min"
      :max-zoom="zoomLevels.hs.max"
    />
    <LBHsStationLayer
        :loaded="loaded"
        :layer-visibility="layerVisibility"
        :min-zoom="zoomLevels.hs.min"
        :max-zoom="zoomLevels.hs.max"
    />
  </div>
</template>

<script>
import userMixin from '@/mixins/common/userMixin'
import { mapGetters } from 'vuex'

// LS
import LBLsCableLayer from "@/components/map/national/LBLsCableLayer.vue";
import LBLsStationLayer from "@/components/map/national/LBLsStationLayer.vue";
// MS
import LBMsCableLayer from "@/components/map/national/LBMsCableLayer.vue";
import LBMsStationLayer from "@/components/map/national/LBMsStationLayer.vue";
// HS
import LBHsCableLayer from "@/components/map/national/LBHsCableLayer.vue";
import LBHsCableBovenLayer from "@/components/map/national/LBHsCableBovenLayer.vue";
import LBHsStationLayer from "@/components/map/national/LBHsStationLayer.vue";
// MISC
import LBGebouwLayer from "@/components/map/national/LBGebouwLayer.vue";
import LBTerreinLayer from "@/components/map/national/LBTerreinLayer.vue";

export default {
  components: {
    LBLsCableLayer,
    LBLsStationLayer,
    LBMsCableLayer,
    LBMsStationLayer,
    LBHsCableLayer,
    LBHsCableBovenLayer,
    LBHsStationLayer,
    LBGebouwLayer,
    LBTerreinLayer,
  },
  mixins: [userMixin],
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      layerName: 'liander_bundle',
      zoomLevels: {
        ls: {
          max: 23,
          min: 16,
        },
        ms: {
          max: 23,
          min: 12,
        },
        hs: {
          max: 23,
          min: 5,
        }
      }
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'DeploymentLayerName',
    ]),
    ...mapGetters('access', [
      'canAccessLayer',
    ]),
    ...mapGetters('layers', [
      'getActiveLayers',
    ]),
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
    visible() {
      if (!this.sourceName) return false
      if (!this.canAccessLayer({ id: this.layerName })) {
        return false
      }

      return true
    },
    bundleLayer() {
      return this.getActiveLayers?.find(layer => layer.id === this.layerName)
    },
    isPrimaryBundleLayer() {
      return this.bundleLayer?.status === 'primary'
    },
    layerVisibility() {
      return this.isPrimaryBundleLayer && this.bundleLayer?.visible
    },
  },
}
</script>

