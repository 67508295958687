<template>
  <div
    v-if="isRoleAllowed && !isFetching"
    class="mt-4"
  >
    <div>
      <b-alert
        :show="showInfoAlert"
        variant="primary"
        class="mt-3 w-100 create-process"
      >
        <ul class="p-1">
          {{ $t(`${translationPath}.requirements`) }}
          <li
            v-if="!hasStatusToStartProcess"
            class="mt-2"
          >
            {{ $t(`${translationPath}.statusTypes`) }}
            <ul class="pl-4">
              <li class="mt-1">
                {{ $t('groupLabels.suggestion') }}
                <img
                  width="15"
                  :src="image({ name: suggestion })"
                  class="mx-1"
                >
              </li>
              <li class="mt-1">
                {{ $t('groupLabels.definitive') }}
                <img
                  width="15"
                  :src="image({ name: definitive })"
                  class="mx-1"
                >
              </li>
            </ul>
          </li>
          <li
            v-if="!isCorrectCpoSelected || !hasWorkflowMunicipality"
            class="mt-1"
          >
            {{ $t(`${translationPath}.selectedCpo`) }}
          </li>
        </ul>
      </b-alert>
      <div v-if="(!isInProcess && getStakeholder.tenants.length)">
        <!-- <h5 class="mt-4">
          Maak een nieuw proces aan
        </h5> -->
        <div
          v-if="(hasUserRole('municipality') || isAdmin) && !stakeholder"
        >
          <strong>{{ $t(`${translationPath}.selectCpo`) }}</strong>
          <b-form-select
            v-model="selectedCpo"
            :options="selectableCpos"
            :disabled="isLoading"
            class="my-2"
          />
        </div>
        <div v-if="(hasCpoAsTenant || hasRoleAndNoStakeholder) && hasStatusToStartProcess">
          <strong>{{ $t(`${translationPath}.selectProcessCategory`) }}</strong>
          <b-form-select
            v-model="processCategory"
            :options="processCategories"
            :disabled="isCategorySelectDisabled"
            class="my-2"
          />
          <b-button
            :disabled="!processCategory || !hasWorkflowMunicipality"
            variant="primary"
            class="mt-3 w-100 create-process"
            @click="startWorkflowProcess"
          >
            <b-spinner
              v-if="isLoading"
              small
            />
            <span>{{ $t(`${translationPath}.createProcess`) }}</span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import chargingpointDataMixin from '@/mixins/chargingpoint/chargingpointDataMixin'
import chargingpointEditMixin from '@/mixins/chargingpoint/chargingpointEditMixin'
import { getCpoById } from '@/data/cpos'
import { image } from '@/helpers/assets'

export default {
  name: 'CreateWorkflowProcess',
  mixins: [chargingpointDataMixin, chargingpointEditMixin],
  data () {
    return {
      isLoading: false,
      isFetching: true,
      hasWorkflowTenant: false,
      municipalityWorkflowTenants: {
        '0363': 'amsterdam',
      },
      allowedMunicipalityRoles: ['admin', 'municipality'],
      allowedCpoRoles: ['admin', 'cpo'],
      translationPath: 'components.sidebar.Tabs.ProcessInfoTab',

      image,
      definitive: 'chargingpoint/png/definitive.png',
      suggestion: 'chargingpoint/png/suggestion.png',
      hasWorkflowMunicipality: false,
      processCategory: null,
      processCategories: null,
      selectedCpo: null,
    }
  },
  computed: {
    ...mapGetters('access', ['getActiveMunicipality', 'getWorkflowTenant', 'getStakeholder']),
    ...mapGetters('config', ['config']),
    ...mapGetters('currentUser', ['getCurrentUserRole']),

    isInProcess() {
      return (
        !!this.chargingpoint.data?.workflowCaseRef ||
        !!this.chargingpoint.data?.externalProcessId
      )
    },
    workflowRole () {
      const tenantRole = this.$auth.user['https://evmaps.nl/workflow'].roles.find(role => role.tenant === this.selectedTenant)
      if (!this.selectedTenant || !tenantRole) return {}

      const role = Object.keys(tenantRole).filter(key => key !== 'tenant')?.[0]
      return { role, value: tenantRole?.[role] }
    },
    workflowRoles () {
      return this.$auth.user['https://evmaps.nl/workflow'].roles
    },
    isCategorySelectDisabled () {
      if (this.hasCpoAsTenant && !this.selectedCpo) {
        return false
      }
      return this.isLoading ||
             (!!this.stakeholder && !this.isCorrectCpoSelected) ||
             !this.hasWorkflowMunicipality || !this.selectedCpo
    },
    hasStatusToStartProcess () {
      return ['suggestion', 'definitive'].includes(this.chargingpoint.data.properties.status)
    },
    userIdentity () {
      return this.$auth.user['https://evmaps.nl/identity']?.uuid
    },
    municipalityTenant () {
      const vlaanderen = this.getActiveMunicipality?.length === 5 && 'vlaanderen'
      return this.municipalityWorkflowTenants[this.getActiveMunicipality] || vlaanderen
    },
    workflowCpoTenant () {
      if (this.stakeholder && this.isCorrectCpoSelected) {
        return getCpoById(this.stakeholder.uuid)?.workflow?.tenant?.[0]
      }
      return getCpoById(this.userIdentity)?.workflow?.tenant?.[0]
    },
    isRoleAllowed () {
      const isMunicipality = this.municipalityTenant && this.isAllowedByRole(this.allowedMunicipalityRoles)
      const isCpo = this.workflowCpoTenant && this.isAllowedByRole(this.allowedCpoRoles)

      return !!this.isCorrectCpoSelected || isMunicipality || isCpo || true
    },
    selectedTenant () {
      const isCpo = this.getStakeholder?.category === 'cpo'
      const tenant = isCpo ? this.getStakeholder.tenants?.[0] : this.selectedCpo?.tenants?.[0]

      return this.municipalityTenant || tenant || this.workflowCpoTenant
    },
    showInfoAlert () {
      return !this.isLoading && (!this.hasWorkflowMunicipality || !this.hasStatusToStartProcess || !this.isCorrectCpoSelected)
    },
    remark () {
      return this.chargingpoint.data.properties.remark
    },
    stakeholder () {
      return this.chargingpoint.data.properties.stakeholders?.[0]
    },
    selectableCpos () {
      return this.getStakeholder.cpos.map(cpo => ({ text: cpo.name, value: cpo }))
    },
    hasCpoAsTenant () {
      return this.stakeholder && this.getStakeholder.cpos.some(cpo => cpo.uuid === this.stakeholder.uuid)
    },
    isAdmin () {
      return ['superuser', 'admin'].includes(this.currentUserRole)
    },
    isCorrectCpoSelected () {
      return (!!this.stakeholder && this.hasCpoAsTenant) || this.hasRoleAndNoStakeholder
    },
    hasRoleAndNoStakeholder () {
      return (this.isAdmin || this.hasUserRole('municipality')) && !this.stakeholder
    },
  },
  watch: {
    chargingpoint: {
      immediate: true,
      deep: true,
      handler () {
        if (this.isRoleAllowed) {
          this.isFetching = false
          this.selectedCpo = null
          this.processCategory = null
          this.$nextTick(() => {
            this.hasWorkflowMunicipality = (this.isAdmin || this.hasUserRole('municipality')) && !!this.getStakeholder.tenants.length || this.hasCpoAsTenant
          })
        }
      },
    },
    selectedTenant: {
      immediate: true,
      handler (tenant) {
        const hasMunicipalityChanged = this.getWorkflowTenant?.municipality?.value !== this.getActiveMunicipality

        if (tenant) {
          if (hasMunicipalityChanged) {
            this.fetchWorkflowTenant()
          } else {
            this.hasWorkflowMunicipality = this.getWorkflowTenant?.municipality
            this.processCategories = this.getWorkflowTenant?.processCategories
            this.isFetching = false
          }

          if (this.stakeholder && this.isCorrectCpoSelected) {
            this.selectedCpo = this.stakeholder
          }
        }
      },
    },
    selectedCpo: {
      async handler () {
        this.$nextTick(() => {
          this.fetchWorkflowTenant()
        })
      },
    },
  },
  methods: {
    async startWorkflowProcess () {
      this.isLoading = true
      let updatedCp = null

      if (!this.stakeholder) {
        updatedCp = await this.updateChargingPoint()
        if (!updatedCp) {
          return this.isLoading = false
        }
      }

      const api = await fetch(`${process.env.VUE_APP_EVTOOLS_API_ENDPOINT}process/realisation/`, {
        method: 'POST',
        headers: {
          'x-api-key': process.env.VUE_APP_EVTOOLS_APIKEY,
        },
        body: JSON.stringify({
          tenant: this.selectedTenant,
          chargingpointUuid: this.chargingpoint.data.uuid,
          category: this.processCategory,
          remark: this.remark,
          user: {
            name: `${this.$auth.user.name} (via Maps)`,
            id: this.$auth.user.sub || 'Unknown',
          },
        }),
      }).catch(err => console.log(err))

      if (api?.ok) {
        this.isLoading = false
        const response = await api.json()
        const error = !response.data
        if (error) {
          const { message } = JSON.parse(response.body)
          return this.$emit('setAlert', { type: 'danger', message })
        }

        const chargingpoint = this.chargingpoint
        chargingpoint.data.workflowUuid = response.data.uuid
        chargingpoint.data.workflowCaseRef = response.data.case?.full
        if (updatedCp) {
          chargingpoint.data.properties.stakeholders = updatedCp.properties.stakeholders
        }

        this.$store.dispatch('planmode/addOrUpdateChargingPoint', { chargingpoint })
        this.isLoading = false
        this.$emit('setAlert', { type: 'success' })
      }
    },
    isAllowedByRole (roles) {
      return roles.includes(this.workflowRole.role) &&
             ['*', this.getActiveMunicipality].some(code => this.workflowRole.value.includes(code))
    },
    async fetchWorkflowTenant () {
      const token = await this.$auth.getTokenSilently()
      const api = await fetch('/api/get-workflow-tenant-config', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ tenant: this.selectedTenant }),
      })

      if (!api.ok) {
        console.log('Could\'t get Workflow tenant municipalities.')
      }

      const response = await api.json()
      this.hasWorkflowMunicipality = response.municipalityOptions.find(m => m.value === this.getActiveMunicipality)
      this.processCategories = response.processCategories

      const data = {
        processCategories: this.processCategories,
        municipality: this.hasWorkflowMunicipality,
        tenant: this.selectedTenant,
      }
      this.$store.dispatch('access/setWorkflowTenant', { tenant: data })
    },
    hasUserRole (role) {
      return this.getCurrentUserRole === role
    },
    async updateChargingPoint () {
      this.isLoading = true
      const token = await this.$auth.getTokenSilently()
      const uuid = this.chargingpoint.data.uuid
      const data = {
        properties: {
          stakeholders: [this.selectedCpo] || [],
        },
      }
      const api = await fetch('/api/update-chargingpoint', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ uuid, data }),
      })

      if (!api.ok) {
        console.log('Could\'t update charging point.')
      }

      return await api.json()
    },
  },
}
</script>
<style lang="scss" scoped>
.create-process {
  position: relative;
  .spinner-border {
    position: absolute;
    left: 1em;
    top: 0.6em;
  }
  &:disabled {
    background: #999 !important;
    border-color: #eee !important;
  }
}
</style>
