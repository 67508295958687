<template>
  <ul class="m-0 p-0 mb-3 list-unstyled">
    <li
      v-for="group in legendItemGroups"
      :key="group.id"
      class="Legend-Item align-items-center"
      :class="{ 'has-title': !!group.title }"
    >
      <div
        v-if="group.title"
        class="Legend-Item__Title"
        @click="togggleVisibility(group)"
      >
        {{ $t(group.title) }}

        <SvgIcon
          :icon="visibility[group.id] ? 'chevron-up-regular' : 'chevron-down-regular'"
          class="float-right"
        />
      </div>

      <b-collapse v-model="visibility[group.id]">
        <InMapLegendList :items="group.items" />
      </b-collapse>
    </li>
  </ul>
</template>

<script>

import SvgIcon from '@/components/common/SvgIcon.vue'
import InMapLegendList from '@/components/map/InMapLegendList.vue'
import Vue from 'vue'
import { layers as layerDetails } from '@/data/layerDetails'

export default {
  components: { InMapLegendList, SvgIcon },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      state: layerDetails.reduce((acc, layer) => {
        return {
          ...acc,
          [layer.id]: true,
        }
      }, {}),
    }
  },
  computed: {
    visibility() {
      return this.state || {}
    },
    legendItemGroups() {
      let items = this.items.filter(item => (item.label && (item.icon || item.color)))

      items = items.reduce((groups, item) => {
        // if the item has a parentId, it is configured via the property legendItems
        if (item.parentId) {
          return this.upsertLegendItem(groups, item)
        }

        // otherwise the item is a layer
        return this.upsertLayer(groups, item)
      }, [])

      return items
    },
  },
  methods: {
    togggleVisibility(group) {
      Vue.set(
          this.state,
          group.id,
          ! this.state[group.id],
      )
    },
    upsertLegendItem(groups, item) {
      this.state[item.parentId] = true

      // find the group the item is part of
      const parent = groups.find(group => group.id === item.parentId)

      // if the item is part of a group, add it to the group, else create a new group
      parent
        ? parent.items.push(item)
        : groups.push({
          id: item.parentId,
          title: item.title,
          items: [item],
        })

      return groups
    },
    upsertLayer(groups, item) {
      this.state[item.id] = true

      // if the item has layers, it is a groups parent and should create a new group
      if (item.layers) {
        return [...groups, {
          id: item.id,
          title: item.legendTitle,
          childrenIds: item.layers,
          items: [item],
        }]
      }

      // find the group the item is part of
      const group = groups.find(group => group.childrenIds?.includes(item.id))
      
      // if the item is part of a group, add it to the group, else create a new group
      group
        ? group.items.push(item)
        : groups.push({
          id: item.id,
          title: item.legendTitle,
          items: [item],
        })

      return groups
    },
  },
}
</script>

<style lang="scss" scoped>
.Legend-Item {
  border-top: 1px solid var(--text-muted);

  &:first-child,
  &.has-title {
    border-top: none;
  }

  &__Title {
    font-weight: 600;
    border-bottom: 1px solid var(--text-muted);
    width: 100%;
    cursor: pointer;
  }
}
</style>
