<template>
  <div
    v-show="legendItems.length"
    class="LegendWrapper"
  >
    <b-button
      :pressed.sync="isOpen"
      text="Legenda"
      variant="primary"
      class="btn-map-view"
      v-text="isOpen ? $t('components.map.MapLegend.close'): $t('components.map.MapLegend.open')"
    />
    <div
      v-if="isOpen"
      :class="['Legend', { hidden }]"
    >
      <InMapLegendCollapsables :items="legendItems" />
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixins/common/userMixin'
import InMapLegendCollapsables from '@/components/map/InMapLegendCollapsables'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MapLegend',
  components: { InMapLegendCollapsables },
  mixins: [userMixin],
  props: {
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isOpen: !window.innerWidth || window.innerWidth > 600,
    }
  },
  computed: {
    ...mapGetters('access', ['getActiveMunicipality']),
    ...mapGetters('layers', {
      visible: 'isLegendInMapVisible',
      getLayerDetailsById: 'getLayerDetailsById',
      layers: 'getLegendInMapLayers',
    }),
    legendItems() {
      return this.layers.reduce((total, layer) => {
        if (layer.legendItems) {
          const items = layer.legendItems.map(item => ({ ...item, title: layer.legendTitle, parentId: layer.id }))
          return [...total, ...items]
        }

        if (layer.layers) {
          return [...total, ...layer.layers.map(id => this.getLayerDetailsById({ id }))]
        }

        return total
      }, []).filter(layer => !!layer)
    },
    hidden () {
      return !this.visible
    },
  },
  watch: {
    getActiveMunicipality() {
      this.resetInMapLegend()
    },
  },
  methods: {
    ...mapActions('layers', ['resetInMapLegend']),
  },
}
</script>

<style lang="scss">
.LegendWrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 0.7rem;
  padding-right: 0.7rem;

  .Legend {
    margin-top: 0.3rem;
    background-color: white;
    padding: 0.3rem 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid lightgray;
    filter: drop-shadow(0 1px 2px #222);
    width: 310px;
    // scroll bar for legend
    overflow: scroll;
    max-height: 660px;

    &-Item {
      &-ColorIndicator {
        border: 3px dashed var(--legend-color);
        display: block;
        height: 5px;
        width: 15px;

        &.solid {
          background-color: var(--legend-color);
        }
      }
    }

    &.hidden {
      display: none;
    }
  }

  .btn-map-view {
    width: 130px;
  }
}
</style>
