<template>
  <b-button-group>
    <b-button
      v-for="buttonState in states"
      :key="buttonState"
      variant="outline-secondary"
      size="sm"
      :class="{'selected': state === buttonState }"
      @click="handleBtnClick(buttonState)"
    >
      {{ $t(`${translationPath}${buttonState}`) }}
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  name: 'Buttons',
  props: {
    state: {
      type: String,
      default: 'all',
    },
  },
  data() {
    return {
      translationPath: 'components.form.buttons.',
      states: ['with', 'without', 'all'],
    }
  },
  methods: {
    handleBtnClick(state) {
      this.$emit('setFilter', state)
    },
  },
}
</script>

<style scoped lang="scss">
  .selected {
    background-color: var(--cta);
    color: white;
    pointer-events: none;
  }
</style>
