<template>
  <div class="UploadDashboard d-flex flex-column w-100 align-items-center">
    <div class="ExportPanelWrapper mt-5 mb-4 flex-grow-1">
      <PanelHeader
        :enable-save="false"
        btnlabel=""
      />

      <Feedback
        v-if="!dimissed"
        :feedback="{ variant: 'logo'}"
        class="mx-4 mt-4 pt-4 pb-4"
        @dismissed="dimissed = true"
      >
        <div style="max-width: 800px">
          {{ $t('best') }} {{ username }},
          <br><br>
          {{ $t('components.export.ExportPanel.msg.line1') }}
          <br>
          {{ $t('components.export.ExportPanel.msg.line2') }}
          <br>
          <br>
          {{ $t('closingMessage.greetings') }}
          <br>
          {{ $t('closingMessage.evtools') }}
          <br><br>
          <span class="text-muted">
            {{ $t('closingMessage.contact') }} <MailtoSupport />.
          </span>
        </div>
      </Feedback>

      <Feedback
        class="mx-4 mt-4"
        :feedback="feedback"
      />

      <div class="d-flex flex-column p-4">
        <div class="ExportPanel d-flex mt-3 pb-3">
          <aside class="ExportPanel__Aside flex-shrink-0 mr-3">
            <h3 class="ExportPanel__Title">
              {{ $t('components.export.ExportPanel.header') }}
            </h3>
            <div class="w-75 mt-3">
              {{ $t('components.export.ExportPanel.description') }}
            </div>
          </aside>

          <div class="ExportPanel__Main flex-grow-1 mb-3">
            <div class="d-flex align-items-center">
              <span class="ExportPanel__Label mr-3">
                Excel
              </span>
              <b-button
                :disabled="busy"
                variant="cta"
                class="ml-3"
                @click="downloadExcel"
              >
                Download
              </b-button>
            </div>
            <div class="d-flex align-items-center mt-3">
              <span class="ExportPanel__Label mr-3">
                Shapefile
              </span>
              <b-button
                :disabled="busy"
                variant="cta"
                class="ml-3"
                @click="downloadShapefile"
              >
                Download
              </b-button>
            </div>
            <div class="d-flex align-items-center mt-3 mb-3">
              <span class="ExportPanel__Label mr-3">
                GeoJSON
              </span>
              <b-button
                :disabled="busy"
                variant="cta"
                class="ml-3"
                @click="downloadGeoJSON"
              >
                Download
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from '@/components/common/PanelHeader'
import Feedback from '@/components/form/Feedback'
import MailtoSupport from '@/components/common/MailtoSupport'

import { mapGetters } from 'vuex'
import { saveAs } from 'file-saver'

import { getDateStamp } from '@/helpers/date'
import { labelByCode } from '@/services/municipalities'
import { introducedBy } from '@/services/introductionSource'
import { statusSlugToNumber } from '@/../shared/services/statusTranslations'

import chargingpointsLoadMixin from '@/mixins/chargingpoint/chargingpointsLoadMixin'

import shpwrite from 'shp-write'
import { splitNumberAndSuffix } from '@/../shared/helpers/address'

export default {
  name: 'ExportPanel',
  components: {
    MailtoSupport,
    PanelHeader,
    Feedback,
  },
  mixins: [chargingpointsLoadMixin],
  data() {
    return {
      busy: true,

      dimissed: false,
      feedback: {

      },
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('planmode', [
      'getChargingPoints',
    ]),
    ...mapGetters('config', ['getPhases']),
    username() {
      return this.$auth.user.name
    },
    municipalityLabel() {
      return labelByCode({ code: this.getActiveMunicipality })
    },
  },
  watch: {
    /**
     * Load new data when the municipality changes
     */
    getActiveMunicipality: async function(code) {
      this.loadChargingPoints({
        code,
      })
    },
  },
  created() {
    this.loadChargingPoints({
      code: this.getActiveMunicipality,
    })
  },
  methods: {
    /***********************************************************************************
     * Obtain Charging points details
     **********************************************************************************/

    /**
     * Load the charging points for a municipality by code
     */
    loadChargingPoints: async function({ code }) {

      this.busy = true
      this.feedback = {
        message: this.$i18n.t('components.export.ExportPanel.loadingFeedback'),
        variant: 'info',
      }

      await this.$_chargingpointsLoadMixin_loadChargingPoints({ code })

      this.busy = false
      this.feedback = {
        message: this.$i18n.t('components.export.ExportPanel.feedback', { municipality: this.municipalityLabel }),
        variant: 'info',
      }
    },

    /***********************************************************************************
     * Generate download files
     **********************************************************************************/

    /**
     * GeoJSON
     *  Note:
     *  - The properties structure is input for the Excel
     *  - The GeoJSON is direct input for the shapefile
     */
    generateGeoJSON() {
      let chargingpoints = this.getChargingPoints

      let code = this.getActiveMunicipality

      let geojson = {
        type: 'FeatureCollection',
        features: [],
      }

      /**
       * Add the entries to the GEOJSON
       */
      geojson.features = chargingpoints.map((entry) => {
        const { number, suffix } = splitNumberAndSuffix({ number: entry.data.address?.number })

        let properties = {
          id: `${code}-${entry.data.properties.id}`,
          uuid: `${entry.data.uuid}`,
          lat: entry.data.coordinates[1]+''.replace('.', ','),
          lng: entry.data.coordinates[0]+''.replace('.', ','),
          street: entry.data.address ? entry.data.address.street : null,
          streetnumber: number,
          streetsuffix: suffix,
          postalcode: entry.data.address && entry.data.address.postalcode ? entry.data.address.postalcode.replace(/\s/g, '') : null,
          municipality: entry.data.address ? entry.data.address.municipality : null,
          city: entry.data.address ? entry.data.address.city : null,
          reference: entry.data.properties.vkb || '',
          source: introducedBy(entry),
          remark: entry.data.properties.remark,
          statusnumber: statusSlugToNumber({ status: entry.data.properties.status }),
          statusdescription: this.$i18n.t(`participationPanel.options.statusStations.regularLabels.${entry.data.properties.status}`),
          rolloutphase: this.getRolloutPhase({ entry }),
          rolloutschedule: entry.data.prio ? entry.data.prio.order : null,
          gridconnection: ['definitive'].includes(entry.data.properties.status) ? '3*25A' : '',
          nroutlets: entry.data.properties.nroutlets,
          cpo: this.getCpo({ entry }),
        }

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: entry.data.coordinates,
          },
          properties,
        }
      })

      return geojson
    },

    generateExcelData() {
      return this.generateGeoJSON().features.map(feature => feature.properties)
    },

    downloadGeoJSON() {
      let content = new Blob([JSON.stringify(this.generateGeoJSON(), null, 4)], { type: 'text/plain;charset=utf-8' })
      saveAs(content, `${this.municipalityLabel}-laadpalen-${getDateStamp()}.geojson`)
    },
    downloadExcel: async function() {
      const { default: ExcelJS } = await import('exceljs') // Dynamically import exceljs
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Laadpalen')
      worksheet.columns = [
        { header: 'id', key: 'id', width: 10 },
        { header: 'uuid', key: 'uuid', width: 32 },
        { header: 'Latitude', key: 'lat', width: 32 },
        { header: 'Longitude', key: 'lng', width: 32 },
        { header: 'Streetname', key: 'street', width: 32 },
        { header: 'Street number', key: 'streetnumber', width: 32 },
        { header: 'Street suffix', key: 'streetsuffix', width: 32 },
        { header: 'Postal code', key: 'postalcode', width: 32 },
        { header: 'Municipality', key: 'municipality', width: 32 },
        { header: 'City', key: 'city', width: 32 },
        { header: 'Reference "wegenVerkeersbesluit"', key: 'verzamelverkeersbesluit', width: 32 },
        { header: 'Ingevoerd door', key: 'source', width: 32 },
        { header: 'CPO', key: 'cpo', width: 32 },
        { header: 'Opmerking', key: 'remark', width: 32 },
        { header: 'Status Number', key: 'statusnumber', width: 32 },
        { header: 'Status Description', key: 'statusdescription', width: 32 },
        { header: 'Rollout fase', key: 'rolloutphase', width: 32 },
        { header: 'Rollout schedule', key: 'rolloutschedule', width: 32 },
        { header: 'Grid connection', key: 'gridconnection', width: 32 },
        { header: 'Number of outlets', key: 'nroutlets', width: 32 },
      ]

      worksheet.addRows(
        this.generateExcelData(),
      )

      this.worksheetStyle(worksheet)

      const buffer = await workbook.xlsx.writeBuffer({ base64: true })
      saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${this.municipalityLabel}-laadpalen-${getDateStamp()}.xlsx`)
    },
    downloadShapefile() {
      // There are some issues with Safari one way or another...
      // See: https://github.com/eligrey/FileSaver.js/issues/12
      // Using SaveAs with a Blob & ArrayBuffer avoids an error, but generates a (zip) file that can't be processed
      // The zip resulting from the code below works, although the name is random.
      // The contained shapefile was problematic for Mapshaper, but QGIS could read it without problems.
      shpwrite.download(this.generateGeoJSON(), {
        folder: `${this.municipalityLabel}-laadpalen-${getDateStamp()}`,
        types: {
          point: 'Laadpalen',
          polygon: 'polygons',
          line: 'lines',
        },
      })
    },
    getCpo({ entry }) {
      // Stakholder value (manual input) has a priority over imported one //
      const cpo = entry.data.properties.stakeholders?.find(stakeholder => stakeholder.type === 'cpo')?.name || entry.data.properties.operator
      return cpo
    },
    worksheetStyle(worksheet) {
      return worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.height = 30
        row.eachCell((cell) => {
          cell.alignment = {
            indent: 1,
            vertical: 'middle',
          }

          if (rowNumber === 1) {
            cell.font = {
              size: 12,
              bold: true,
            }
          }
        })
      })
    },
    getRolloutPhase({ entry }) {
      if (! entry.data.prio) return null

      if (entry.data.prio.customPhase) {
        return this.getPhases.find(phase => phase.uuid === entry.data.prio.customPhase)?.label
      }

      return entry.data.prio.fase
    },
  },
}
</script>

<style lang="scss">


/**
 * This component combines the html & css of three layered admin components,
 *  because it doesn't need any of the logic of those components
 * TODO: Fix html & CSS so that it makes sense as one component
 */
.UploadDashboard {
  &__Panel, &__Select {
    // width: calc(100% - 2rem);
    min-width: 1100px;
    max-width: 1100px;
  }
  &__Panel {
    // border: 1px solid red;
    background: white;
    box-shadow: 0px 1px 3px 0px #dfe2e5;
  }
  .vSelect {
    min-width: 300px;
  }
}
.ExportPanelWrapper {

  min-width: 1100px;
  max-width: 1100px;
  background: white;
  box-shadow: 0px 1px 3px 0px #dfe2e5;

  header {
    background: var(--primary);
    color: white;
    font-size: 1.5rem;
  }

  form {
    label {
      font-size: 1.1rem;
    }
    small {
      outline: none !important;
      font-size: 1rem;
    }
  }
}
.ExportPanel {
  &__Aside {
    width: 275px;
  }
  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Label {
    width: 175px;
  }
  &__Input {
    position: relative;
    width: 360px;

    &:after {
      content: attr(data-intructions);
      position: absolute;
      top: 3.1rem;
      font-size: 1rem;
      left: 0;
      color: #7F8FA4;

    }
  }
  &__Download {
    text-decoration: underline;
    color: var(--cta);
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Selecteer';
  }
}
</style>
