<template>
  <div class="ActiveTab ProcessInfoTab">
    <LocatieInformatieHeader :chargingpoint="chargingpoint">
      <h4>{{ $t(`${translationPath}.title`) }}</h4>
    </LocatieInformatieHeader>
    <div class="ActiveTab__Content">
      <div v-if="isInProcess">
        <p>
          <strong>{{ $t(`${translationPath}.caseRef`) }}</strong><br>
          <a
            v-if="processUrl"
            :href="processUrl"
            target="_blank"
            title="Ga naar het Workflowproces"
          >
            <b-icon-box-arrow-up-right class="mr-1" />
            {{ caseRef }}
          </a>
          <span v-else> {{ caseRef }}</span>
          <span
            v-if="isWorkflowAltLocation"
            class="ml-1"
          >
            (Alternatieve locatie)
          </span>
        </p>

        <strong> {{ $t(`${translationPath}.status.current`) }} </strong> <br>
        {{ $t(`${translationPath}.status.${processStatus}`) }}
      </div>
      <div v-else>
        <span> {{ $t(`${translationPath}.notLinked`) }}</span>
        <CreateWorkflowProcess @setAlert="handleAlert" />
      </div>
      <b-alert
        :show="alertDismissSec"
        dismissible
        fade
        :class="['mt-3', alertVariant]"
        @dismiss-count-down="countDownChanged"
      >
        {{ responseAlertMessage }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import chargingpointDataMixin from '@/mixins/chargingpoint/chargingpointDataMixin'
import LocatieInformatieHeader from '@/components/map/sidebar/LocatieInformatieHeader'
import CreateWorkflowProcess from '@/components/map/sidebar/Tabs/CreateWorkflowProcess'
import { BIconBoxArrowUpRight } from 'bootstrap-vue'

export default {
  name: 'ProcessInfoTab',
  components: { LocatieInformatieHeader, CreateWorkflowProcess, BIconBoxArrowUpRight },
  mixins: [chargingpointDataMixin],
  data () {
    return {
      translationPath: 'components.sidebar.Tabs.ProcessInfoTab',
      alertVariant: 'alert-success',
      alertDismissSec: 0,
      responseAlertMessage: '',
      processUrl: '',
      processStatus: '',
    }
  },
  computed: {
    externalProcessId(){
      return this.chargingpoint.data?.externalProcessId
    },
    workflowCaseRef(){
      return this.chargingpoint.data?.workflowCaseRef
    },
    isInProcess() {
      return (
        !!this.chargingpoint.data?.workflowCaseRef ||
        !!this.chargingpoint.data?.externalProcessId
      )
    },
    caseRef() {
      return (
        this.chargingpoint.data?.workflowCaseRef ||
        this.chargingpoint.data?.externalProcessId
      )
    },
    workflowUuid () {
      return this.chargingpoint.data.workflowUuid
    },
    isWorkflowAltLocation () {
      return this.chargingpoint.data.isWorkflowAltLocation
    },
  },
  watch: {
    workflowUuid: {
      immediate: true,
      handler (uuid) {
        if (uuid) {
          this.fetchProcess()
        }
      },
    },
    chargingpoint: {
      immediate: true,
      handler () {
        this.processStatus = this.findProcessStatus()
      },
    },
  },
  created (){
    this.fetchProcess()
  },
  methods: {
    findProcessStatus(linkedWorkflowProcess) {
      const { status: statusChargingPoint } = this.chargingpoint.data.properties
      const statusProcess = linkedWorkflowProcess?.status || {}
      const locIsRealized = statusChargingPoint.includes('realized')

      if (this.externalProcessId && locIsRealized) {
        return 'completed'
      }

      if (this.workflowUuid) {
        if (locIsRealized && statusProcess.completed) return 'completed'
        if (!locIsRealized && statusProcess.cancelled) return 'cancelled'
        if (!locIsRealized && statusProcess.onhold) return 'onhold'
      }

      return 'active'
    },
    async fetchProcess () {
      const token = await this.$auth.getTokenSilently()
      const api = await fetch('/api/get-workflow-process', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ uuid: this.workflowUuid }),
      })

      if (!api.ok) {
        console.log('Could\'t get Workflow process.')
      }

      const response = await api.json()

      const routeType = response.processType.routeType
      this.processUrl = `${response.baseUrl}${routeType}/${this.workflowUuid}`
      this.processStatus = this.findProcessStatus(response)
    },
    countDownChanged (dismissAlertCount) {
      this.alertDismissSec = dismissAlertCount
    },
    handleAlert (event) {
      this.alertDismissSec = event.message ? 10 : 4
      this.alertVariant = `alert-${event.type}`
      this.responseAlertMessage = event.message || this.$i18n.t(`${this.translationPath}.responseAlertMessage`)
    },
  },
}
</script>

