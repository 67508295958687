<template>
  <div class="CollapsableSubSegment">
    <h4
      class="d-flex justify-content-between"
      @click="togggleVisibility"
    >
      <span
        v-if="subHeader"
        class="flex-grow-1"
      >{{ $t(subHeader) }}</span>
      <SvgIcon
        class="ml-3"
        :icon="icon"
        :has-fill="true"
      />
    </h4>
    <b-collapse v-model="visibilityState">
      <div>
        <slot />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon.vue'
import CollapsableSegment from '@/components/common/CollapsableSegment'

export default {
  name: 'CollapsableSubSegment',
  components: { SvgIcon },
  extends: CollapsableSegment,
  props: {
    subHeader: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.CollapsableSubSegment {
  h4 {
    font-size: medium;
    cursor: pointer;
    fill: #7F8FA4;
    position: relative;

    &:hover {
      color: darken(#7F8FA4, 20%);
      fill: darken(#7F8FA4, 20%);
    }
  }
}
</style>
