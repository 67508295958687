<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/services/eventbus'

export default {
  name: 'NeighborhoodsLayer',
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
    layerVisibility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      layerName: 'buurtlaag',
      type: 'line',
      icon: false,
      sdf: false,
      paint: {
        'line-width': 2,
        'line-color': 'hsl(30, 87%, 15%)', //"hsla(23,100%, 62%, 0.7)", // "hsl(52, 73%, 73%)",
        'line-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          11,
          0,
          14,
          0.90,
        ],
      },
      textLayout: {
        'text-field': [
          'format',
          ['get', 'buurtnaam'],
          { 'font-scale': 1 },
          '\n',
          {},
          ['concat', '(', ['get', 'buurtcode'], ')'],
          {
            'font-scale': 0.8,
            'text-font': ['literal', ['Open Sans Italic', 'Arial Unicode MS Regular']],
          },
        ],
        'text-justify': 'auto',
        'symbol-placement': 'point',
        'visibility': this.layerVisibility ? 'visible' : 'none',
      },
      textPaint: {
        'text-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          12,
          0,
          14,
          0.80,
        ],
      },
      layout: {
        'visibility': this.layerVisibility ? 'visible' : 'none',
      },
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
    ]),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    source() {
      return {
        url: process.env.VUE_APP_LS_URL || `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        layer: process.env.VUE_APP_LS_SOURCE || this.sourceName,
      }
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
  },
  watch: {
    loaded(loaded) {
      if (!loaded) return

      this.addLayer()
    },
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  methods: {
    /**
     * Add the layer
     */
    addLayer() {
      if (! this.sourceName) return

      if (! this.source.url || ! this.source.layer) return

      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
        this.$store.map.removeLayer(this.layerName + '-text')

      }
      if (this.$store.map.getSource(this.sourceName)) {
        this.$store.map.removeSource(this.sourceName)
      }

      this.$nextTick(() => {
        this.$store.map.addSource(this.sourceName, {
          type: 'vector',
          url: this.source.url,
        })

        this.$store.map.addLayer({
          'id': this.layerName,
          'type': this.type,
          'source': this.sourceName,
          'source-layer': this.source.layer,
          'minzoom': this.minzoom || 0,
          'paint': this.paint,
          'layout': this.layout,
        })

        this.$store.map.addLayer({
          'id': this.layerName + '-text',
          'type': 'symbol',
          'minzoom': 12,
          'source': this.sourceName,
          'source-layer': this.source.layer,
          'layout': this.textLayout,
          'paint': this.textPaint,
        })


        EventBus.$emit('layers.national.loaded', {
          name: this.layerName,
        })
      })
    },
  },
}
</script>
