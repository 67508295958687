<template>
  <div style="display:none" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { initiate, subscribe, unsubscribe } from '@/services/pusher'

import chargingpointsLoadMixin from '@/mixins/chargingpoint/chargingpointsLoadMixin'
import planmodeFilterMixin from '@/mixins/planmode/filterMixin'

import { uniqueIntId } from '@/helpers/number'
import { image } from '@/helpers/assets'

import { statusesWithLabelDutch, statusesWithLabelEnglish } from '@/../shared/services/statusTranslations'
import { getLatestCoordinates } from '@/helpers/chargingpoints'

export default {
  mixins: [chargingpointsLoadMixin, planmodeFilterMixin],
  props: {
    /**
     * Whether or not the mapbox instance is ready and available
     */
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      /**
       * Icon counter. We're loading 5 icons (see requiredIcons)
       */
      iconCounter: 0,

      /**
       * Pusher channel
       */
      channel: null,
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
      'canAccessLayer',
    ]),
    ...mapGetters('config', [
      'isMonitoringEnabled',
      'isParticipationEnabled',
    ]),
    ...mapGetters('filters', [
      'isAnyFilterActive',
    ]),
    ...mapGetters('planmode', [
      'getChargingPoints',
      'getFilteredChargingpoints',
      'getPlanmodeLayers',
      'hasLoadedChargingPoints',
    ]),
    ...mapGetters('config', [
      'isPlanmodeEnabled',
    ]),

    /**
     * Track whether all required icons are loaded
     */
    iconsLoaded() {
      return this.iconCounter >= this.requiredIcons.length
    },

    /**
     * The list of icons that need to be loaded
     *  While the planmode may be disabled for one municipality, it can be active for the next.
     *  We're loading all icons regardless of the feature state to keep things simple
     */
    requiredIcons() {
      const statuses = localStorage.getItem('language') === 'nl' ? statusesWithLabelDutch : statusesWithLabelEnglish
      return Object.keys(statuses)
    },

    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
  },
  watch: {
    /**
     * When the charger locations list changes, we update the layer data
     */
    getChargingPoints(chargingpoints) {
      if (!this.loaded) return

      let source = this.$store.map.getSource('chargingpoints')
      if (source) {
        source.setData(this.generateGeoJson(chargingpoints))
      }
    },

    /**
     * when chargingpoints are filtered, update opacity of chargingpoints on the map
     */
    getFilteredChargingpoints() {
      this.updateFilterVisibility()
    },

    /**
     * update when layer changes
     */
    getLayers: {
      handler: function () {
        this.setOpacity()
      },
      deep: true,
    },

    /**
     * Load new data when the municipality changes
     */
    getActiveMunicipality: async function (code, previous) {

      // Update the Pusher connection
      unsubscribe({ channel: previous })
      let { channel } = await subscribe({ channel: code })
      this.channel = channel

      await this.fetchData()
    },

    /**
     * Trigger the loading the icons when mapbox is ready
     *  When switching styles mapbox 'unloads', and we reset the icon counter as we will need to reload them
     */
    loaded(ready) {
      if (ready === false) {
        this.iconCounter = 0
      }
      if (ready) {
        this.loadIcons()
      }
    },

    /**
     * When the icons are loaded we can move on to adding the layer
     */
    iconsLoaded(ready) {
      if (ready) {
        this.addLayer()
      }
    },

    /**
     * Changes to the pusher channel
     */
    channel(channel) {
      if (channel === null) return

      channel.bind('client-chargingpoint-saved', this.handleChargingpointSavedEvent)
    },
    /**
     * When all charging points are loaded
     */
    hasLoadedChargingPoints (value) {
      if (value) {
        if (this.isMonitoringEnabled) {
          this.fetchAllUsageReports({ code: this.getActiveMunicipality })
        }
        // This one is needed for filtering //
        if (this.isParticipationEnabled) {
          this.fetchAllParticipationComments({ code: this.getActiveMunicipality })
        }
        // To avoid a newly Fauna generated 429 too many requests error //
        this.fetchAllComments({ code: this.getActiveMunicipality })
      }
    },
  },
  /**
   * Take prep actions as soon as possible
   *  By loading the charging point data
   *  and marker icons
   */
  created: async function () {
    const token = await this.$auth.getTokenSilently()
    initiate({ token })

    let { channel } = await subscribe({ channel: this.getActiveMunicipality })
    this.channel = channel

    await this.fetchData()

    this.$store.map?.on('styledata', this.updateFilterVisibility)
  },
  /**
   * Unsubscribe from pusher channel
   */
  beforeDestroy() {
    unsubscribe({ channel: this.getActiveMunicipality })
    this.$store.map?.off('styledata', this.updateFilterVisibility)
  },
  methods: {
    ...mapActions('planmode', [
      'addOrUpdateChargingPoint',
      'fetchAllComments',
      'fetchAllParticipationComments',
    ]),
    ...mapActions('monitoring', [
      'fetchAllUsageReports',
    ]),

    async fetchData() {
      this.$_chargingpointsLoadMixin_loadChargingPoints({ code: this.getActiveMunicipality })
    },

    /**
     * Make the marker icons available to MapBox
     *  This must be done before adding the layer
     */
    loadIcons() {
      this.requiredIcons.forEach(name => {
        this.$store.map.loadImage(image({ name: `chargingpoint/png/${name}.png` }), (error, image) => {
          if (error) throw error

          this.$store.map.addImage(name, image)
          this.iconCounter++
        })
      })
    },

    /**
     * Handle a Pusher event, triggered when another user saves (or deletes) a charging point.
     */
    handleChargingpointSavedEvent({ chargingpoint }) {
      if (! this.canAccessLayer({ id: `chargingpoints-${chargingpoint.data.properties.status}` })) return

      this.addOrUpdateChargingPoint({ chargingpoint })
    },

    /**
     * Translate datapoints into GeoJSON
     */
    generateGeoJson(points) {
      let geoJson = {
        'type': 'FeatureCollection',
        'features': [],
      }

      points = Array.isArray(points) ? points : []


      points.forEach(chargingpoint => {
        const status = chargingpoint.data.properties.status
        let prio = {
          fase: 99999,
          order: 99999999999999999,
        }

        if (chargingpoint.data.prio) {
          if (chargingpoint.data.prio.order) {
            prio.order = chargingpoint.data.prio.order
          }
          if (chargingpoint.data.prio.fase) {
            prio.fase = chargingpoint.data.prio.fase
          }
        }

        /*
        * id must be castable to int, and there are other restrictions, see: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#setfeaturestate
        */
        geoJson.features.push({
          'id': uniqueIntId({ chargingpoint }),
          'type': 'Feature',
          'properties': Object.assign(
              chargingpoint.data.properties,
              {
                refId: chargingpoint.ref['@ref'].id,
                uuid: chargingpoint.data.uuid,
                priority: prio.order,
                priofase: prio.fase,
                power: chargingpoint.data.properties.power || chargingpoint.data.monitoring?.power,
                status,
              }),
          'geometry': {
            'type': 'Point',
            'coordinates': getLatestCoordinates({ chargingpoint }),
          },
        })
      })

      return geoJson
    },

    /**
     * Update the opacity property
     */
    setOpacity() {
      // Context
      const layers = this.getPlanmodeLayers ?? []

      if (!this.$store.map?.getLayer('chargingpoints') || layers.length === 0) return

      const opacity = this.isAnyFilterActive ? 0.1 : 0.8

      /**
       * Legend on / off Toggle effect
       */
      const iconState = [
        'match',
        ['get', 'status'],
      ]
      const textState = [
        'match',
        ['get', 'status'],
      ]

      layers.forEach(layer => {
        let status = layer.id.replace('chargingpoints-', '')
        iconState.push(status)
        iconState.push(layer.visible ? opacity : 0)

        if (status === 'alert') return
        textState.push(status)
        textState.push(layer.visible ? opacity : 0)
      })

      iconState.push(0)
      textState.push(0)

      let textStyle = [
        'interpolate',
        ['linear'],
        ['zoom'],
        14,
        0,
        14.2,
        textState,
      ]

      // Default
      let style = [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        0,
        10.2,
        iconState,
      ]

      if (this.isAnyFilterActive) {
        style = [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          0,
          10.2,
          ['case',
            ['boolean', ['feature-state', 'displayChargingpoint'], false],
            0.9,
            iconState,
          ],
        ]

        textStyle = [
          'interpolate',
          ['linear'],
          ['zoom'],
          14,
          0,
          14.2,
          ['case',
            ['boolean', ['feature-state', 'displayChargingpoint'], false],
            0.9,
            textState,
          ],
        ]
      }

      this.$store.map.setPaintProperty('chargingpoints', 'icon-opacity', style)
      this.$store.map.setPaintProperty('chargingpoints-text', 'text-opacity', textStyle)
    },

    /**
     * Load the chargingpoint layer
     */
    addLayer() {

      // Clean up first if we must
      let source = this.$store.map.getSource('chargingpoints')
      if (source) {
        if (this.$store.map.getLayer('chargingpoints')) {
          this.$store.map.removeLayer('chargingpoints')
        }
        if (this.$store.map.getLayer('chargingpoints-text')) {
          this.$store.map.removeLayer('chargingpoints-text')
        }

        this.$store.map.removeSource('chargingpoints')
      }


      // Add the generated source
      this.$store.map.addSource('chargingpoints', {
        type: 'geojson',
        data: this.generateGeoJson(this.getChargingPoints),
      })

      /**
       * The marker icon layer
       */
      this.$store.map.addLayer({
        'id': 'chargingpoints',
        'type': 'symbol',
        'source': 'chargingpoints',
        'minzoom': 10,
        'layout': {
          'visibility': 'visible',
          'symbol-sort-key': 1,
          'symbol-placement': 'point',
          'symbol-z-order': 'source',
          'icon-allow-overlap': true,
          'icon-anchor': 'bottom',
          'icon-image': ['get', 'status'], // 'get' from properties
          'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0.05,
            18,
            0.2,
          ],
        },
        'paint': {
          //'visibility': 'visible', // todo:: toggle visible|none this
          // "icon-color": "",
          'icon-opacity': [ // transition based on zoom
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0,
            10.2,
            0.8,
          ],
        },
      }) // , this.getSymbolLayerId

      /**
       * Add the ID next to definitive charging points
       */
      this.$store.map.addLayer({
        'id': 'chargingpoints-text',
        'type': 'symbol',
        'source': 'chargingpoints',
        'minzoom': 14,
        'layout': {
          'symbol-sort-key': 1,
          'symbol-placement': 'point',
          'symbol-z-order': 'source',
          // 'text-allow-overlap': true,
          'text-field': ['get', 'id'], // 'get' from properties
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold',
          ],
          'text-size': 12,
          'text-radial-offset': [ // transition based on zoom
            'interpolate',
            ['linear'],
            ['zoom'],
            14,
            1,
            18.2,
            2,
          ],
          'text-variable-anchor': ['top-right', 'bottom-right', 'bottom-left', 'top-left', 'right', 'left', 'top', 'bottom'],
          'text-justify': 'auto',
        },
        'paint': {
          'text-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            14,
            0,
            14.2,
            [
              'case',
              ['boolean', ['feature-state', 'vote'], false],
              1,
              0.5,
            ],
          ],
        },
      })

      // Once the layers are added. Update the opacity based on the filters
      this.setOpacity()
    },

    updateFilterVisibility() {
      // reset the feature state
      if (this.$store.map?.getSource('chargingpoints')) {
        this.$store.map.removeFeatureState({
          source: 'chargingpoints',
        })
      }

      // set feature state visibility
      this.getFilteredChargingpoints.forEach(chargingpoint => {
        if (
            this.$store.map?.getSource('chargingpoints')
        ) {
          this.$store.map.setFeatureState(
              { source: 'chargingpoints', id: uniqueIntId({ chargingpoint }) },
              {
                displayChargingpoint: true,
              },
          )
        }
      })

      // update opacity accordingly
      this.setOpacity()
    },
  },
}

</script>
