export const bboxByCodeByDeployment = {
  nl: {
    '0214': [
      5.241921632509203, 51.87339498499149, 5.586616662930258,
      51.98594763315502,
    ],
    '0755': [
      5.626568576891281, 51.582360194471704, 5.758614667093563,
      51.62906095836148,
    ],
    '0175': [
      6.323412589236394, 52.428144524501654, 6.609643620091393,
      52.592584127574014,
    ],
    1735: [
      6.416313233062183, 52.163166308487966, 6.752959593139884,
      52.3086660187104,
    ],
    '0984': [
      5.838238544727365, 51.446557719387386, 6.141094126447129,
      51.570243516868445,
    ],
    '0873': [
      4.263732326876892, 51.35435188611156, 4.43169174290664,
      51.466800890122066,
    ],
    '0928': [
      6.000568536720309, 50.843676347690696, 6.088102386510639,
      50.90577028131039,
    ],
    1641: [
      5.807668965356885, 51.09946976985112, 5.955477588059281,
      51.21602527474536,
    ],
    '0342': [
      5.243172699989223, 52.11027146453064, 5.349925507819525,
      52.20456119775857,
    ],
    1924: [
      3.773675409461632, 51.64377788232158, 4.423549796722082,
      51.85859789459392,
    ],
    '0632': [
      4.792040368407709, 52.06494998654595, 5.00501133092293,
      52.162050777349485,
    ],
    '0627': [
      4.591089495991046, 52.0116798996594, 4.706039358368582, 52.07704971396603,
    ],
    1729: [
      5.819409637685796, 50.750367484696426, 5.983518481985121,
      50.848889405389045,
    ],
    1721: [
      5.423389877263624, 51.62668597605315, 5.600605638641616,
      51.740606801380316,
    ],
    '0534': [
      4.542282291454907, 52.27124001887879, 4.611701360872751,
      52.31839505025585,
    ],
    '0358': [
      4.670293461271338, 52.21635386526831, 4.823860490467681,
      52.30577610082595,
    ],
    '0888': [
      5.754766041396754, 50.89855495763709, 5.856929744803723,
      50.95514325512376,
    ],
    '0668': [
      5.400633290989015, 51.809535973547234, 5.609857606120027,
      51.89475336679385,
    ],
    '0944': [
      5.864468921821136, 51.717671498730155, 5.938298685873225,
      51.77862729191275,
    ],
    '0244': [
      6.000788474381065, 52.44376558337965, 6.10354600486454, 52.50960620404827,
    ],
    '0090': [
      5.870312657805836, 53.07078528183261, 6.185716766035934,
      53.159504524474805,
    ],
    '0344': [
      4.970096051947255, 52.02628198426504, 5.195155042329031,
      52.14205059836869,
    ],
    '0050': [
      5.301244569904476, 52.24952707085864, 5.623021253767905,
      52.442656258151054,
    ],
    '0355': [
      5.192752129636187, 52.058486804031844, 5.33261134247881,
      52.17125091183322,
    ],
    '0637': [
      4.415230953470687, 52.03128057436773, 4.557686425559341,
      52.093290330487235,
    ],
    '0451': [
      4.723655247014372, 52.20651642040826, 4.852187275598475,
      52.26101558242062,
    ],
    '0203': [
      5.472433377570189, 52.100482302011926, 5.811932405582697,
      52.242503063990036,
    ],
    '0297': [
      5.000363980164141, 51.737607140141336, 5.28843674693361,
      51.826851290535295,
    ],
    1884: [
      4.538147707858331, 52.15224831670421, 4.729703235351344,
      52.23384336369214,
    ],
    '0569': [
      4.681209439655733, 52.106484746654466, 4.892272689610232,
      52.2287618731964,
    ],
    '0106': [
      6.483947742810601, 52.932514826988005, 6.632646256884124,
      53.06192060192432,
    ],
    '0268': [
      5.757620635033959, 51.79058910766373, 5.908346975872266,
      51.894620924043934,
    ],
    '0098': [
      5.818108957165331, 52.80071841744546, 6.247258762593685,
      52.943013663715526,
    ],
    1960: [
      4.993854744551292, 51.80396261164345, 5.415234252898892,
      51.93548253913169,
    ],
    '0794': [
      5.570156117419671, 51.43802998492768, 5.736093702338414,
      51.50921735497299,
    ],
    '0034': [
      5.060428205379767, 52.302392175070395, 5.379211120127141,
      52.58368170116214,
    ],
    1640: [
      5.745619628854537, 51.15130385569139, 6.011014707870546,
      51.316577690339514,
    ],
    1706: [
      5.51577396516463, 51.220909401802416, 5.672110862804143,
      51.35387728216831,
    ],
    '0014': [
      6.46274568771544, 53.10619874106916, 6.77252686558787, 53.31296374309502,
    ],
    '0400': [
      4.629450718274295, 52.878626229478385, 4.925838026140233,
      53.02197818202843,
    ],
    '0448': [
      4.613000662149414, 52.94106928749547, 5.14428144539137, 53.21427480059697,
    ],
    1771: [
      5.525166899552563, 51.403136353166865, 5.656509351671874,
      51.460509014954965,
    ],
    '0664': [
      3.705536385158043, 51.47912157235094, 3.971449288211027,
      51.553417114700586,
    ],
    '0743': [
      5.693983532120045, 51.33380458505483, 5.870359647095207,
      51.440066898172084,
    ],
    1970: [
      5.702682794320213, 53.24350248769925, 6.294361198088017,
      53.49257747644532,
    ],
    '0184': [
      5.361146921448071, 52.63478080676003, 5.655379217018095,
      52.76480520877621,
    ],
    '0441': [
      4.634478643955724, 52.69140378927618, 4.850374530535386,
      52.88447262509001,
    ],
    '0362': [
      4.794545569307737, 52.24186783734697, 4.909151280682634,
      52.330569907406115,
    ],
    1891: [
      5.882244659536473, 53.2276976509319, 6.07775275403914, 53.3256304191922,
    ],
    1900: [
      5.164249079102024, 52.79595506347107, 5.788229324742015,
      53.15381403403212,
    ],
    1859: [
      6.400538930512337, 52.02060098935892, 6.760572414170953,
      52.182487841292556,
    ],
    1901: [
      4.659967798718078, 52.01324180860534, 4.873200481755069,
      52.12228737906615,
    ],
    '0828': [
      5.353514438136346, 51.7258662739761, 5.711230023589208,
      51.830750955946904,
    ],
    '0482': [
      4.626391123509044, 51.83865032614419, 4.7002397738684, 51.881212150324096,
    ],
    '0376': [
      5.203822047296329, 52.25466278607805, 5.335461511194046,
      52.30763117840567,
    ],
    1911: [
      4.758855323620145, 52.70680565690013, 5.215067021601229,
      53.04428857971112,
    ],
    '0431': [
      4.842951014445188, 52.42446134177641, 4.906469709302765,
      52.47982647741096,
    ],
    '0766': [
      4.904279284225279, 51.60709305124276, 5.004496668989576,
      51.67891799592099,
    ],
    '0547': [
      4.514691592552691, 52.13190333182122, 4.57148097690038, 52.18211625809935,
    ],
    '0074': [
      5.795507948211175, 52.9117404487515, 6.205423712023771, 53.08520791916361,
    ],
    '0505': [
      4.620403952618219, 51.71412354821405, 4.884642583979452,
      51.82335827508566,
    ],
    '0823': [
      5.191024955170428, 51.4250827875311, 5.391186965781834, 51.55282079062975,
    ],
    '0855': [
      4.947846024221746, 51.52950150794784, 5.210711452703898,
      51.641661422255794,
    ],
    '0269': [
      5.840256109631511, 52.391392945596856, 6.036944852275781,
      52.52202502557984,
    ],
    '0858': [
      5.387803704956482, 51.2622857247335, 5.52718061419862, 51.37833550989377,
    ],
    '0335': [
      4.868225108698213, 52.01001400077405, 5.018010578440935,
      52.07992033095834,
    ],
    '0093': [
      5.087068987015954, 53.21561365230381, 5.612830854556972,
      53.471584867976695,
    ],
    '0603': [
      4.290951755393736, 52.01277857025051, 4.35785338672339, 52.06180589283972,
    ],
    '0373': [
      4.594074494213869, 52.57369992840822, 4.752464759847014,
      52.75832885186332,
    ],
    '0059': [
      6.019789039587869, 53.14499340987926, 6.235100749244866,
      53.27003653182018,
    ],
    '0397': [
      4.590186182269623, 52.322204580487615, 4.641033733392852,
      52.363404858000116,
    ],
    1783: [
      4.110164141122343, 51.94138345806375, 4.309819327990657,
      52.061450716626766,
    ],
    '0085': [
      6.084847768519509, 52.90635607504152, 6.427614782917278,
      53.07527938970196,
    ],
    1883: [
      5.758002406576181, 50.9463983266985, 5.905517501038557, 51.07260985626141,
    ],
    '0060': [
      5.552855867628431, 53.31874308138936, 6.004928577355714,
      53.493948571967294,
    ],
    '0353': [
      4.978379188350332, 51.99123307695884, 5.077717623845952,
      52.06114287182499,
    ],
    '0622': [
      4.267571277507173, 51.894039902511395, 4.370622752164108,
      51.95150651792259,
    ],
    '0279': [
      5.459242996537713, 52.06961949167626, 5.539160255972826,
      52.11109726470586,
    ],
    '0375': [
      4.566312401127053, 52.45552040941977, 4.707982431709252,
      52.502035789229154,
    ],
    1876: [
      6.110812909642047, 51.967978820116116, 6.475772945827903,
      52.13771236574425,
    ],
    1731: [
      6.359556815679701, 52.73568986953095, 6.72862877684065, 53.0054052910877,
    ],
    '0406': [
      5.184702895182412, 52.267115090371384, 5.305455336624934,
      52.32576153468134,
    ],
    '0450': [
      4.690494383790232, 52.494953914593694, 4.782476523739211,
      52.55164250776378,
    ],
    '0590': [
      4.669159393773224, 51.82039426969987, 4.743514427404717,
      51.849093261215195,
    ],
    '0331': [
      4.834101755631296, 51.938030674196085, 5.062638187262243,
      52.02562706838113,
    ],
    '0173': [
      6.859781459090129, 52.28216814530556, 6.950389339096426,
      52.333460198982195,
    ],
    1719: [
      4.645136655551981, 51.628862558864654, 4.853126241787854,
      51.766568300834344,
    ],
    '0986': [
      5.861490745886198, 50.83965974154972, 5.969362418257518,
      50.90484428082313,
    ],
    '0610': [
      4.729638741852925, 51.815132610190894, 4.811415903788252,
      51.84664321899951,
    ],
    '0981': [
      5.920541177921329, 50.75275997205964, 6.027930913623473,
      50.80344034258658,
    ],
    '0758': [
      4.657981333497149, 51.485548201543224, 4.871684575951648,
      51.643198033575665,
    ],
    1916: [
      4.335213257567182, 52.05625439073444, 4.493018140943937,
      52.128979892944194,
    ],
    '0498': [
      5.091752371099029, 52.60393719600457, 5.262266083155086,
      52.70745180490155,
    ],
    '0285': [
      6.024800666255589, 52.14393807759705, 6.194369806439372,
      52.33090729068294,
    ],
    '0553': [
      4.51352302870825, 52.22220541828157, 4.57274910107545, 52.28664415888423,
    ],
    '0437': [
      4.870121334892399, 52.25074263481034, 4.955240593948781,
      52.33863824917663,
    ],
    1773: [
      6.066083366051185, 52.297627143782655, 6.23584260256108,
      52.453541037159845,
    ],
    1742: [
      6.326529933093632, 52.24214646949193, 6.554008726618212,
      52.326462251499365,
    ],
    '0363': [
      4.728758927718665, 52.277976609132836, 5.107693332999837,
      52.43106382971274,
    ],
    1978: [
      4.619248914180282, 51.831993665553185, 5.02751214704374,
      51.950927943088836,
    ],
    1676: [
      3.678928025508114, 51.59367070546305, 4.202700681186946,
      51.78152048213048,
    ],
    '0189': [
      6.46818956817129, 52.26911618143151, 6.631782412302973, 52.41751350515812,
    ],
    '0415': [
      4.893034894952897, 52.411607583489776, 4.950879454216625,
      52.49043447942631,
    ],
    '0289': [
      5.605823943327533, 51.93640548782033, 5.724362688515328,
      52.00070833149014,
    ],
    1723: [
      4.746995214628549, 51.45679034047225, 5.01667254977776, 51.55726955342276,
    ],
    '0339': [
      5.495554639171221, 52.03946881358384, 5.562746268480129,
      52.10541954408825,
    ],
    '0263': [
      5.173672434051097, 51.73358070475124, 5.416223003362996,
      51.83765876971913,
    ],
    1959: [
      4.676304294289294, 51.71081337202074, 5.142713891041348,
      51.82852918866178,
    ],
    '0453': [
      4.517549812271866, 52.41082018810661, 4.721983160290254,
      52.49274412073021,
    ],
    '0626': [
      4.401159131584874, 52.10147024233987, 4.469299924455105,
      52.14988053838646,
    ],
    '0171': [
      5.37725983726796, 52.60733122988141, 6.01730144117532, 52.84398224871387,
    ],
    '0880': [
      4.788692885933956, 52.46844416395826, 4.934724281962839,
      52.542689310525226,
    ],
    '0502': [
      4.537160429127884, 51.90620455476207, 4.619488693566413,
      51.96550383926284,
    ],
    '0718': [
      3.307952615747958, 51.37297676509772, 3.738035002900756,
      51.616407748183846,
    ],
    '0785': [
      4.962572820037418, 51.471108145014604, 5.110645407964211,
      51.540132861589846,
    ],
    1728: [
      5.183203569179373, 51.30566500564133, 5.313650171889307,
      51.43650053921047,
    ],
    1674: [
      4.306808968433563, 51.45148725644688, 4.523191632503747,
      51.56923481776432,
    ],
    1659: [
      5.533083128499178, 51.49488315887237, 5.686005090961348,
      51.569678521220325,
    ],
    '0168': [
      6.934325004760237, 52.22073699295295, 7.072763362145606,
      52.379859561847404,
    ],
    1734: [
      5.646322779974434, 51.87026538375353, 5.89803445407971, 51.97364867168397,
    ],
    '0599': [
      3.940749587511343, 51.842118251864534, 4.601808332774063,
      52.00452762371312,
    ],
    1966: [
      6.16743182005068, 53.24944790563233, 6.911786068857161, 53.57642328662697,
    ],
    1740: [
      5.453376569561035, 51.88900894724572, 5.711021610811946,
      51.95400932829523,
    ],
    '0642': [
      4.55360876984192, 51.80075997625398, 4.671708759810248, 51.84711677218853,
    ],
    1942: [
      5.012793922476526, 52.25579580126568, 5.230356735346321, 52.3886541190811,
    ],
    '0351': [
      5.298300783418982, 52.04312322315015, 5.497876037282433,
      52.11742618169183,
    ],
    '0147': [
      6.68948139232064, 52.28311348606065, 6.802135686052262,
      52.341207542794905,
    ],
    '0037': [
      6.861287523113604, 52.919037023685014, 7.10007727249069,
      53.07370171259116,
    ],
    '0784': [
      4.82908473721916, 51.510081280253466, 4.979565221961288,
      51.61629452579618,
    ],
    '0772': [
      5.3567185242749, 51.40004748297475, 5.54885288585735, 51.49707786464661,
    ],
    '0361': [
      4.708275756623361, 52.51916417633149, 4.926925536600978,
      52.68247524934715,
    ],
    '0965': [
      5.953252863144456, 50.80146244532134, 6.02502898229189, 50.86022771146569,
    ],
    1714: [
      3.358378253017131, 51.24149250769966, 3.712408774908541,
      51.40906915197771,
    ],
    1652: [
      5.651357330310808, 51.484083847839436, 5.852573783724697,
      51.598208375142214,
    ],
    1681: [
      6.724175268566277, 52.80463395654916, 7.045571091287635,
      52.99335255802957,
    ],
    '0995': [
      5.074623647521978, 52.384377413625, 5.605870635597377, 52.692844719419014,
    ],
    '0798': [
      5.048791851493401, 51.42824021445042, 5.251249881242237,
      51.54591897100702,
    ],
    1598: [
      4.862700408236497, 52.59528534632943, 5.039042968376112,
      52.699911790827336,
    ],
    '0779': [
      4.823610483103352, 51.67183545801144, 4.933169494260575,
      51.72014879074293,
    ],
    '0946': [
      5.643996789033944, 51.236924737273576, 5.87782345246325,
      51.346877060292606,
    ],
    1700: [
      6.447450143417721, 52.38362776960811, 6.717706766424709,
      52.49498074113591,
    ],
    '0232': [
      5.840830999319374, 52.25580705757677, 6.094857509991708,
      52.398437125105936,
    ],
    '0503': [
      4.320217809554831, 51.96631631205433, 4.407910943703032,
      52.032598806563264,
    ],
    1940: [
      5.33784168825177, 52.76480520877742, 5.937846998348294, 53.05883927519368,
    ],
    1655: [
      4.426674870510342, 51.54923749041279, 4.613891694164928,
      51.630406725750284,
    ],
    '0233': [
      5.545167949288571, 52.23932509784331, 5.761448750209692,
      52.332389085143355,
    ],
    '0907': [
      5.919469627269011, 51.643783110859076, 6.044939227493752,
      51.74802415871573,
    ],
    '0385': [
      4.949346047324048, 52.48456840436201, 5.112987840276817,
      52.61260604775818,
    ],
    1711: [
      5.795107146952613, 51.02833835904624, 6.036490743294993,
      51.13596212837258,
    ],
    '0606': [
      4.350671426005881, 51.896678711329066, 4.41771299465607,
      51.96340662438565,
    ],
    1955: [
      6.089514517466707, 51.867626205725664, 6.332279253119382,
      51.98051869907856,
    ],
    '0202': [
      5.802960636168883, 51.93346419832774, 5.990340117891819,
      52.077890532551216,
    ],
    '0394': [
      4.550455159443354, 52.21446840512267, 4.819158766091426,
      52.43333617014941,
    ],
    1948: [
      5.3709475786462, 51.52129623672465, 5.656555892250174, 51.65570260569092,
    ],
    '0687': [
      3.575296361903173, 51.46813936882897, 3.746943542428353,
      51.53530334522415,
    ],
    '0629': [
      4.290063252478363, 52.09477259283313, 4.443939377899825,
      52.19267739465258,
    ],
    '0225': [
      5.530981412961546, 51.836273844909705, 5.685013858385873,
      51.901803557683365,
    ],
    1669: [
      5.913748679264518, 51.09653142679457, 6.180724338525611,
      51.194414116891856,
    ],
    '0313': [
      5.310456423615208, 52.205527709064775, 5.40464338601561,
      52.28773172540302,
    ],
    '0994': [
      5.74928688173454, 50.82954880103368, 5.888009369851117,
      50.892208862545765,
    ],
    '0119': [
      6.120063733014733, 52.667987750695275, 6.286700237232867,
      52.77305426389119,
    ],
    '0770': [
      5.237597814514564, 51.31823745615023, 5.388907108345012,
      51.468536682869775,
    ],
    '0479': [
      4.674848086878976, 52.41666216918941, 4.862805083980295,
      52.521253640335836,
    ],
    '0294': [
      6.629071355769335, 51.896063339984764, 6.832801702827627,
      52.04006471355439,
    ],
    '0716': [
      3.924760580816975, 51.47955528093906, 4.242786270634194,
      51.66076431007441,
    ],
    '0252': [
      5.72974545338, 51.752100593849875, 5.893409337849443, 51.81009627391707,
    ],
    '0537': [
      4.360001838886606, 52.15565298291022, 4.466519686324997,
      52.22524368777871,
    ],
    '0847': [
      5.637854936441779, 51.31509400880806, 5.770448694285646, 51.457809890611,
    ],
    '0777': [
      4.59042061148065, 51.51720523498649, 4.694634105403334, 51.63475471313392,
    ],
    '0882': [
      5.990627228815014, 50.87021987179451, 6.094183956580347,
      50.93470139002052,
    ],
    '0484': [
      4.530103588630906, 52.05856937815117, 4.760076038337779,
      52.16750422722324,
    ],
    '0677': [
      3.924362392690125, 51.24111343716654, 4.23680534271233, 51.42201009697278,
    ],
    1903: [
      5.681970532175464, 50.75476499942106, 5.864135614610983,
      50.85529058339248,
    ],
    '0420': [
      4.971888209103066, 52.66440296768956, 5.281178970032589,
      52.91610234792677,
    ],
    '0321': [
      5.119563282174026, 51.959432482636345, 5.271446670871295,
      52.05675729620611,
    ],
    '0158': [
      6.624347427182716, 52.118069494785125, 6.889750344425829,
      52.20633143849096,
    ],
    '0262': [
      6.156078785918185, 52.09690799222475, 6.494372909249996,
      52.24611281119967,
    ],
    '0163': [
      6.333501944084968, 52.31821879906749, 6.59814765881895,
      52.461702471047545,
    ],
    '0748': [
      4.218747777113867, 51.43966697641496, 4.372798230826633,
      51.57066126133408,
    ],
    '0345': [
      5.51259224832678, 51.996958357192376, 5.590242176106738,
      52.04937330777093,
    ],
    '0845': [
      5.303797296557159, 51.600223876281774, 5.456872804658362,
      51.701500359272494,
    ],
    '0957': [
      5.916652599933734, 51.15875268967128, 6.086024933496248,
      51.258682991134314,
    ],
    1945: [
      5.879067265669811, 51.74102861120208, 6.063485648039433,
      51.88167200649899,
    ],
    1895: [
      6.903300156581589, 53.11083107379944, 7.227498451308611,
      53.333206418417056,
    ],
    '0579': [
      4.43494689964979, 52.16205195579929, 4.497592295484023, 52.20479892537132,
    ],
    '0744': [
      4.766569763011827, 51.39368697722859, 5.010996429679869,
      51.480929565294915,
    ],
    '0086': [
      5.927580593189923, 52.964363648722276, 6.332055391037408,
      53.12921346052374,
    ],
    '0542': [
      4.549238504076758, 51.90236749285934, 4.643019176800682,
      51.931050139662275,
    ],
    1774: [
      6.759035434369261, 52.272961765449445, 7.072204791916251,
      52.469766803342615,
    ],
    '0396': [
      4.571494511035275, 52.486332287005254, 4.720367158289169,
      52.533892071283745,
    ],
    1699: [
      6.30527383325499, 52.98552293800819, 6.540087016677345, 53.20383229261856,
    ],
    '0080': [
      5.63199320162004, 53.0458492347725, 5.949656660799151, 53.29472100732801,
    ],
    1894: [
      5.832075736910754, 51.26575905315706, 6.123254036724471,
      51.39572924087911,
    ],
    '0879': [
      4.52989653524391, 51.422014440799614, 4.744059817234981, 51.5497985377748,
    ],
    '0820': [
      5.505408806211745, 51.433911708417874, 5.605231780302415,
      51.53807588125627,
    ],
    '0762': [
      5.717186518243577, 51.35253496269487, 5.931287598178677,
      51.504144422511146,
    ],
    '0717': [
      3.432062335101896, 51.46224613781476, 3.748885449885481,
      51.650363341318766,
    ],
    '0150': [
      6.101320455781913, 52.217438308096966, 6.38191182490453,
      52.32112186010022,
    ],
    '0356': [
      5.062511547730813, 51.997451831552475, 5.130993349703093,
      52.06225556576857,
    ],
    '0096': [
      4.818375780076682, 53.087105152861284, 5.163222116559472,
      53.3230724925706,
    ],
    '0796': [
      5.206494262573893, 51.66399663874801, 5.499656145647512,
      51.76503027678013,
    ],
    '0826': [
      4.781071393316704, 51.578441897522, 4.934410376342724, 51.68601744985851,
    ],
    '0275': [
      5.951526399963452, 51.974406093522774, 6.137469310302331,
      52.08824704664859,
    ],
    '0281': [
      5.328030053213329, 51.851801018930175, 5.472451111172915,
      51.92276135857841,
    ],
    1581: [
      5.246989895449137, 51.97073019689493, 5.529381339283105,
      52.08656772257152,
    ],
    '0848': [
      5.423796291707433, 51.49392721278024, 5.546272863235086,
      51.54184836786849,
    ],
    '0737': [
      5.829642814910275, 53.12160799623197, 6.114593490770592,
      53.28864912739545,
    ],
    '0243': [
      5.565863666279405, 52.30153805870257, 5.712847690325963,
      52.37884126280062,
    ],
    '0518': [
      4.184998485109444, 52.01484843040601, 4.42248972121535, 52.13503621990332,
    ],
    '0166': [
      5.777750068127078, 52.47405758246343, 6.033037613881902,
      52.628302263205484,
    ],
    '0613': [
      4.383228782049525, 51.83164541946252, 4.492227226769404,
      51.87319820258595,
    ],
    '0160': [
      6.303063762773827, 52.46560325528851, 6.766693989378059,
      52.674049280336334,
    ],
    1954: [
      5.796557055180865, 50.880639450457, 6.026894542816303, 50.988416992081845,
    ],
    '0861': [
      5.315808116574273, 51.37646009935872, 5.429856256356882,
      51.438908793212796,
    ],
    1507: [
      5.87496597491246, 51.37804988701061, 6.178979718008928, 51.52477734429113,
    ],
    '0402': [
      5.102013382549322, 52.17768072240244, 5.218960393739485, 52.2855451867081,
    ],
    '0340': [
      5.477664574427498, 51.94312478509547, 5.627314537224701,
      52.018516097977205,
    ],
    '0384': [
      4.942693623161102, 52.31397885185522, 5.039969224626953,
      52.35610168000842,
    ],
    '0299': [
      5.987062649549568, 51.840721642057574, 6.167913122977045,
      52.015024452616814,
    ],
    '0277': [
      5.937098935272583, 51.99963780833093, 6.008992659651403,
      52.079164181265114,
    ],
    '0327': [
      5.310919873708932, 52.08933353432964, 5.514079464360291,
      52.16152009163653,
    ],
    1952: [
      6.639468142904809, 53.07097159579394, 6.965855434809987,
      53.29835233166226,
    ],
    1930: [
      4.185272594218937, 51.78277160841863, 4.432482404599629,
      51.872985907570964,
    ],
    1621: [
      4.424221593187225, 51.960286283288, 4.565201504261666, 52.06174116160723,
    ],
    '0307': [
      5.306458708383394, 52.10838438823703, 5.484329737863708,
      52.21915384988352,
    ],
    '0317': [
      5.222901318114247, 52.21927339981917, 5.339563659264061,
      52.29021841661105,
    ],
    1509: [
      6.289653177812857, 51.827262655286034, 6.532829065195942,
      51.97821891255573,
    ],
    '0417': [
      5.188852928951467, 52.21212871590352, 5.245093194539189,
      52.27290887445002,
    ],
    1842: [
      4.233583288017998, 51.92123631685221, 4.40303800365673,
      52.019921679947764,
    ],
    '0273': [
      5.47419209304096, 52.20233624234903, 5.683642169917135, 52.29330274760944,
    ],
    1695: [
      3.630163725370023, 51.52235216329528, 3.960959024734861,
      51.60786084939039,
    ],
    '0867': [
      4.917991224893485, 51.65153188120801, 5.111506201798753,
      51.723061889684985,
    ],
    1708: [
      5.795177104753999, 52.641363281102954, 6.201730816111653,
      52.85421495197516,
    ],
    '0589': [
      4.798700277785325, 51.98614301935237, 4.926687768165583,
      52.06344633219606,
    ],
    1961: [
      4.926301362332156, 51.85736068241161, 5.18022672890933,
      52.003035128049696,
    ],
    '0405': [
      5.013536713721525, 52.578936134322305, 5.135305730421728,
      52.684365484616116,
    ],
    '0715': [
      3.644149067493294, 51.20016211275375, 4.005693038140833,
      51.39761638712316,
    ],
    '0197': [
      6.464335119889097, 51.853823022487546, 6.668733473314511,
      51.974368359743536,
    ],
    '0399': [
      4.670615457595683, 52.57873712329028, 4.752138689641293,
      52.62502542573883,
    ],
    '0983': [
      6.071780736455769, 51.277061016051654, 6.226769353796719,
      51.50781766979428,
    ],
    '0971': [
      5.719630351829833, 50.92792793434064, 5.789247230588055,
      51.019166503075155,
    ],
    1709: [
      4.361821659220372, 51.606743674093266, 4.709879160574562,
      51.71906732151611,
    ],
    1926: [
      4.372640497544376, 51.96819860342766, 4.476790321242193,
      52.05405389896174,
    ],
    '0938': [
      5.712476188101482, 50.86597243517493, 5.803481208739807,
      50.943411014582985,
    ],
    '0352': [
      5.242355179144443, 51.95491916978727, 5.415277338741859,
      52.027001472696504,
    ],
    '0473': [
      4.477649500525647, 52.31399659853122, 4.591659031779113,
      52.40516737111805,
    ],
    '0222': [
      6.144688414114086, 51.92278202590827, 6.416081947778329,
      51.99426071406792,
    ],
    '0575': [
      4.390975657379869, 52.20735558278994, 4.563707498587194,
      52.33251094784661,
    ],
    '0296': [
      5.597976692088266, 51.77285104782119, 5.800948743437154,
      51.867449439080474,
    ],
    '0153': [
      6.755892793147529, 52.161205923773444, 6.981100137294578,
      52.285505754457596,
    ],
    '0114': [
      6.826360854695812, 52.63270575112158, 7.092739745839745,
      52.87301203796558,
    ],
    1963: [
      4.213246911718683, 51.692473556315676, 4.631633379189113,
      51.83704614359731,
    ],
    '0308': [
      5.212075635949247, 52.165379577759545, 5.335808863469474,
      52.23282883155047,
    ],
    '0228': [
      5.546744852324758, 51.99095145763928, 5.951465820177541,
      52.15222847303791,
    ],
    '0183': [
      6.669671796182378, 52.32966535406885, 6.949224076962157,
      52.47813155812772,
    ],
    '0556': [
      4.212717758360989, 51.90533503948825, 4.292611075546862, 51.9479914992892,
    ],
    '0047': [
      6.801674028203625, 53.03240649110231, 6.961913904663716,
      53.12883974338893,
    ],
    '0141': [
      6.597704879492126, 52.28997395371698, 6.721168032380362, 52.3991227541871,
    ],
    '0523': [
      4.803378620340337, 51.816471395640605, 4.926986651733371,
      51.86073466923792,
    ],
    '0230': [
      5.75629189703148, 52.357872772175064, 5.924338687275774,
      52.473401577762765,
    ],
    '0209': [
      5.66784724697427, 51.83227185805486, 5.825695408830049, 51.89567265326552,
    ],
    '0088': [
      6.050124764465447, 53.41328882077028, 6.418680968366183,
      53.539713430497045,
    ],
    '0988': [
      5.566045382853948, 51.18042068896068, 5.79177008773055, 51.29057548541476,
    ],
    1690: [
      6.216319881524311, 52.6121955206128, 6.530555892820023, 52.79897898512264,
    ],
    1904: [
      4.909638030916239, 52.11671591981597, 5.120529021768022,
      52.28932013470982,
    ],
    '0193': [
      6.003619080515191, 52.440530733582484, 6.210810751056923,
      52.587940369099194,
    ],
    '0221': [
      6.109590612348112, 51.99556605536545, 6.177299104879929,
      52.04211280570133,
    ],
    '0489': [
      4.479871635350352, 51.82985423810511, 4.571412375498532,
      51.87402722808016,
    ],
    '0824': [
      5.130196194677288, 51.50740864633935, 5.27874359580635, 51.62492842233503,
    ],
    '0293': [
      5.949249439069859, 51.93867536699385, 6.003187955740587,
      51.980650006707855,
    ],
    1896: [
      5.971341302499298, 52.55184064159241, 6.166814320538563,
      52.667936875378025,
    ],
    '0840': [
      4.485380626978177, 51.47331258894313, 4.640110146558888,
      51.56856188692696,
    ],
    1680: [
      6.561504876843922, 52.868158827454664, 6.935684881494123,
      53.0947606762106,
    ],
    '0753': [
      5.349361901469032, 51.471756400529486, 5.447507770136998,
      51.54835326515255,
    ],
    '0703': [
      3.935351596181998, 51.34975544172578, 4.277423168802465, 51.538244240824,
    ],
    '0865': [
      5.154451962814059, 51.61253775186058, 5.321190794634189,
      51.690030805203925,
    ],
    '0383': [
      4.583320938478581, 52.52672755808313, 4.781622911071806,
      52.59258472683043,
    ],
    1949: [
      5.432116414135349, 53.124149105422035, 5.749301164857709,
      53.36795842695951,
    ],
    '0512': [
      4.921688093817346, 51.81885673805091, 5.031415074191863,
      51.863629358494094,
    ],
    '0597': [
      4.549253370796552, 51.83873007562211, 4.647330812450648,
      51.90303171802359,
    ],
    '0797': [
      5.081760315134479, 51.635360427748616, 5.251611849002784,
      51.743091743062905,
    ],
    '0638': [
      4.470825853276892, 52.080510731119666, 4.550733115545123,
      52.147397866718556,
    ],
    1931: [
      4.576328523027843, 51.88935456624005, 4.877792991018089,
      52.02227172706755,
    ],
    1586: [
      6.459186902247388, 51.962547016222295, 6.687981260831572,
      52.068040320445455,
    ],
    '0216': [
      5.142432947972022, 51.91963012036745, 5.270373677227278,
      51.97893962954027,
    ],
    '0654': [
      3.695678150007799, 51.35574550350405, 3.963208623075666,
      51.50245590168573,
    ],
    '0148': [
      6.162495534345194, 52.424440678949615, 6.384150125916356,
      52.59917108182128,
    ],
    '0392': [
      4.599515653176973, 52.33890607800995, 4.686921202532774,
      52.428569663297665,
    ],
    '0852': [
      4.946865612867377, 52.3886541190811, 5.185490079189554,
      52.497886065244764,
    ],
    '0532': [
      5.185373470852319, 52.676829321326544, 5.26896217688725,
      52.717697190602834,
    ],
    '0303': [
      5.51129422417486, 52.36412960980178, 5.864502350118688, 52.66355611129787,
    ],
    '0164': [
      6.702039219740207, 52.191064903772606, 6.852527425694122,
      52.3135860866908,
    ],
    1950: [
      7.014799354237951, 52.83819614756878, 7.217445436425489,
      53.160773684647204,
    ],
    1705: [
      5.856874323668753, 51.85444929800605, 6.046284773455486,
      51.960415662358386,
    ],
    '0213': [
      6.006084955331009, 52.05918111131649, 6.21838066375661, 52.16205944004018,
    ],
    1730: [
      6.480299627534018, 53.02577314715521, 6.77650945111941, 53.19830816222313,
    ],
    1658: [
      5.473603563328806, 51.295202501912975, 5.654707231867753,
      51.422456480880925,
    ],
    '0180': [
      6.095916733191991, 52.581246299989964, 6.32929019765224,
      52.692183330914055,
    ],
    1979: [
      6.592563466115452, 53.236952468888028, 7.13486048084138,
      53.459428266378872,
    ],
    1992: [
      3.955580378742973, 51.791113312092321, 4.239383150977878,
      51.936141607769144,
    ],
    '0809': [
      4.987262128750915, 51.6056809499144, 5.106137047054621, 51.67683469012158,
    ],
    '0899': [
      5.928470065960697, 50.926439481376015, 6.018201745132624,
      50.962529606789424,
    ],
    '0377': [
      4.526045163762028, 52.309298008420136, 4.631647661910028,
      52.43930240711496,
    ],
    1525: [
      4.455648647184423, 52.17969462971325, 4.561704786939823, 52.2550674267744,
    ],
    '0177': [
      6.144145823197065, 52.3005215213028, 6.405169579936246, 52.47438835987397,
    ],
    '0531': [
      4.600154973069484, 51.823589116157436, 4.672702443996429,
      51.86984692370121,
    ],
    '0118': [
      6.403598921109111, 52.65450732028272, 6.632412604029169,
      52.80055650479076,
    ],
    '0889': [
      6.00528546525331, 51.24256008813551, 6.124757988605956, 51.29745436937513,
    ],
    '0851': [
      4.190124167488729, 51.54372140231321, 4.443999310199302,
      51.662979058981385,
    ],
    '0513': [
      4.659454627990816, 51.99625804068981, 4.75732987712614,
      52.040119697706494,
    ],
    '0546': [
      4.438865223329895, 52.11895160964979, 4.524037748068717,
      52.184626246337736,
    ],
    1892: [
      4.546339343469277, 51.93349227519387, 4.688684552956848,
      52.06554090810235,
    ],
    '0917': [
      5.895409302751053, 50.818403166700314, 6.026379433298999,
      50.93661220990217,
    ],
    '0200': [
      5.726388941033364, 52.073338553365595, 6.078484195029166,
      52.285669414522395,
    ],
    1667: [
      5.071181770690767, 51.31031660002596, 5.204697843303088,
      51.43217424731079,
    ],
    '0388': [
      5.211337581267218, 52.67572642718134, 5.37725983726796, 52.84063143053888,
    ],
    '0432': [
      4.898669474216472, 52.67176547898516, 5.011163623905528,
      52.75529633726459,
    ],
    '0678': [
      3.907727440565531, 51.447012778475376, 4.017388188492236,
      51.5514773129369,
    ],
    '0866': [
      5.417439304583078, 51.3660131011423, 5.516417681572127, 51.40534964062136,
    ],
    '0935': [
      5.638866139452907, 50.80380526812155, 5.762924863342771,
      50.91200277246024,
    ],
    '0267': [
      5.393209787524283, 52.16576178055902, 5.634862096317884,
      52.26392405343091,
    ],
    '0736': [
      4.794419112605543, 52.161460797627505, 5.021926235449557,
      52.30367703722391,
    ],
    1724: [
      5.225972020347506, 51.261353143091135, 5.433922751905057,
      51.3839050047442,
    ],
    '0765': [
      6.905497812165789, 53.01386066981118, 7.042657912486935,
      53.119196220981195,
    ],
    '0072': [
      5.095778159318643, 53.0010036964444, 5.496393579632159, 53.25132515840416,
    ],
    1969: [
      6.175234940818549, 53.08713493118852, 6.51144249426235, 53.34138176598391,
    ],
    1696: [
      5.021293029989701, 52.16592898899302, 5.145769253292128,
      52.28521548198096,
    ],
    '0757': [
      5.246177747531141, 51.54348863206187, 5.405201380286896,
      51.634633932694285,
    ],
    '0312': [
      5.153030697409061, 51.997742062689646, 5.280153305483418,
      52.08056268615348,
    ],
    '0246': [
      5.951004878916065, 52.35651244373552, 6.13055294937323,
      52.454806446353786,
    ],
    '0226': [
      5.965775545604169, 51.91233396035801, 6.068852566128004,
      51.981859318027574,
    ],
    '0109': [
      6.576428585383126, 52.626760438809754, 6.861875294764338,
      52.878691299564494,
    ],
    1701: [
      6.119819962534502, 52.729306850061086, 6.480026205269665,
      52.92764124267174,
    ],
    '0893': [
      5.976607018682194, 51.503081655039736, 6.213361347965454,
      51.67616520221261,
    ],
    '0302': [
      5.678921660210269, 52.26714586422627, 5.880951404540972,
      52.407426492068275,
    ],
    '0274': [
      5.713608280150249, 51.9589907785659, 5.870860324363396, 52.02407058455587,
    ],
    '0310': [
      5.09488978547204, 52.084895590817176, 5.230930458122319,
      52.20427342691703,
    ],
    '0301': [
      6.14038701142097, 52.08873301169249, 6.328314448528984, 52.16980127667893,
    ],
    9000: [
      5.690858248146925, 51.632667978838136, 5.934518732587673,
      51.77503386190299,
    ],
    9001: [
      4.818375780076682, 53.087105152861284, 6.418680968366183,
      53.539713430497045,
    ],
    9002: [
      4.746995214628549, 51.39368697722859, 5.01667254977776, 51.61629452579618,
    ],
    9003: [
      5.071181770690767, 51.261353143091135, 5.433922751905057,
      51.55282079062975,
    ],
    9004: [
      5.246177747531141, 51.54348863206187, 5.456872804658362,
      51.701500359272494,
    ],
    9005: [
      5.060428205379767, 52.24952707085864, 6.01730144117532, 52.84398224871387,
    ],
    9006: [
      5.566045382853948, 50.750367484696426, 6.226769353796719,
      51.77862729191275,
    ],
    9008: [
      6.119819962534502, 52.6121955206128, 7.092739745839745, 53.20383229261856,
    ],
    9009: [
      4.993854744551292, 51.73358070475124, 6.832801702827627,
      52.52202502557984,
    ],
    9010: [
      4.477649500525647, 52.16592898899302, 5.37725983726796, 53.21427480059697,
    ],
    9011: [
      4.792040368407709, 51.85736068241161, 5.627314537224701,
      52.30367703722391,
    ],
    9012: [
      5.777750068127078, 52.118069494785125, 7.072763362145606,
      52.85421495197516,
    ],
    9013: [
      3.307952615747958, 51.20016211275375, 4.277423168802465,
      51.78152048213048,
    ],
    9014: [
      3.773675409461632, 51.64377788232158, 5.031415074191863,
      52.33251094784661,
    ],
    9015: [
      4.818375780076682, 52.76480520877742, 6.427614782917278,
      53.539713430497045,
    ],
    9016: [
      6.16743182005068, 52.83819614756878, 7.227498451308611, 53.57642328662697,
    ],
    9017: [
      4.383228782049525, 51.82985423810511, 4.647330812450648,
      51.90303171802359,
    ],
    1980: [
      4.728853884940338, 52.63267786297678, 4.899897717009308, 52.7313468818704,
    ],
    '0439': [
      4.833910451402412, 52.47034854165207, 5.024700124910907,
      52.614107526972155,
    ],
    1991: [
      5.561966469350273, 51.62249637499118, 5.750478321572682,
      51.76804588992493,
    ],
    1982: [
      5.690858248146925, 51.552030865633405, 6.048120780938219,
      51.77503386190299,
    ],
    9007: [
      4.190124167488729, 51.220909401802416, 6.048120780938219,
      51.830750955946904,
    ],
  },
  be: {
    11001: [
      4.35246884982063, 51.1100326231339, 4.40746088866893, 51.1516130437549,
    ],
    11002: [4.217576738127646, 51.14332014346224, 4.509074910538003, 51.377639412761383],
    11004: [
      4.47864086898914, 51.1301513578633, 4.56381705801698, 51.1910383599308,
    ],
    11005: [
      4.3389630579638, 51.0811910434094, 4.40496151230473, 51.1067476434011,
    ],
    // 11007: [
    //   4.46873706352233, 51.1811073591352, 4.50776143818812, 51.2043651848585,
    // ],
    11008: [
      4.43894587668128, 51.2691705477059, 4.5574622610736, 51.3514349186066,
    ],
    11009: [
      4.53958410709811, 51.27615827815, 4.72225538580018, 51.3828608630607,
    ],
    11013: [
      4.39563215733028, 51.1452621434181, 4.4624510327586, 51.1737445237128,
    ],
    11016: [
      4.37805261953636, 51.4160156780886, 4.54688016607779, 51.4829242147564,
    ],
    11018: [
      4.32129270539654, 51.1298709040332, 4.35714634098057, 51.1623653997994,
    ],
    11021: [
      4.45684985201496, 51.1310389021832, 4.5090475133334, 51.1639475758964,
    ],
    11022: [
      4.38234992224247, 51.3530094924816, 4.53412869169902, 51.4543688300989,
    ],
    11023: [
      4.39044074466844, 51.2931650795807, 4.51766055549813, 51.3649353235183,
    ],
    11024: [
      4.39515030708608, 51.0935615121704, 4.48524355925714, 51.1503622981045,
    ],
    11025: [
      4.47091263209227, 51.1161814478875, 4.52608626233288, 51.1429067701296,
    ],
    11029: [
      4.43167702055352, 51.161614366218, 4.48276381048618, 51.1900863395792,
    ],
    11030: [
      4.30682303222955, 51.095637129313, 4.36192020600475, 51.1166534983842,
    ],
    11035: [
      4.52880282240208, 51.1488972403542, 4.64265788175803, 51.2366459130661,
    ],
    11037: [
      4.35809958848893, 51.0720864172797, 4.46944634603281, 51.1195337435579,
    ],
    11038: [
      4.30556302858959, 51.1132560134906, 4.37535754226892, 51.1352542187587,
    ],
    11039: [
      4.52809571964061, 51.2202979231293, 4.631861619688, 51.296190238835,
    ],
    11040: [
      4.45401256168763, 51.235792662523, 4.55168599073565, 51.3046545821513,
    ],
    11044: [
      4.32489281111806, 51.2977240620519, 4.41588101117302, 51.357212762384,
    ],
    11050: [
      4.47997414286172, 51.2163564348629, 4.55181421996278, 51.2428382472099,
    ],
    11052: [
      4.48102407250687, 51.1871972818202, 4.56101188897457, 51.2234924611375,
    ],
    11053: [
      4.50537591240164, 51.338069506343, 4.71568700494583, 51.4333770557024,
    ],
    11054: [
      4.62116282872495, 51.1758315027112, 4.74129188780467, 51.2491048231809,
    ],
    11055: [
      4.60640729352439, 51.2194449884779, 4.74206525369228, 51.294402810673,
    ],
    // 11056: [
    //   4.28882896137824, 51.1860690445582, 4.35602701898333, 51.2629054165971,
    // ],
    11057: [
      4.63274546185146, 51.2429844656259, 4.7753036270599, 51.3273727475159,
    ],
    12002: [
      4.60378517977478, 51.0757456015967, 4.69921075593432, 51.1357460155109,
    ],
    12005: [
      4.50153865852963, 50.9906957332741, 4.6335161958106, 51.0474414243765,
    ],
    12007: [
      4.17110998149775, 51.0582673991881, 4.32654026622162, 51.1253318942552,
    ],
    12009: [
      4.4634925477321, 51.0730824918363, 4.57392415549081, 51.1174385404978,
    ],
    12014: [
      4.657747987276, 50.9991495608406, 4.81694893322519, 51.127783359939,
    ],
    12021: [
      4.50460532568859, 51.0732528571162, 4.64162604722979, 51.166274554564,
    ],
    12025: [
      4.36883012917561, 50.9915105938279, 4.54777122116593, 51.0791795458583,
    ],
    12026: [
      4.595958780302, 51.1229877595953, 4.71899705991139, 51.1816814266281,
    ],
    12029: [
      4.56224290283383, 51.0177439900307, 4.68799748229211, 51.0874384906391,
    ],
    12035: [
      4.46144792945709, 51.03517936311, 4.60759847689477, 51.0903302420774,
    ],
    12040: [
      4.32157942998346, 51.0169276401471, 4.4242153531907, 51.0849506285524,
    ],
    12041: [
      4.18572606154185, 51.027227351029, 4.35899523111476, 51.1021207062288,
    ],
    13001: [
      5.0281744556742, 51.2917886358688, 5.13911593332292, 51.3940190220309,
    ],
    13002: [
      4.82516194650558, 51.393330818892, 4.95146585938037, 51.4561602080737,
    ],
    13003: [
      5.08742683835547, 51.1166842617401, 5.26095296573924, 51.2119489766888,
    ],
    13004: [
      4.7642025819761, 51.2736834070547, 4.89467160468438, 51.3451012095774,
    ],
    13006: [
      5.04855717389228, 51.2191854884982, 5.18425541479234, 51.2702576477352,
    ],
    13008: [
      4.8934444363021, 51.0898068140458, 5.07597101017956, 51.243144949367,
    ],
    13010: [
      4.6910955733076, 51.1500886733893, 4.79179037312499, 51.2167885394027,
    ],
    13011: [
      4.77434520669464, 51.103523516926, 4.88132983167056, 51.2165058320801,
    ],
    13012: [
      4.71281800571445, 51.1122830592702, 4.80918393092395, 51.1651647758559,
    ],
    13013: [
      4.79772922751491, 51.0110211107361, 4.96762249916486, 51.0917862657862,
    ],
    13014: [
      4.66530229844161, 51.3762443800901, 4.84959683148788, 51.5056412219734,
    ],
    13016: [
      4.76558118447807, 51.0288528726675, 4.86760421400697, 51.0891123625423,
    ],
    13017: [
      4.86041673206111, 51.1951981228632, 5.03095890458421, 51.2874370740262,
    ],
    13019: [
      4.76002650505351, 51.2074785857777, 4.90619441654296, 51.2995281039886,
    ],
    13021: [
      5.01000264808147, 51.0942905816562, 5.12894191374847, 51.1600884840087,
    ],
    13023: [
      4.80717459699535, 51.3370168496434, 4.93189561297707, 51.4127574680272,
    ],
    13025: [
      5.03406709449923, 51.1540210241759, 5.24089217701093, 51.3231954262799,
    ],
    13029: [
      4.8388576223059, 51.1363665202085, 4.92541345747654, 51.1974081992446,
    ],
    13031: [
      4.96088549851873, 51.276567800874, 5.05654718623481, 51.3643203582069,
    ],
    13035: [
      4.92661150955001, 51.3532611112535, 5.10331352982094, 51.4879220776927,
    ],
    13036: [
      5.01498687221503, 51.2187256589105, 5.15777502038147, 51.2981369378935,
    ],
    13037: [
      4.71596992532677, 51.3131723784214, 4.82648871396225, 51.3931743616258,
    ],
    13040: [
      4.88743834559823, 51.2629393694782, 4.98355747644168, 51.3959866956742,
    ],
    13044: [
      4.7248482583559, 51.1834479170639, 4.83455932676108, 51.2517343294302,
    ],
    13046: [
      4.83581168100868, 51.2855566441987, 4.91307350685465, 51.327689676452,
    ],
    13049: [
      4.81033220774608, 51.0643466700268, 4.94900653004853, 51.1484600962381,
    ],
    13053: [
      4.92723332319017, 51.0354265204029, 5.09908684564352, 51.1165126464963,
    ],
    21001: [
      4.24250331250646, 50.8076056146758, 4.3388478910444, 50.8508627032655,
    ],
    21002: [
      4.39299870771597, 50.790309012133, 4.48100296327247, 50.8275718160394,
    ],
    21003: [
      4.27793176353522, 50.8558952741207, 4.3127996337016, 50.8758321738314,
    ],
    21004: [
      4.3125032352127, 50.7968894307732, 4.43579671941615, 50.9144443241438,
    ],
    21005: [
      4.37785577104348, 50.823056667389, 4.41094606422669, 50.8452144032093,
    ],
    21006: [
      4.38604334955452, 50.852653252942, 4.42537139606517, 50.8864777124004,
    ],
    21007: [
      4.30076026276944, 50.798056715903, 4.35232510595633, 50.831302464969,
    ],
    21008: [
      4.2902637342968, 50.865768472863, 4.32482464377268, 50.8826435979928,
    ],
    21009: [
      4.34602640552602, 50.8012393511317, 4.40213105189755, 50.8411738309921,
    ],
    21010: [
      4.29249698161034, 50.8650379179878, 4.33928167544109, 50.8946616639931,
    ],
    21011: [
      4.30566525410176, 50.8573199378762, 4.33647168817927, 50.8681305101048,
    ],
    21012: [
      4.281848679533, 50.8444150775959, 4.34712695706465, 50.8719262272123,
    ],
    21013: [
      4.32517597384268, 50.8210927074737, 4.35729371404759, 50.8407848655982,
    ],
    21014: [
      4.35394917827779, 50.8476728109388, 4.38281704629895, 50.8608575362395,
    ],
    21015: [
      4.35592034807114, 50.8439664171222, 4.4118192538436, 50.8816089229887,
    ],
    21016: [
      4.31053434560409, 50.7642272553148, 4.41134721285499, 50.8174812314692,
    ],
    21017: [
      4.39097143410678, 50.7728696641853, 4.46900547167964, 50.8155769888312,
    ],
    21018: [
      4.39872045350812, 50.8343243438663, 4.46050678022701, 50.8635793532148,
    ],
    21019: [
      4.40305639324464, 50.8189679704945, 4.47546368174222, 50.8494879577657,
    ],
    23002: [
      4.13773712932076, 50.8739856800302, 4.29987533512919, 50.9450167798657,
    ],
    23003: [
      4.25543709751413, 50.7139647678335, 4.35472820410648, 50.7826629973646,
    ],
    23009: [
      3.88748245796679, 50.6895302310997, 3.972918839578, 50.7335786109648,
    ],
    23016: [
      4.16474460680703, 50.8131942312954, 4.28857724989382, 50.8913018547541,
    ],
    // 23023: [
    //   3.92975746500023, 50.7195977911116, 4.05074654010481, 50.7825545335506,
    // ],
    23024: [
      4.03381567637228, 50.7459302664043, 4.15111300958439, 50.816974505587,
    ],
    23025: [
      4.31707665087774, 50.9014582614661, 4.42512521857321, 50.9909434032954,
    ],
    23027: [
      4.17083177188802, 50.6900678010335, 4.31068019797176, 50.7786661353837,
    ],
    // 23032: [
    //   3.95756143747385, 50.6880393880042, 4.11519950300048, 50.7587465477987,
    // ],
    23033: [
      4.40380327414397, 50.7356808944834, 4.51026234686815, 50.7832322075075,
    ],
    23038: [
      4.51175469576566, 50.9098502720246, 4.61757198604762, 50.971126852063,
    ],
    23039: [
      4.31823767221614, 50.9691543757438, 4.38908677827659, 51.0314381409317,
    ],
    23044: [
      4.0577187654244, 50.8502310718461, 4.12508699234639, 50.8894501187931,
    ],
    23045: [
      4.2199176697591, 50.9744661876298, 4.33217672510684, 51.0437440858085,
    ],
    23047: [
      4.41445076089314, 50.8793250691366, 4.46845545079613, 50.9248463938269,
    ],
    23050: [
      4.26918830858488, 50.9212766436408, 4.3522548881307, 51.004922568703,
    ],
    23052: [
      4.1802267570157, 50.9094856416997, 4.30053410113746, 50.9990677510459,
    ],
    23060: [
      4.14156760242973, 50.9324910517777, 4.22404093450398, 50.9896630505304,
    ],
    23062: [
      4.45037638609746, 50.7278811221023, 4.61213292556466, 50.8093202794311,
    ],
    23064: [
      4.07081511935521, 50.7060504433201, 4.20120785025845, 50.7903839266072,
    ],
    23077: [
      4.1723510798077, 50.7500390348091, 4.31217794045287, 50.8280594431333,
    ],
    23081: [
      4.4565616755239, 50.8896268943712, 4.53286389544596, 50.9599616168745,
    ],
    23086: [
      4.10485722509752, 50.8412632030335, 4.2055390268281, 50.8902802447562,
    ],
    23088: [
      4.35919689830937, 50.897530270781, 4.48580948133236, 50.9598279004839,
    ],
    23094: [
      4.41960030410235, 50.8348440235644, 4.53511803779168, 50.9029160588291,
    ],
    23096: [
      4.36813564747926, 50.947067711656, 4.52743080756506, 51.0098885956283,
    ],
    23097: [
      4.03940762831943, 50.812040576882, 4.14933410297529, 50.8591362112665,
    ],
    23098: [
      4.28737017484338, 50.7802482089825, 4.32450065406621, 50.8135966779259,
    ],
    23099: [
      4.44765010086559, 50.8188513214934, 4.49164202186203, 50.8677194197217,
    ],
    23100: [
      4.32041144556764, 50.7568046856703, 4.36622758146699, 50.7804495150398,
    ],
    23101: [
      4.31993638771019, 50.7156494925644, 4.42271488279951, 50.7739158240336,
    ],
    23102: [
      4.27654421311716, 50.8909324152443, 4.337698198211, 50.9286222508752,
    ],
    23103: [
      4.46914206508334, 50.826567969591, 4.51609798532636, 50.8625424596169,
    ],
    23104: [
      4.10781914293832, 50.7802109023765, 4.21068695500107, 50.8437544003868,
    ],
    23105: [
      4.07298605279294, 50.8837744045086, 4.14923140700543, 50.9330030193872,
    ],
    23106: [3.931013500547929, 50.687492334560318, 4.152371603765016, 50.81642069623063],
    24001: [
      4.77268580606201, 50.9538574474435, 4.93516625228174, 51.0381104223658,
    ],
    24007: [
      4.75184529510782, 50.9785574762266, 4.8105953943966, 51.0394913735893,
    ],
    24008: [
      4.91812250650639, 50.8934150528834, 5.0440172597041, 50.9698595236693,
    ],
    24009: [
      4.54490709870419, 50.8189345979431, 4.65869477937277, 50.8915054867463,
    ],
    24011: [
      4.68995666888044, 50.7886903657705, 4.81817258793846, 50.8761956423814,
    ],
    24014: [
      4.51120356862035, 50.9587408489357, 4.61161451783371, 51.0039750212631,
    ],
    24016: [
      4.79475210487413, 50.7909278917651, 4.8981568603225, 50.8728395820878,
    ],
    24020: [
      4.99346766449569, 50.9433167456265, 5.15842798411456, 51.0503676218518,
    ],
    24028: [
      5.06417314982508, 50.8584343648424, 5.18674626679136, 50.9220865451446,
    ],
    24033: [
      4.58440486931272, 50.925061502205, 4.68818921602029, 50.9950551025402,
    ],
    24038: [
      4.59703131485111, 50.8789981483796, 4.70135018443369, 50.9471140216545,
    ],
    24041: [
      4.79925029946835, 50.7432437975247, 4.95253850321908, 50.8140602505348,
    ],
    24043: [
      4.71439776962548, 50.8974291806043, 4.8707509059605, 50.9637892502445,
    ],
    24045: [
      4.55387489815804, 50.7415800597385, 4.65415305024593, 50.83575347751,
    ],
    24048: [
      4.59734054355666, 50.9859300718552, 4.70821480481363, 51.0246499919247,
    ],
    24054: [
      4.96782188364886, 50.8460750433207, 5.09074572718183, 50.9331318836387,
    ],
    24055: [
      4.51379045816268, 50.8563262251067, 4.61340114765001, 50.9354159252245,
    ],
    24059: [
      4.98265401392049, 50.7038992963958, 5.12016116139733, 50.7953363018527,
    ],
    24062: [
      4.63903348583781, 50.8247551686496, 4.76914790192722, 50.9446173916289,
    ],
    24066: [
      4.74671718609442, 50.8522371046437, 4.90983681385523, 50.9123032016198,
    ],
    24086: [
      4.63079312928791, 50.7914111053006, 4.74532501331456, 50.8528072760711,
    ],
    24094: [
      4.66866222163826, 50.9289617104016, 4.80203758664597, 50.9868241863993,
    ],
    24104: [
      4.4459086726856, 50.7944271639951, 4.5852958777892, 50.8683809282538,
    ],
    24107: [
      4.85044171121277, 50.7526455806196, 5.01858736873723, 50.8648728613783,
    ],
    24109: [
      4.65158462976741, 50.9797566008241, 4.77307280584733, 51.0273564132342,
    ],
    24130: [
      5.0420943290531, 50.7796057295398, 5.15106088042632, 50.8852211192977,
    ],
    24133: [
      4.99089869458242, 50.7765791689295, 5.09250180826444, 50.868892209359,
    ],
    24134: [
      4.90103258124434, 50.9534869862236, 5.02093825971601, 51.0466624890535,
    ],
    24135: [
      4.84162675246281, 50.8808041702871, 4.95059653492928, 50.963642197546,
    ],
    24137: [
      4.90424490887336, 50.8317580390712, 4.99216404764422, 50.9017736445157,
    ],
    25005: [
      4.69616054777203, 50.7404165101222, 4.83726809292827, 50.8077683588617,
    ],
    25014: [
      4.29905760486216, 50.6264835216827, 4.41082903266826, 50.7349390366845,
    ],
    25015: [
      4.23622606476732, 50.6593766098771, 4.33236676949838, 50.7009811796317,
    ],
    25018: [
      4.62383700842079, 50.6528499561325, 4.76883304817473, 50.7240249979141,
    ],
    25023: [
      4.51099011161841, 50.5965540472313, 4.61041386039133, 50.6587331014743,
    ],
    25031: [
      4.37439033404657, 50.5546297990637, 4.54049387087462, 50.6592290422213,
    ],
    25037: [
      4.61901338734978, 50.70111476446, 4.74904918095756, 50.7999097685235,
    ],
    25043: [
      4.73030000045954, 50.6542701928572, 4.85039646626369, 50.7502559745101,
    ],
    25044: [
      4.19572106962402, 50.6140112366908, 4.32302374080012, 50.6796894305993,
    ],
    25048: [
      4.77164097526964, 50.67825656963, 4.94758535441568, 50.7740195656274,
    ],
    25050: [
      4.422456789118, 50.7161578549058, 4.51136611776401, 50.7576493012505,
    ],
    25068: [
      4.58793894399221, 50.6073085749155, 4.66501478043745, 50.6635628683261,
    ],
    25072: [
      4.24431262145106, 50.5439658782355, 4.40476181031167, 50.6359319934336,
    ],
    25084: [
      4.7322384657811, 50.5893994592125, 4.85114988221818, 50.6803733086318,
    ],
    25091: [
      4.47602322118997, 50.6955315696397, 4.56554940388757, 50.749334213302,
    ],
    25105: [
      4.12968119124224, 50.6528207173663, 4.24502931189858, 50.729621517774,
    ],
    25107: [
      4.45204189661781, 50.5259593277889, 4.58751492638384, 50.6025258707165,
    ],
    25110: [
      4.35379890749072, 50.6714907982789, 4.44004935709781, 50.7364195499711,
    ],
    25112: [
      4.52842188514633, 50.6831705163513, 4.64566488785079, 50.7643414157478,
    ],
    25117: [
      4.57203219192009, 50.5607586396005, 4.67184737507466, 50.6318110690499,
    ],
    25118: [
      4.92436626696098, 50.7240305852639, 5.01917950130929, 50.7703316915419,
    ],
    25119: [
      4.393405935549, 50.644530293968, 4.52509989439192, 50.7246959274554,
    ],
    25120: [
      4.90998040128452, 50.6401149312231, 5.01604648394795, 50.7422856932096,
    ],
    25121: [
      4.49062336810346, 50.6498177953545, 4.64682032219656, 50.6969903564932,
    ],
    25122: [
      4.82976852334003, 50.6159416915473, 4.93983554903819, 50.7043747584264,
    ],
    25123: [
      4.09004343966202, 50.6298661386965, 4.18004921269069, 50.7175161078892,
    ],
    25124: [
      4.62573347090396, 50.5947797500036, 4.74512629181602, 50.665576386949,
    ],
    31003: [
      3.27163647093504, 51.099408483523, 3.40974260766639, 51.2011846676152,
    ],
    31004: [
      3.10312309777414, 51.2769521581968, 3.17579189466687, 51.3286402920515,
    ],
    31005: [
      3.13294141370715, 51.1587234271678, 3.3088001717574, 51.3639025304276,
    ],
    31006: [
      3.24485395802382, 51.1924114357985, 3.39803986888562, 51.3086500510076,
    ],
    31012: [
      3.0388356742096, 51.1447792565662, 3.16132993837271, 51.2387185882447,
    ],
    31022: [
      3.149148239172, 51.0567815390858, 3.30091893726702, 51.1869662521792,
    ],
    31033: [
      3.04231943892276, 51.014064900961, 3.15654976667523, 51.1045332681312,
    ],
    31040: [
      3.05650334161048, 51.0844246789287, 3.21390734601195, 51.167939668619,
    ],
    31042: [
      3.06038728566654, 51.2131140288922, 3.19236012669744, 51.2913720539552,
    ],
    31043: [
      3.22384266470376, 51.2966516611354, 3.38410015037878, 51.3735248900185,
    ],
    32003: [
      2.74188431683111, 50.9751585449102, 2.95003433420426, 51.1167442043634,
    ],
    32006: [
      2.80465216040085, 50.9317244110319, 2.97249740906412, 51.0089521192994,
    ],
    32010: [
      2.89812156824365, 51.0423249037931, 3.01330278157542, 51.134263609275,
    ],
    32011: [
      2.92732373843326, 50.9845542401974, 3.06917525448641, 51.0708965318895,
    ],
    32030: [
      2.69522086535495, 50.9040753368153, 2.85523411435116, 51.0176585650573,
    ],
    33011: [
      2.76783782099785, 50.7941462458417, 2.97527254598956, 50.9392405852365,
    ],
    33016: [
      2.86840810951911, 50.7531939803764, 2.92132198066961, 50.767579511452,
    ],
    33021: [
      2.5974229545267, 50.795348840714, 2.80218656516699, 50.9377850700482,
    ],
    33029: [
      2.99733912898222, 50.7703386035711, 3.10562598468491, 50.8545737026299,
    ],
    33037: [
      2.93704762212382, 50.8006459628686, 3.06832793219961, 50.9294192516787,
    ],
    33039: [
      2.71180159457849, 50.7088235425772, 2.95924564711543, 50.8278551874078,
    ],
    33040: [
      2.84234513672704, 50.8688477068167, 2.98863429240837, 50.9578473876274,
    ],
    33041: [
      2.68881687396855, 50.8826551091435, 2.80917511094187, 50.957269274541,
    ],
    34002: [
      3.38255731421892, 50.7930640891479, 3.51339440650318, 50.866660111541,
    ],
    34003: [
      3.38902283971028, 50.7408926003139, 3.52210874146385, 50.8151827734997,
    ],
    34009: [
      3.32362632455004, 50.8118721331059, 3.39262372389351, 50.8678578474698,
    ],
    34013: [
      3.26605626310919, 50.8185225322851, 3.34801878525166, 50.9078790949592,
    ],
    34022: [
      3.19952503483562, 50.7315727481708, 3.35784060658625, 50.8751755070725,
    ],
    34023: [
      3.23920434242896, 50.8400988670467, 3.30027898810486, 50.8804493576592,
    ],
    34025: [
      3.18697296269228, 50.8718974860688, 3.27138298388353, 50.8999141551157,
    ],
    34027: [
      3.0793603995529, 50.7558597759779, 3.21841987595518, 50.8327299711846,
    ],
    34040: [
      3.31854072751053, 50.8455586948885, 3.47523642820615, 50.9119228813669,
    ],
    34041: [
      3.10113692596546, 50.7944634240868, 3.22168604652331, 50.8662548315777,
    ],
    34042: [
      3.30367185985918, 50.7380220537615, 3.45656491518176, 50.8286928602091,
    ],
    34043: [
      3.32252552032823, 50.710117237215, 3.40304455809526, 50.7486846802157,
    ],
    35002: [
      2.93874718764807, 51.2148984891939, 3.0112951100092, 51.2641115298725,
    ],
    35005: [
      2.87248672898947, 51.1109678577894, 2.9992436788032, 51.1977183837132,
    ],
    35006: [
      2.98076351687052, 51.0554000862192, 3.07733500004576, 51.1652803097688,
    ],
    35011: [
      2.74488776214091, 51.0952262140681, 2.92252248371606, 51.202721784435,
    ],
    35013: [
      2.83723008775729, 51.1851148862011, 3.00307571707917, 51.2463495963069,
    ],
    35014: [
      2.93508515931468, 51.1449892072913, 3.05684341188065, 51.224990567742,
    ],
    35029: [
      2.98304103697663, 51.2201915300765, 3.1140127043546, 51.3155533521385,
    ],
    36006: [
      3.01719111757329, 50.9578029706494, 3.14935036114313, 51.0195711839443,
    ],
    36007: [
      3.2286356586145, 50.8968679560422, 3.29625253985384, 50.9477817496427,
    ],
    36008: [
      3.1712192684126, 50.891359687461, 3.2428099200782, 50.9549784935712,
    ],
    36010: [
      3.09329177851008, 50.8481616534037, 3.20758295663695, 50.8931955648429,
    ],
    36011: [
      3.09650911301991, 50.9921243521174, 3.18238174179864, 51.0697314598766,
    ],
    36012: [
      3.02624992922427, 50.8375855802684, 3.13392412715013, 50.9284912193307,
    ],
    36015: [
      3.060691560637, 50.8875503179691, 3.17724176147486, 50.9944886525041,
    ],
    36019: [
      2.95777570344513, 50.9166270509896, 3.08708017807379, 50.999910373219,
    ],
    37002: [
      3.35956648262226, 50.9196553578932, 3.46021887372064, 50.9822035284649,
    ],
    // 37007: [
    //   3.2278003427562, 50.9196598369154, 3.35147847795829, 50.9785486210128,
    // ],
    37010: [
      3.28488648675413, 50.9048187011468, 3.3750001987167, 50.9649415881195,
    ],
    37011: [
      3.22069579204032, 50.9649753321031, 3.31096202015649, 51.0380878106558,
    ],
    // 37012: [
    //   3.32562130487403, 51.0246790293518, 3.44477960914878, 51.1015355363043,
    // ],
    // 37015: [
    //   3.28516873585471, 50.9616498683421, 3.45131507823749, 51.0541081826906,
    // ],
    37017: [
      3.30893251443134, 50.8809260420975, 3.43272544628067, 50.9462310972273,
    ],
    // 37018: [
    //   3.15646996470384, 51.0166346698646, 3.34825636385436, 51.1048017873184,
    // ],
    37020: [
      3.16304233290131, 50.944880291524, 3.24300134088775, 51.0268946141814,
    ],
    37021: [3.157693144929735, 51.01607563305254, 3.446022025769644, 51.1042674811930],
    37022: [3.229009588699241, 50.919123339247349, 3.452557095569048, 51.053584675735863],
    38002: [
      2.58895537032568, 50.9072154800758, 2.76934233673442, 51.0448253209066,
    ],
    38008: [
      2.54011213940164, 51.0466681886156, 2.63332228437006, 51.114293503543,
    ],
    38014: [
      2.60057639363945, 51.0818098729216, 2.75598208793852, 51.1503158115502,
    ],
    38016: [
      2.69807377953835, 51.0872497232674, 2.82357615641427, 51.1654993992395,
    ],
    38025: [
      2.5646707314205, 50.9858597582856, 2.77564830964055, 51.0983000203527,
    ],
    41002: [
      3.98118993288385, 50.8999389429486, 4.16219229513798, 50.9953811753353,
    ],
    41011: [
      4.02735159415027, 50.8650968265892, 4.08834413391286, 50.9117109369107,
    ],
    41018: [
      3.80238010522247, 50.72806401284, 4.00269300851665, 50.8375208364213,
    ],
    41024: [
      3.93570953371243, 50.8590848773277, 4.04034872658292, 50.9169033387003,
    ],
    41027: [
      3.83646850748262, 50.8221738828767, 3.94800697594524, 50.9186875493856,
    ],
    41034: [
      3.8663349250355, 50.9480669504185, 4.02280034202825, 50.9877091760801,
    ],
    41048: [
      3.91698045896918, 50.7717188433881, 4.07421631086128, 50.8723530233938,
    ],
    41063: [
      3.82218249846088, 50.9030715913354, 3.93652141905456, 50.9553711803842,
    ],
    41081: [
      3.74558792019675, 50.8104732256543, 3.86601408531362, 50.9190193568864,
    ],
    41082: [
      3.8914774052071, 50.8745815723225, 4.00999380056827, 50.9565234166689,
    ],
    42003: [
      3.92689063936776, 51.0046447865917, 4.04053956424331, 51.0744678664729,
    ],
    42004: [
      4.15308702543351, 50.9827446365576, 4.23982785383307, 51.0438091090177,
    ],
    42006: [
      3.99536263476159, 50.9856389038241, 4.19279804654391, 51.0729803313375,
    ],
    42008: [
      4.05742720942217, 51.0326163138125, 4.19803558704199, 51.1120931743311,
    ],
    42010: [
      3.82187956308204, 51.0178171697734, 3.93701331147341, 51.0765456692027,
    ],
    42011: [
      4.06987442082329, 50.9675532290277, 4.16769580737233, 51.0222360384934,
    ],
    42023: [
      4.01093900765812, 51.0914909609985, 4.14043005033494, 51.1464249872531,
    ],
    42025: [
      3.81942896238669, 50.9517492376041, 3.92199358806405, 51.0288857473048,
    ],
    42026: [
      3.91012953303031, 50.9718595191577, 4.00508439224699, 51.0347976291892,
    ],
    42028: [
      3.97025113280619, 51.032800484681, 4.09126829779886, 51.1023263529638,
    ],
    43002: [
      3.6112519724572, 51.1682935737835, 3.79421946949904, 51.2813577154234,
    ],
    43005: [
      3.50714868051334, 51.1628119345043, 3.60775789326356, 51.2193058329274,
    ],
    43007: [
      3.57418908958667, 51.1686312417284, 3.6638307382956, 51.2499893052948,
    ],
    43010: [
      3.35900269579195, 51.147384321314, 3.54381563936713, 51.2749681187096,
    ],
    43014: [
      3.47452392439073, 51.2159766037795, 3.68573774015837, 51.3064792565885,
    ],
    43018: [
      3.76339350471289, 51.1825232308531, 3.85499667237243, 51.2147916304392,
    ],
    // 44012: [
    //   3.6223786190743, 50.9546227962205, 3.7098514998912, 51.0148478138405,
    // ],
    44013: [
      3.75479179894689, 50.993581850564, 3.84323250969729, 51.082997549321,
    ],
    44019: [
      3.63110684261027, 51.0876946913786, 3.79813133688189, 51.199205685055,
    ],
    44020: [
      3.6227179527137, 50.9029212820744, 3.75442231627987, 50.956974500199,
    ],
    44021: [
      3.57853562013978, 50.9800754575348, 3.84808593154718, 51.189422519119,
    ],
    // 44034: [
    //   3.7853516048589, 51.0507738974492, 3.92202065895293, 51.1500070679678,
    // ],
    // 44040: [
    //   3.76164341757769, 50.9718931285248, 3.83498026055393, 51.0314780688459,
    // ],
    // 44043: [3.6816289839907, 50.9342766041608, 3.78975743941, 51.0277947006698],
    // 44045: [
    //   3.90053197795333, 51.1372599578888, 3.99742448656438, 51.2258936022191,
    // ],
    44086: [3.538548987326388, 50.928512030050662, 3.711100517177707, 51.014311988243485],
    // 44048: [
    //   3.5373053708128, 50.9290501169525, 3.67728259059282, 50.9888115675856,
    // ],
    44052: [
      3.72689595218805, 50.8952845154628, 3.84979724589673, 50.9781968408949,
    ],
    44064: [
      3.58988107840728, 50.9822555210791, 3.65383984422427, 51.035892808861,
    ],
    // 44073: [
    //   3.82776112576097, 51.1445876278656, 3.92090996789268, 51.2235496975652,
    // ],
    44081: [
      3.41409023950318, 50.8973480274941, 3.52658541498649, 50.9841217412131,
    ],
    44083: [
      3.41216429258891, 50.9476779231781, 3.61197119213646, 51.1016077508241,
    ],
    44084: [
      3.33011936658152, 51.0169940246447, 3.52442353899945, 51.1603931511891,
    ],
    44085: [
      3.5086317538611, 51.0746723502136, 3.66596038829778, 51.1760744444966,
    ],
    44087: [3.78660458303054, 51.050212361042718, 3.923277120786929, 51.223018606307306],
    44088: [3.682876846154903, 50.933737999641778, 3.836232921274054, 51.030942010530147],
    45035: [
      3.53984919867891, 50.7905798807325, 3.69646760904072, 50.9044228664738,
    ],
    45041: [
      3.53664522677643, 50.721556231539, 3.67635051054967, 50.7847694745438,
    ],
    45059: [
      3.69792423270412, 50.7480328252992, 3.81866525044254, 50.8478401954296,
    ],
    45060: [
      3.45781503892337, 50.7581116340763, 3.58655966427731, 50.8118358803374,
    ],
    45061: [
      3.45007792633145, 50.8058816752112, 3.5880700665883, 50.8872551137868,
    ],
    45062: [
      3.67093423104851, 50.7989487617046, 3.71483008618501, 50.8628124603711,
    ],
    45063: [
      3.78222405970561, 50.7752598730323, 3.88056168464616, 50.8387748605483,
    ],
    45064: [
      3.57685595568538, 50.765354961822, 3.710810182573, 50.8305945728954,
    ],
    45065: [
      3.6665074862702, 50.8337022062202, 3.76612925840766, 50.9125874876373,
    ],
    45068: [
      3.46549004512997, 50.8703551250213, 3.68552281760491, 50.9535507334479,
    ],
    // 46003: [
    //   4.15123478817081, 51.1669727287693, 4.31829571424538, 51.3544960992408,
    // ],
    // 46013: [
    //   4.23072304872078, 51.1240086369198, 4.32898911605678, 51.1985603377814,
    // ],
    // 46014: [
    //   3.89536585959542, 51.0718276568853, 4.03268949297798, 51.1652017038017,
    // ],
    46020: [
      4.05781775170703, 51.1759745226219, 4.16536531728666, 51.2933510409543,
    ],
    46021: [
      3.98406579578375, 51.1302138723123, 4.22070255403494, 51.2083342265077,
    ],
    46024: [
      3.96986375149542, 51.1693331971665, 4.09224831446666, 51.2504680073225,
    ],
    46025: [
      4.1269564857543, 51.1017056613385, 4.28464863227162, 51.1761227380055,
    ],
    46029: [3.896622406492985, 51.071291949030922, 4.034052504192, 51.225362066684291],
    46030: [4.152499816946851, 51.123472316066525, 4.357297715681366, 51.353966741512856],
    51004: [
      3.65380006661549, 50.5630308933394, 3.91204600288716, 50.6979904602198,
    ],
    51008: [
      3.60725437173009, 50.481506802631, 3.75932375488869, 50.5822762131625,
    ],
    51009: [
      3.61377463246082, 50.4428763241732, 3.75255862535918, 50.5151740896879,
    ],
    51012: [
      3.81760414604025, 50.5699400484793, 3.91045867774124, 50.6348107728682,
    ],
    51014: [
      3.71264291702371, 50.5270976312052, 3.83663213568686, 50.6140451921917,
    ],
    51017: [
      3.63647198067493, 50.6843960081377, 3.76537799638849, 50.774085675068,
    ],
    51019: [
      3.68415480213443, 50.7299165812308, 3.77581713377285, 50.7824036649132,
    ],
    51065: [
      3.48594959037966, 50.62633910061, 3.73018205234193, 50.7352758825864,
    ],
    51067: [
      3.95967325117017, 50.631087863501, 4.11237358142899, 50.7140532736007,
    ],
    51068: [
      3.87522688760432, 50.5906963163477, 4.06538739843183, 50.6960382101779,
    ],
    51069: [
      3.74360744116427, 50.6698995763166, 3.9122243063843, 50.7521860244365,
    ],
    52010: [
      4.26075341560335, 50.425093586976, 4.32560324708031, 50.507608029856,
    ],
    52011: [
      4.34680500828817, 50.353305021241, 4.50635450131524, 50.4932636413892,
    ],
    52012: [
      4.48143432472782, 50.3686159212003, 4.56193647199999, 50.4442193188,
    ],
    52015: [
      4.28567653181676, 50.4279629800435, 4.40369161827315, 50.524379612837,
    ],
    52018: [
      4.51625599286836, 50.414253212285, 4.57917425848002, 50.4516935809527,
    ],
    52021: [
      4.47693735280731, 50.4386183852316, 4.59947274377261, 50.5429099571313,
    ],
    52022: [
      4.28878398103946, 50.3668798127643, 4.3551592984727, 50.4478273442196,
    ],
    52025: [
      4.45634167025731, 50.3155256701173, 4.59303105995057, 50.3797878601535,
    ],
    52048: [
      4.3336649693752, 50.3555813417105, 4.41604919999739, 50.405028503976,
    ],
    52055: [
      4.3061136027587, 50.4684949357306, 4.45507433107671, 50.5634969620035,
    ],
    52074: [
      4.52947968954593, 50.3671705722484, 4.61600120448138, 50.4392524155064,
    ],
    52075: [
      4.37173115045899, 50.4768457691621, 4.51183094186182, 50.5651898323748,
    ],
    53014: [
      3.75740707526164, 50.3954669000047, 3.84215928371823, 50.452793094125,
    ],
    53020: [
      3.72372748809037, 50.3537491437398, 3.85116062401651, 50.4210130694315,
    ],
    53028: [
      3.83669579881803, 50.3427916423807, 3.94798747238393, 50.4248544838058,
    ],
    53039: [
      3.65395425832877, 50.4127048472074, 3.77991870104952, 50.4647132329797,
    ],
    53044: [
      3.79839563480399, 50.4892925229569, 3.99535979353456, 50.5557430041723,
    ],
    53046: [
      3.82541663777628, 50.5391863974959, 3.97813473536736, 50.6177388832428,
    ],
    53053: [
      3.85324699369591, 50.3782633675679, 4.08392052057168, 50.5143701775993,
    ],
    53065: [
      3.82751929457506, 50.4119360676725, 3.88326926312632, 50.4598561583156,
    ],
    53068: [
      3.66732295920249, 50.3704336507836, 3.74275169520164, 50.4229910428941,
    ],
    53070: [
      3.73502720429541, 50.4387000091555, 3.87820420551497, 50.5420622102476,
    ],
    53082: [
      3.81268302804104, 50.3797630133909, 3.8705818435607, 50.4350675570393,
    ],
    53083: [
      3.65584984118168, 50.303744738406, 3.83829418406204, 50.3847341311424,
    ],
    53084: [
      3.85955914113982, 50.3275056299493, 4.05379060340979, 50.4092848657952,
    ],
    55004: [
      4.02529875882339, 50.5650989671141, 4.2523931331452, 50.6695947175195,
    ],
    55035: [
      4.03777570850204, 50.4634184203554, 4.1851493366529, 50.547351666898,
    ],
    55040: [
      3.95100182634698, 50.5010138737526, 4.12943771644941, 50.6342951721764,
    ],
    55050: [
      4.12209825466822, 50.525505023071, 4.22819627763265, 50.5972021577134,
    ],
    55085: [
      4.18484299536502, 50.4968486766668, 4.33520235697486, 50.6021301864742,
    ],
    55086: [
      4.20293413092213, 50.4655114214175, 4.280439295758, 50.5219629419868,
    ],
    56001: [
      4.22657076388026, 50.3804593525472, 4.29270846731543, 50.4285775575866,
    ],
    56005: [
      4.16385909304485, 50.1594576234281, 4.34460149685338, 50.295464547964,
    ],
    56016: [
      4.19544471025057, 49.9423730240486, 4.44465959541055, 50.1293413653052,
    ],
    56022: [
      4.04151404579745, 50.256940267731, 4.21870100992393, 50.3502497373202,
    ],
    56029: [
      4.28072966806645, 50.083391310188, 4.39988490851172, 50.2439859329252,
    ],
    56044: [
      4.18194989541435, 50.3195210336099, 4.3026746147798, 50.3894107517231,
    ],
    56049: [
      4.13131202533026, 50.2817340052332, 4.26624570733291, 50.3673076049306,
    ],
    56051: [
      4.13411796870856, 49.9551589155839, 4.31338680853054, 50.0632533859156,
    ],
    56078: [
      4.22547869081299, 50.2678123305985, 4.39330664521136, 50.377176501812,
    ],
    56086: [
      4.3451390035706, 50.2900980781287, 4.47539673624443, 50.3625472925387,
    ],
    56088: [
      4.12579924448669, 50.1210793451381, 4.30302428667345, 50.2161730022636,
    ],
    57003: [
      3.40668777454195, 50.5281818769621, 3.52742325930761, 50.5881543309973,
    ],
    57018: [
      3.3641857580441, 50.6525217265238, 3.53206604960091, 50.7593230206095,
    ],
    57027: [
      3.23877506867514, 50.6531858093456, 3.33857382954223, 50.7229420462639,
    ],
    57062: [
      3.30187891505774, 50.647946227185, 3.39807530245464, 50.7232935871332,
    ],
    57064: [
      3.49500593393622, 50.4872337289228, 3.6280567199045, 50.5804924199191,
    ],
    57072: [
      3.27702844678041, 50.5131119150657, 3.36363200270168, 50.5731413731166,
    ],
    57081: [
      3.24288576690886, 50.5530193318879, 3.57564526233709, 50.6893231400013,
    ],
    57093: [
      3.32012168793058, 50.4915474268265, 3.4693898041599, 50.5680022761762,
    ],
    57094: [
      3.54721456033527, 50.5483119978431, 3.71246164747078, 50.6463146744943,
    ],
    57095: [
      3.45182947795925, 50.7040170474069, 3.54654472732693, 50.771860899725,
    ],
    57096: [
      3.17701494647317, 50.7103985396952, 3.32782208148343, 50.7664505299328,
    ],
    57097: [
      2.84089827367113, 50.6929793244811, 3.02861184788174, 50.8113309592991,
    ],
    58001: [
      4.06868884501131, 50.4338299337635, 4.2286077070899, 50.5254468703032,
    ],
    58002: [
      4.05460008708578, 50.349850664448, 4.25118358829257, 50.4474840382564,
    ],
    58003: [
      4.02613134352226, 50.3303431646678, 4.18443509740802, 50.4310446304395,
    ],
    58004: [
      4.19566754674757, 50.4224904090514, 4.28193962456646, 50.4753214746308,
    ],
    61003: [
      5.2606022603945, 50.5168681669675, 5.34895684558413, 50.5924456649514,
    ],
    61010: [
      5.02617523770793, 50.5559028329752, 5.16563156281682, 50.6087582197305,
    ],
    61012: [
      5.219816318847, 50.3655195792742, 5.42812506949575, 50.4620196395145,
    ],
    61019: [
      5.51735846004344, 50.3659152348442, 5.70804119751343, 50.460733917459,
    ],
    61024: [
      5.50346664999789, 50.4078609077965, 5.62179079145491, 50.46552966255,
    ],
    61028: [
      5.03349400318447, 50.5040783498008, 5.17123338543596, 50.5797441926973,
    ],
    61031: [
      5.12778222378583, 50.4681654134944, 5.32245886117549, 50.5395962824261,
    ],
    61039: [
      5.1955312243785, 50.427633113197, 5.28338997003951, 50.5083466467914,
    ],
    61041: [
      5.23385521268045, 50.4184900939194, 5.35349656542072, 50.5258982273264,
    ],
    61043: [
      5.33294233790187, 50.4890157513135, 5.47564196588483, 50.5445477784196,
    ],
    61048: [
      5.38297988452827, 50.4080998241652, 5.53557346587175, 50.4833153843334,
    ],
    61063: [
      5.24940697444588, 50.5746890900354, 5.34816738285781, 50.6377245531411,
    ],
    61068: [
      5.18832710755595, 50.5519046345254, 5.29600578651428, 50.6303090619315,
    ],
    61072: [
      5.13073514345464, 50.5090973397101, 5.26459130236338, 50.5831698725423,
    ],
    61079: [
      5.43649440055685, 50.4581426010316, 5.55255059752831, 50.5236322204978,
    ],
    61080: [
      5.33268905188753, 50.532474897034, 5.43938503959608, 50.593588734661,
    ],
    61081: [
      5.30680802376072, 50.4472057139811, 5.44419099524062, 50.5067787929793,
    ],
    62003: [
      5.45920423985609, 50.6496326643829, 5.5512841915363, 50.723400611717,
    ],
    62006: [
      5.41941878561678, 50.6589931339831, 5.49024566713412, 50.7396580662658,
    ],
    62009: [
      5.62249087405213, 50.3960638633703, 5.79192450457845, 50.5218553254227,
    ],
    62011: [
      5.51798180873929, 50.7311059918214, 5.68589043430489, 50.8083110603756,
    ],
    62015: [5.63464132115275, 50.6116722470178, 5.692300584798, 50.651473539],
    62022: [
      5.58792494479605, 50.5500552613171, 5.67899333023723, 50.6155748997361,
    ],
    62026: [
      5.53427606241106, 50.4598321729368, 5.63436966058437, 50.5123978526376,
    ],
    62027: [
      5.70017240947276, 50.6814552538728, 5.81745031784063, 50.7560266238503,
    ],
    62032: [
      5.49606591737059, 50.5040213313816, 5.61538153030564, 50.5816137353568,
    ],
    62038: [
      5.6428346280075, 50.590539864403, 5.71359237744272, 50.6454691478032,
    ],
    62051: [
      5.55424556110996, 50.6553308613997, 5.65613486528359, 50.7032184039226,
    ],
    62060: [
      5.47723213102813, 50.6791001696827, 5.59826352369593, 50.7499323995329,
    ],
    62063: [
      5.52197408947732, 50.5615764259884, 5.6739849748818, 50.6887430490498,
    ],
    62079: [
      5.59184341823281, 50.6819204250926, 5.69066457159729, 50.7537126916399,
    ],
    62093: [
      5.50510054382682, 50.6130317769795, 5.54804671578942, 50.6519283953169,
    ],
    62096: [
      5.46860253040657, 50.5477715323683, 5.56425091498433, 50.6291366541194,
    ],
    62099: [
      5.68796249320261, 50.5998466083084, 5.76761796713581, 50.6670910296365,
    ],
    62100: [
      5.58175349156981, 50.4769623498387, 5.76192294211658, 50.5563687047527,
    ],
    62108: [
      5.64672602281728, 50.6717199055742, 5.72394559275143, 50.8126731167579,
    ],
    62118: [
      5.34816917710518, 50.6144384641405, 5.51148093325451, 50.6613326677397,
    ],
    62119: [
      5.64105653761357, 50.6384759726491, 5.77373635792276, 50.7078875884213,
    ],
    62120: [
      5.379194277112, 50.5576324067813, 5.48770771214442, 50.6299497684811,
    ],
    62121: [
      5.4214420171816, 50.520779202308, 5.55342755372296, 50.5722855423381,
    ],
    62122: [
      5.63955540005527, 50.5472195098715, 5.7722862348697, 50.5961178408101,
    ],
    63001: [
      6.06842412703024, 50.2850939759061, 6.28474194883061, 50.4056249193162,
    ],
    63003: [
      5.79708116821987, 50.6768084940499, 5.92103182714784, 50.7180637160934,
    ],
    63004: [
      5.9327701842217, 50.520298093346, 6.13838154011084, 50.6505050073733,
    ],
    63012: [
      6.21408951353024, 50.3059660707831, 6.4067852385002, 50.5020442205645,
    ],
    63013: [
      6.12951404668385, 50.3946381787392, 6.30055030099342, 50.5225935917599,
    ],
    63020: [
      5.83689015752415, 50.597267591607, 5.91293687528978, 50.6344224178389,
    ],
    63023: [
      5.99929502665066, 50.5485852686861, 6.26816769030621, 50.6734638492943,
    ],
    63035: [
      5.72871860409792, 50.5955844980825, 5.85943418272804, 50.7039576893116,
    ],
    63038: [
      5.87654249259705, 50.4671171883844, 6.07157059418828, 50.594436665039,
    ],
    63040: [
      5.98042505252945, 50.6920759082263, 6.06119609075466, 50.7548429783999,
    ],
    63045: [
      5.70152198964797, 50.2547728407019, 5.88008014706678, 50.3652672565444,
    ],
    63046: [
      5.8970291108706, 50.5768492527173, 5.98260639115229, 50.6536997236941,
    ],
    63048: [
      5.95333099017706, 50.6501544932401, 6.07147569954464, 50.7041885299245,
    ],
    63049: [
      5.96830269537489, 50.3508835916568, 6.11236118884124, 50.5195304115976,
    ],
    63057: [
      5.69175038790313, 50.5641421812895, 5.7706525682081, 50.6109592034835,
    ],
    63058: [
      5.75000462566471, 50.5394885583033, 5.85373344978896, 50.6038944237277,
    ],
    63061: [
      6.03618551185782, 50.6010735586469, 6.27279916099394, 50.7242754759199,
    ],
    63067: [
      5.97736109081565, 50.2305589587806, 6.30663613380023, 50.3534067147274,
    ],
    63072: [
      5.81406218174325, 50.4341850833789, 5.93044773007772, 50.512687511957,
    ],
    63073: [
      5.84973680325622, 50.3360695411134, 6.02917849312737, 50.4968887382652,
    ],
    63075: [
      5.66886960576085, 50.356811115191, 5.92957686754993, 50.4646719217812,
    ],
    63076: [
      5.73741665871442, 50.4342007692224, 5.91985235965903, 50.5681932539466,
    ],
    63079: [
      5.81169117653393, 50.5498196650992, 5.92422532775712, 50.6265201296857,
    ],
    63080: [
      6.06126881403631, 50.3747363205144, 6.23315094433528, 50.5709318449068,
    ],
    63084: [
      5.88311259592427, 50.6269774312835, 5.99929502665066, 50.7000616473158,
    ],
    63086: [
      5.75893543201494, 50.3203151468838, 6.00073691575943, 50.3916099129888,
    ],
    63087: [
      6.00739097732753, 50.1301361383449, 6.19120209210028, 50.2651932244698,
    ],
    63088: [
      5.8820603400235, 50.6790702071811, 6.01969409486299, 50.7630309668126,
    ],
    63089: [
      5.82373031568463, 50.6270291461028, 5.91749610198331, 50.698818011608,
    ],
    64008: [
      5.16334475465278, 50.6841534674477, 5.23407667499568, 50.7242073630725,
    ],
    64015: [
      5.07931938443224, 50.5713219003727, 5.2085832540299, 50.649826163835,
    ],
    64021: [
      5.36174774721891, 50.6856410201136, 5.43044191860479, 50.7360132848401,
    ],
    64023: [
      5.28009836154318, 50.62577424751, 5.37528179967822, 50.675963458839,
    ],
    64025: [
      5.34709726848361, 50.6397406845888, 5.43947469160418, 50.6927289203516,
    ],
    64029: [
      5.1385198488156, 50.643906246777, 5.22563168628267, 50.6988770516376,
    ],
    64034: [
      4.9858602455367, 50.5990396728991, 5.16091849937085, 50.7167002197755,
    ],
    64047: [
      4.99124361213118, 50.6990321850945, 5.05957498333216, 50.7513166738266,
    ],
    64056: [
      5.30269606216561, 50.6999660114629, 5.39317367268781, 50.7485231291973,
    ],
    64063: [
      5.28713381660439, 50.665997001883, 5.39547884494719, 50.7045937001213,
    ],
    64065: [
      5.32483054433419, 50.5613893949969, 5.39313610599362, 50.633924291683,
    ],
    64074: [
      5.21246550512082, 50.6559968149133, 5.31198171245933, 50.72958832145,
    ],
    64075: [
      4.97802035922281, 50.5885807678472, 5.06052364529117, 50.6470309237302,
    ],
    64076: [
      5.19095752374519, 50.6163100084675, 5.28547817243164, 50.678547627099,
    ],
    71002: [
      5.52759542128952, 50.9778490156877, 5.64172907350314, 51.0380145302621,
    ],
    71004: [
      5.12024012521656, 51.0169104198741, 5.34289736935924, 51.106335764927,
    ],
    71011: [
      5.3638220336948, 50.8746445251891, 5.47796208765595, 50.9512560442435,
    ],
    71016: [
      5.38350171633192, 50.9135758774722, 5.59385868463794, 51.0213204821954,
    ],
    71017: [
      5.10024673684646, 50.6959875930749, 5.2426660167855, 50.770792326735,
    ],
    71020: [
      5.0402206904103, 50.9095269580999, 5.13809396236843, 50.9992954973351,
    ],
    // 71022: [
    //   5.21094684729353, 50.8681043420646, 5.41365528383031, 50.9821404551698,
    // ],
    71024: [
      5.11539092035396, 50.8930311232121, 5.22867280902502, 50.9718043642798,
    ],
    71034: [
      5.20019343869079, 51.0964108884349, 5.29962967111544, 51.1476711970833,
    ],
    71037: [
      5.1071694637379, 50.9566682844307, 5.25166126181733, 51.0419688791575,
    ],
    71045: [
      5.14714433961228, 50.8523111873784, 5.26229680882189, 50.9108736120388,
    ],
    71053: [
      5.10530073134083, 50.7494458944848, 5.28558042992885, 50.8778714840947,
    ],
    // 71057: [
    //   4.97879454068647, 51.0159958155742, 5.18262102738843, 51.0860087255481,
    // ],
    71066: [
      5.29974716874911, 50.9634297180725, 5.46434112355802, 51.0130134843183,
    ],
    71067: [
      5.52683990771792, 50.8956292479255, 5.61231041179588, 50.9778904782447,
    ],
    // 71069: [
    //   5.09363933157699, 51.074245156301, 5.22906813716204, 51.1411494362038,
    // ],
    71070: [
      5.22298289729853, 50.9757276616079, 5.35065813826825, 51.0732119160622,
    ],
    71071: [4.980080346070526, 51.015451797241155, 5.23035011596046, 51.140592207444783],
    71072: [5.212146669571512, 50.807773678193186, 5.450136331695584, 50.981593928155704],
    72003: [
      5.4761412308109, 51.1310988061981, 5.65815286014329, 51.223448345464,
    ],
    72004: [
      5.54517947243143, 51.1030613901936, 5.71717617140632, 51.1852854802961,
    ],
    72018: [
      5.65673440753447, 51.1204083129105, 5.85446222165692, 51.1900396617154,
    ],
    72020: [
      5.21333608574353, 51.1581677439845, 5.4161087604029, 51.2763015944826,
    ],
    72021: [
      5.60603954222061, 51.0193252825735, 5.83250905683099, 51.1305566446631,
    ],
    72030: [
      5.38791053841382, 51.0555393048656, 5.54631421520642, 51.1900398709393,
    ],
    72037: [
      5.43706009175007, 51.2105681406892, 5.55900309092572, 51.3006995006879,
    ],
    72038: [
      5.25969368532168, 51.0882910084063, 5.43628261582499, 51.1824255118541,
    ],
    72039: [
      5.33422267020809, 51.0015545011515, 5.52087329015308, 51.1045607792893,
    ],
    72041: [
      5.62115811829513, 50.996399416412, 5.79985297687097, 51.0828979252698,
    ],
    72042: [
      5.45308454218721, 51.0193142330123, 5.66476058176456, 51.1468906973153,
    ],
    72043: [
      5.31884314523465, 51.1648033198424, 5.52775254712675, 51.2769387994535,
    ],
    73001: [
      5.24083234282785, 50.8542153657341, 5.35071176773048, 50.910931858483,
    ],
    // 73006: [
    //   5.43911383783181, 50.8168965899988, 5.62373337233901, 50.9231716619394,
    // ],
    // '73009': [
    //   5.24908666812451,
    //   50.7700099192991,
    //   5.40635971497156,
    //   50.845971750561,
    // ],
    73022: [
      5.23306781702946, 50.7156527561482, 5.40091113604706, 50.7924248680799,
    ],
    73028: [5.4141434854215, 50.7186559479028, 5.43498119590361, 50.7359857555],
    // 73032: [
    //   5.42781386417102, 50.8142890635453, 5.51227926176831, 50.8889812951245,
    // ],
    // 73040: [
    //   5.35116547392538, 50.8083257743514, 5.44884008366794, 50.8840052788667,
    // ],
    73042: [
      5.56983239468595, 50.8338003904636, 5.72324054591196, 50.9450669818337,
    ],
    73066: [
      5.51227926176831, 50.7641122641772, 5.68685780815381, 50.8439379645145,
    ],
    // '73083': [
    //   5.38768155263566,
    //   50.7207129415888,
    //   5.55165763371361,
    //   50.822907100864,
    // ],
    73098: [
      5.2634967798772, 50.8123348104213, 5.37096291428768, 50.868144281726,
    ],
    73107: [
      5.59401653587321, 50.9109571647631, 5.76636127481148, 51.0128214371497,
    ],
    73109: [
      5.68086955269347, 50.7104228064137, 5.90980206948496, 50.7837993188882,
    ],
    73110: [5.429109071594002, 50.813736821861461, 5.625029830849205, 50.922622413910631],
    73111: [
      5.250376655557812, 50.720158117801134, 5.552955425959436,
      50.845420893057046,
    ],
    81001: [
      5.6771772097276, 49.6224449651698, 5.90938467457842, 49.7278363560584,
    ],
    81003: [
      5.67513414080349, 49.6972207209642, 5.83138505338844, 49.7972261340728,
    ],
    81004: [
      5.70224387132183, 49.5392926050337, 5.87198588377189, 49.6123442542202,
    ],
    81013: [
      5.68340204843577, 49.7726339635333, 5.75708895848472, 49.8583579965441,
    ],
    81015: [
      5.72730470379629, 49.5724841773729, 5.87883632196812, 49.6606511337284,
    ],
    // 82003: [
    //   5.63705855799317, 49.9098659273749, 5.86859867431925, 50.1178075902099,
    // ],
    // 82005: [
    //   5.53708930978782, 50.007197776248, 5.73136286532124, 50.1171112220602,
    // ],
    82009: [
      5.61288023232755, 49.8201799112941, 5.78269821199431, 49.9425972292528,
    ],
    82014: [
      5.65060902387022, 50.062182933652, 5.8941620120229, 50.2367148900241,
    ],
    82032: [
      5.70837024970509, 50.2194232764633, 6.01598145785667, 50.3488629712986,
    ],
    82036: [
      5.49388969283524, 49.8675706549119, 5.71218744513621, 50.0123974323969,
    ],
    82037: [
      5.78676966936628, 50.1107554596318, 6.03308631048983, 50.2709107375424,
    ],
    82038: [
      5.41351166669853, 49.9559814278661, 5.62842113339161, 50.0782963997259,
    ],
    82039: [5.538376148598505, 49.909286619800852, 5.869894367623403, 50.117233804303083],
    83012: [
      5.34011125588375, 50.2995501714668, 5.63599919787945, 50.4311751278737,
    ],
    83013: [
      5.43973328497215, 50.2475000737823, 5.65360798576382, 50.3427685994974,
    ],
    83028: [
      5.36824610585773, 50.216878806463, 5.50212295165267, 50.3196010178203,
    ],
    83031: [
      5.45835340991807, 50.0904165583842, 5.7430589280701, 50.2311934140774,
    ],
    83034: [
      5.20525248524492, 50.1579043934632, 5.47186511171042, 50.2795048992825,
    ],
    83040: [
      5.2409543200179, 50.0790876491167, 5.46528076445076, 50.2004056273344,
    ],
    83044: [
      5.44069477263077, 50.1642104991322, 5.61086325957585, 50.265643544621,
    ],
    83049: [
      5.38895882099694, 50.0483743746888, 5.59606866333108, 50.1520321491506,
    ],
    83055: [
      5.57262684019816, 50.2205806215568, 5.74752380974546, 50.3726045377862,
    ],
    84009: [
      5.15680444434154, 49.772133086641, 5.36663051152018, 49.9324463439016,
    ],
    84010: [
      4.96721726127291, 49.7097147086209, 5.1883193298146, 49.8842607386502,
    ],
    84016: [
      4.97158341318464, 49.9615563391109, 5.15078572207585, 50.0459968934341,
    ],
    84029: [
      5.21406472080491, 49.759986869204, 5.3952421168072, 49.8321231746903,
    ],
    84033: [
      5.41030310436093, 49.7349322961166, 5.70407768418719, 49.9014584243671,
    ],
    84035: [
      5.12264240373487, 49.908433868751, 5.32892540564827, 50.0463072043375,
    ],
    84043: [
      5.31372392874823, 49.779444077846, 5.52810221961328, 49.9036135254776,
    ],
    84050: [
      5.04104877712733, 49.8259569509718, 5.20028583585442, 49.993762515247,
    ],
    84059: [
      5.22789233495412, 49.980335804241, 5.45905551164235, 50.0964114864168,
    ],
    84068: [
      5.15114232990992, 50.0263775713971, 5.31533663851467, 50.1108130226817,
    ],
    84075: [
      5.0097043874352, 50.016614596259, 5.17690790664986, 50.1233545139374,
    ],
    84077: [
      5.29023219530436, 49.8757317337175, 5.55215725370288, 50.0257588549051,
    ],
    85007: [
      5.30765463883267, 49.6529397347693, 5.48079934356341, 49.7908498264058,
    ],
    85009: [
      5.52629743373646, 49.6215707317674, 5.69802410253281, 49.7120480160743,
    ],
    85011: [
      5.14574494620487, 49.6113661869144, 5.423594689258, 49.7817808601553,
    ],
    85024: [
      5.37466697788951, 49.5642641473327, 5.53201849342758, 49.6405025789162,
    ],
    85026: [
      5.61527297291376, 49.5394442933897, 5.73336774610397, 49.6081537564499,
    ],
    85034: [
      5.61415943801306, 49.5870798103672, 5.74184515542198, 49.6467962660051,
    ],
    85039: [
      5.41513287248853, 49.6199452794513, 5.55547403447691, 49.7839779936681,
    ],
    85045: [
      5.49946423772669, 49.5065549390113, 5.64266735565801, 49.6399219443412,
    ],
    85046: [
      5.49943003872872, 49.6878293894299, 5.71205946057163, 49.7845473851466,
    ],
    85047: [
      5.44011307568525, 49.4975444768668, 5.5322499158146, 49.575511553949,
    ],
    91005: [
      4.72515263589117, 50.2808363805377, 4.8955926617932, 50.3618147076338,
    ],
    91013: [
      4.81769541612548, 50.0182637805022, 5.07956030763483, 50.1658144757999,
    ],
    91015: [
      4.9291881118124, 49.8433819085029, 5.11839200040587, 50.0168341617328,
    ],
    91030: [
      5.0012000225259, 50.2091234114311, 5.26025182508614, 50.3409380365696,
    ],
    91034: [
      4.85314629018228, 50.1810255435373, 5.04666868044733, 50.3050883550935,
    ],
    91054: [
      4.78902682724563, 49.9175584086651, 5.00797098423686, 50.0522186037757,
    ],
    91059: [
      5.02341990270124, 50.2911199092631, 5.23711150831964, 50.384075755259,
    ],
    91064: [
      5.1286890380289, 50.2946461730365, 5.36193922552705, 50.4178411679415,
    ],
    91072: [
      4.8512876624866, 50.1327091698882, 5.11858721960121, 50.2519402121395,
    ],
    91103: [
      4.73517237799859, 50.21531319139, 4.89234049223253, 50.3116232272615,
    ],
    91114: [
      5.05403890879984, 50.0941444369702, 5.28273841803979, 50.2390274408785,
    ],
    91120: [
      5.21265132745541, 50.2395734320335, 5.40165415424637, 50.3889471778104,
    ],
    91141: [
      4.85250076237904, 50.281469024531, 5.02346206787256, 50.3646671232802,
    ],
    91142: [
      4.75109216511604, 50.1488656099811, 4.89928592130165, 50.247665111648,
    ],
    91143: [
      4.84618645310125, 49.7860854739456, 4.98934020108645, 49.9317061955134,
    ],
    92003: [
      4.9605865455213, 50.4480496791151, 5.16267133720881, 50.5324033104051,
    ],
    92006: [
      4.89439048668699, 50.3333791953497, 5.09960601085267, 50.4191032879243,
    ],
    92035: [
      4.78526961705383, 50.526613812932, 4.9884989972509, 50.6489986600229,
    ],
    92045: [
      4.69399880629213, 50.3936563459246, 4.82811554857138, 50.472482188649,
    ],
    92048: [
      4.58253415459373, 50.3588855215633, 4.79791300277223, 50.4286931372569,
    ],
    92054: [
      4.95720469521746, 50.3738632753534, 5.14416135152561, 50.4629626715914,
    ],
    92087: [
      4.58063384526998, 50.2619830267585, 4.76675271422263, 50.3803736633528,
    ],
    92094: [
      4.72169619700936, 50.3878838609539, 4.98307539354835, 50.5316636872878,
    ],
    92097: [
      5.07494117036411, 50.398812858813, 5.23585982587179, 50.4794934167048,
    ],
    92101: [
      4.73968443242449, 50.3433139646961, 4.91800502609999, 50.4116824106933,
    ],
    92114: [
      4.55609504570116, 50.4847610303776, 4.64381687718173, 50.5701257825243,
    ],
    92137: [
      4.57138225242594, 50.4009077548575, 4.66901481492958, 50.4931684216771,
    ],
    92138: [
      4.91565508048677, 50.5054699568451, 5.05295263688023, 50.6191496272872,
    ],
    92140: [
      4.61929265833483, 50.421534196688, 4.73176712506445, 50.513646046411,
    ],
    92141: [
      4.74333803281923, 50.4877002534577, 4.8973914932227, 50.5736191586481,
    ],
    92142: [
      4.62397838641995, 50.4963721932167, 4.80579480190137, 50.6119659055216,
    ],
    93010: [
      4.36032675534656, 50.1223321933182, 4.51760757100594, 50.2412337770617,
    ],
    93014: [
      4.38435937862632, 49.9378102110407, 4.60411439142955, 50.1401644693433,
    ],
    93018: [
      4.58068718838279, 50.0926771945049, 4.79827370294917, 50.2105771855468,
    ],
    93022: [
      4.50328645167565, 50.1981245416284, 4.78672735388727, 50.323523530391,
    ],
    93056: [
      4.47496127614681, 50.0917436048026, 4.72806635377687, 50.2316104742298,
    ],
    93088: [
      4.28456498581936, 50.2165673362868, 4.54408648491179, 50.3296095071515,
    ],
    93090: [
      4.52496871728792, 49.9861668535262, 4.72122862090996, 50.1146396784226,
    ],
    2000: [2.55651, 50.6741, 5.92, 51.4957],
  },
}
