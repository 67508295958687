/**
 * Import Dependency
 */

import { unique } from '@/helpers/array.js'
import {
  slugToCode,
  deprecatedCodes,
  allRegions,
  provincieCodeByMunicipalityCode,
} from '@/services/municipalities'
import { USER_ROLE } from '@/../shared/valueholders/userRoles'
import { layers as layerDetails } from '@/data/layerDetails'
import { GEOPGRAPHY_TYPE } from '../../shared/valueholders/geography-type'
import { Bugfender } from '@bugfender/sdk'

/*
 * Helper
 */

/**
 * NOTE: Not all types of include/exclude are actually implemented yet.
 *
 * Working restrictions:
 * - regions with geography type province
 * - regions with proxy
 * - geography type provinces
 * - geography type municipality
 *
 * missing:
 * - inclusion of regions with geography type municipality
 * - exclusion of regional lauers
 */
function hasMunicipalityAccessToRegionalLayer({ layer, code }) {
  let canAccess = false

  layer.restrictions.include.regional.forEach(accessGeography => {
    switch (accessGeography.type) {
      case GEOPGRAPHY_TYPE.REGION: {
        let region = allRegions().find(region => region.slug === accessGeography.slug)
        if (! region) {
          return false
        }

        // todo:: implement region with geography type municipality as well
        const haveGeographiesAccess = region.geographies?.some(geopgrahpy => geopgrahpy.code === provincieCodeByMunicipalityCode({ code })) ?? false
        const hasProxyAccess = region.proxy?.includes(code) ?? false

        if (haveGeographiesAccess || hasProxyAccess) {
          canAccess = true
        }
        break
      }

      case GEOPGRAPHY_TYPE.PROVINCE: {
        let provinceCode = provincieCodeByMunicipalityCode({ code })

        const hasProvinceAccess = accessGeography.code === provinceCode
        if (hasProvinceAccess) {
          canAccess = true
        }
        break
      }

      case GEOPGRAPHY_TYPE.MUNICIPALITY: {
        const hasMunicipalityAccess = accessGeography.code === code
        if (hasMunicipalityAccess) {
          canAccess = true
        }
      }
    }

    return canAccess
  })

  // todo:: implement exclusive regional layers

  return canAccess
}

/**
 * Declare Variable
 */
const state = {
  active: '',
  /**
   * User roles determine the access to municipalities
   */
  roles: [],
  workflowTenant: null,
  stakeholder: null,
}

const getters = {

  /**
   * The active Municipality (code)
   */
  getActiveMunicipality: (state) => state.active,
  getRoles: (state) => state.roles,
  getWorkflowTenant: state => state.workflowTenant,
  getStakeholder: state => state.stakeholder,

  /**
   * Returns a list of codes of municipalities the user can access
   */
  municipalityCodeList: state => unique({
    arr: state.roles
      .reduce((result, role) => result.concat(role.municipalities), []),
  }).filter(code => !deprecatedCodes.includes(code)),


  municipalityGuestCodeList: state => unique({
    arr: state.roles
      .filter(role => USER_ROLE.GUEST === role.role)
      .reduce(
        (result, role) => result.concat(role.municipalities), [],
      ),
  }).filter(code => !deprecatedCodes.includes(code)),

  municipalityEditorCodeList: state => unique({
    arr: state.roles
      .filter(role => USER_ROLE.EDITOR === role.role)
      .reduce(
        (result, role) => result.concat(role.municipalities), [],
      ),
  }).filter(code => !deprecatedCodes.includes(code)),

  municipalityCpoCodeList: state => unique({
    arr: state.roles
      .filter(role => USER_ROLE.CPO === role.role)
      .reduce(
        (result, role) => result.concat(role.municipalities), [],
      ),
  }).filter(code => !deprecatedCodes.includes(code)),

  municipalityMunicipalityCodeList: state => unique({
    arr: state.roles
      .filter(role => USER_ROLE.MUNICIPALITY === role.role)
      .reduce(
        (result, role) => result.concat(role.municipalities), [],
      ),
  }).filter(code => !deprecatedCodes.includes(code)),

  municipalityAdminCodeList: state => unique({
    arr: state.roles
      .filter(role => USER_ROLE.ADMIN === role.role)
      .reduce(
        (result, role) => result.concat(role.municipalities), [],
      ),
  }).filter(code => !deprecatedCodes.includes(code)),

  /**
   * Whether the user can access a particular municipality, by code or slug
   */
  hasAccess: (state, getters) => ({ slug, code }) => {
    if (slug) {
      code = slugToCode({ slug })
    }
    return getters.municipalityCodeList.includes(code)
  },

  hasAdminAccess: (state, getters) => ({ code, slug }) => {
    if (slug) {
      code = slugToCode({ slug })
    }
    return getters.municipalityAdminCodeList.includes(code)
  },

  canAccessMultipleMunicipalities: (state, getters, rootState, rootGetters) => {
    if (rootGetters['currentUser/getCurrentUserRole'] === USER_ROLE.SUPERUSER) {
      return true
    }

    return getters.municipalityCodeList.length > 1
  },

  municipalityPlanModeAccessList: (state, getters) => {
    return [
      ...getters.municipalityCpoCodeList,
      ...getters.municipalityEditorCodeList,
      ...getters.municipalityMunicipalityCodeList,
      ...getters.municipalityAdminCodeList,
    ]
  },

  /**
   * Check whether the user has editor or admin rights to the current municipality
   */
  canAccessPlanmode: (state, getters) => {
    let codes = getters.municipalityPlanModeAccessList
    return codes.includes(getters.getActiveMunicipality)
  },

  /**
   * Check whether the user can pre-access the plan mode,
   *  before it is made available to the regular municipality users
   */
  canPreAccessPlanMode: (state, getters) => {
    return getters.hasAdminAccess({ code: getters.getActiveMunicipality })
  },
  canPreAccessPlanModeBy: (state, getters) => ({ code, slug }) => {
    if (slug) {
      code = slugToCode({ slug })
    }
    return getters.hasAdminAccess({ code })
  },
  canAccessLayer: (state, getters, rootState, rootGetters) => ({ id }) => !!rootGetters['layers/getLayerDetailsById']({ id }),
  canAccessRegionalLayer: (state, getters, rootState, rootGetters) => ({ id= null, layer = null }) => {
    const code = getters['getActiveMunicipality']

    if (! layer && id) {
      layer = rootGetters['layers/getLayerDetailsById']({ id })
    }

    if (! layer) {
      Bugfender.warn(`canAccessRegionalLayer - layer with id ${id} not found`)
      return false
    }

    // always display layer if there are no restrictions
    if (
      layer.restrictions?.include?.regional &&
      ! hasMunicipalityAccessToRegionalLayer({ layer, code })
    ) {
      return false
    }

    return true
  },

  canRoleAccessLayer: (state, getters, rootState, rootGetters) => ({ id = null, layer = null, status = null }) => {
    if (!id && !layer && status) {
      id = status.startsWith('chargingpoints-') ? status : `chargingpoints-${status}`
    }

    if (id && !layer && !status) {
      layer = layerDetails.find(layer => layer.id === id)
    }

    if (!layer) {
      Bugfender.warn(`canRoleAccessLayer - layer with id ${id} not found`)
      return false
    }

    // check if it's an inclusive layer is
    if (
      layer.restrictions &&
      layer.restrictions.include?.roles &&
      ! layer.restrictions.include.roles.includes(rootGetters['currentUser/getCurrentUserRole'])
    ) {
      return false
    }

    // check if it's an exclusive layer is
    if (
      layer.restrictions &&
      layer.restrictions.exclude?.roles &&
      layer.restrictions.exclude.roles.includes(rootGetters['currentUser/getCurrentUserRole'])
    ) {
      return false
    }

    return true
  },
}
const actions = {
  setWorkflowTenant ({ commit }, { tenant }) {
    commit('setWorkflowTenant', { tenant })
  },
  async setStakeholder ({ commit }, { code }) {
    const token = await this.$auth.getTokenSilently()
    const api = await fetch('/api/get-stakeholder-by-category', {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ category: 'municipality', code }),
    })

    if (!api.ok) {
      console.log('Could\'t fetch stakeholder.')
    }

    const stakeholder = await api.json()
    if (stakeholder) {
      commit('setStakeholder', { stakeholder })
    }
  },
}
const mutations = {
  /**
   * Set the active municipality, by code or slug
   */
  setActiveMunicipality(state, { code, slug }) {
    if (slug) {
      code = slugToCode({ slug })
    }
    state.active = code
    this.dispatch('access/setStakeholder', { code })
  },
  setWorkflowTenant (state, { tenant }) {
    state.workflowTenant = tenant
  },
  setStakeholder (state, { stakeholder }) {
    state.stakeholder = stakeholder
  },
  setRoles(state, { roles }) {
    state.roles = roles
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
