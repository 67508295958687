import { mapActions, mapGetters } from 'vuex'

import { initiate, subscribe } from '@/services/pusher'
import { EventBus } from '@/services/eventbus'

import privilegesMixin from '@/mixins/common/privilegesMixin'
import { checkStatus, returnJson } from '@/helpers/api'
import statusMixin from '@/mixins/common/statusMixin'

export default {
  mixins: [privilegesMixin, statusMixin],
  data() {
     return {
       channel: null,
       isLoadingFauna: false,
     }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
      'canAccessLayer',
    ]),
    ...mapGetters('users', [
      'getUsersByCode',
    ]),
  },
  methods: {
    ...mapActions('planmode', ['addOrUpdateChargingPoint', 'deselectChargingPoint', 'clearAlternativeLocationForUuid']),
    $_chargingpointEditMixin_connectToPusher: async function() {
      const token = await this.$auth.getTokenSilently()
      initiate({ token })
      let { channel } = await subscribe({ channel: this.getActiveMunicipality })
      this.channel = channel
    },
    $_chargingpointEditMixin_exists: async function({ code, refId }) {
      const token = await this.$auth.getTokenSilently()
      const api = await fetch('/api/chargingpointexists', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ code, refId }), // '1930'
      })

      return await api.json()
    },
    $_chargingpointEditMixin_save: async function({ data, rollback= false }) {
      this.isLoadingFauna = true

      // reset
      this.error = false
      this.clearAlternativeLocationForUuid()

      const token = await this.$auth.getTokenSilently()
      const response = await fetch('/api/chargingpointssave', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(data), // '1930'
      })
        .then(await checkStatus)
        .then(returnJson)
        .catch((err) => {
          console.log(err)
          this.$notify({
            type: 'error',
            title: 'Niet gelukt!',
            text: 'Er ging iets mis tijdens het opslaan van de locatie.',
          })

          this.addOrUpdateChargingPoint({ chargingpoint: rollback })
        })

      if (response?.chargingpoint) {
        this.addOrUpdateChargingPoint({
          chargingpoint: response.chargingpoint,
        })

        if (this.channel) {
          this.channel.trigger('client-chargingpoint-saved', {
            chargingpoint: response.chargingpoint,
          })
        }
      }

      this.isLoadingFauna = false
    },

    $_chargingpointEditMixin_delete: async function({ code, ref }) {
      this.isLoadingFauna = true

      const token = await this.$auth.getTokenSilently()
      const response = await fetch('/api/chargingpointsdelete', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ code, ref }), // '1930'
      })
        .then(await checkStatus)
        .then(returnJson)
        .catch(() => this.$notify({
          type: 'error',
          title: 'Niet gelukt!',
          text: 'Er ging iets mis tijdens het verwijderen van de locatie.',
        }))

      if (response.chargingpoint) {
        this.addOrUpdateChargingPoint({
          chargingpoint: response.chargingpoint,
        })

        if (this.channel) {
          this.channel.trigger('client-chargingpoint-saved', {
            chargingpoint: response.chargingpoint,
          })
        }
      }

      this.isLoadingFauna = false

      // broadcast to close popup / sidebar
      EventBus.$emit('deselect-chargingpoint')
    },
  },
}
