<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="panel d-flex">
    <aside class="aside flex-shrink-0 mr-3">
      <h3 class="title">
        {{ $t("bulkTrafficDecisionPanel.title") }}
      </h3>
      <div class="w-75 mt-3">
        <p v-html="$t('bulkTrafficDecisionPanel.description')" />
      </div>
      <div class="d-flex align-items-center mt-4">
        <b-button
          variant="primary"
          @click="applyFilters"
        >
          <span>{{ $t("components.sidebar.PlanModeSearch.apply") }}</span>
        </b-button>
      </div>

      <div class="d-flex align-items-center mt-2">
        <b-button @click="exportDocx">
          {{ $t("download") }}
        </b-button>
      </div>
    </aside>

    <div class="main flex-grow-1 flex-shrink-1">
      <div class="d-flex align-items-center">
        <QuillEditor
          ref="quillEditor"
          :initial-content="data.bulkTrafficDecisionText"
          text="bulkVKB"
          @content-changed="handleEditorChange"
        />
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="mr-3">
          {{ $t("participationPanel.options.phases.title") }}
        </span>
        <vSelect
          v-model="selectedPhases"
          :options="phaseOptions"
          :searchable="false"
          :select-group-label="$t('components.form.select.select-groupLabels')"
          class="flex-grow-1 mr-3"
          multiple
        />
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="mr-3">
          {{ $t("participationPanel.options.cpos.title") }}
        </span>
        <vSelect
          v-model="cpos"
          :options="cpoOptions"
          class="flex-grow-1 mr-3"
          multiple
        />
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="BulkTrafficDecisionPanel__Label mr-3">
          {{ $t("participationPanel.options.statusStations.title") }}

          <SelectStatusesModal
            :preselected="statuses"
            @select="handleStatusSelect"
          />
        </span>

        <vSelect
          v-model="statuses"
          :disabled="true"
          class="flex-grow-1 mr-3"
          multiple
        />
      </div>

      <div class="d-flex align-items-center mt-4">
        <span class="mr-3">
          {{ $t("bulkTrafficDecisionPanel.inProcess") }}
        </span>
        <b-dropdown
          variant="outline-secondary"
          class="flex-grow-1 mr-3"
        >
          <template #button-content>
            <span>{{ processOptionLabel }}</span>
          </template>

          <b-dropdown-item
            v-for="option in processOptions"
            :key="option.value"
            @click="data.inProcess = option.value"
          >
            {{ option.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'

import SelectStatusesModal from '@/components/admin/municipalities/SelectStatusesModal.vue'
import QuillEditor from '@/components/common/QuillEditor'
import vSelect from '@/components/form/vSelect'

import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import { saveAs } from 'file-saver'
import htmlDocx from 'html-docx-js/dist/html-docx'

import {
  statusesWithLabelDutch,
  statusesWithLabelEnglish,
} from '@/../shared/services/statusTranslations'
import { labelByCode } from '@/services/municipalities'

import chargingpointCpoMixin from '@/mixins/chargingpoint/chargingpointCpoMixin'
import chargingpointsLoadMixin from '@/mixins/chargingpoint/chargingpointsLoadMixin'

export default {
  name: 'BulkTrafficDecisionPanel',
  components: { SelectStatusesModal, QuillEditor, vSelect },
  mixins: [chargingpointCpoMixin, chargingpointsLoadMixin],

  props: {
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      trackChanges: true,
      initialDataValues: null,
      chargingPoints: [],
      data: {
        bulkTrafficDecisionText: '',
        cpos: [],
        phases: [],
        statuses: [],
        inProcess: '',
      },
      difference: false,
      processOptions: [
        {
          label: this.$t('bulkTrafficDecisionPanel.all'),
          value: 'all',
        },
        {
          label: this.$t('bulkTrafficDecisionPanel.yes'),
          value: 'in-process',
        },
        {
          label: this.$t('bulkTrafficDecisionPanel.no'),
          value: 'no-process',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('config', ['config']),
    ...mapGetters('access', ['getActiveMunicipality']),
    activePhases() {
      const oldPhases = [
        { value: 1, label: 'Fase 1' },
        { value: 2, label: 'Fase 2' },
      ]
      const newActivePhases =
        this.config.phases
          ?.filter(({ active }) => !!active)
          ?.map(({ uuid, label }) => ({ value: uuid, label })) ?? []
      return [...oldPhases, ...newActivePhases]
    },
    processOptionLabel() {
      return this.processOptions.find(
        (option) => option.value === this.data.inProcess,
      ).label
    },
    phaseOptions() {
      return this.activePhases.filter(
        ({ value }) => !this.data.phases?.includes(value),
      )
    },
    cpoOptions() {
      return this.cpoSelectOptions?.map(({ value, text }) => ({
        value,
        label: text,
      }))
    },
    statusOptions() {
      let statusesLang

      if (
        typeof localStorage !== 'undefined' &&
        localStorage.getItem('language')
      ) {
        const storedLanguage = localStorage.getItem('language')

        statusesLang =
          storedLanguage === 'nl'
            ? statusesWithLabelDutch
            : statusesWithLabelEnglish
      } else {
        statusesLang = statusesWithLabelEnglish
      }

      return Object.entries(statusesLang)?.map(([value, label]) => ({
        value,
        label,
      }))
    },
    selectedPhases: {
      get() {
        return this.activePhases.filter(({ value }) =>
          this.data.phases.includes(value),
        )
      },
      set(newPhases) {
        this.data.phases = newPhases?.map(({ value }) => value)
      },
    },

    cpos: {
      get() {
        return this.cpoOptions.filter(({ value }) =>
          this.data.cpos.includes(value),
        )
      },
      set(newCpos) {
        this.data.cpos = newCpos?.map(({ value }) => value)
      },
    },
    statuses: {
      get() {
        return this.statusOptions.filter(({ value }) =>
          this.data.statuses.includes(value),
        )
      },
      set(newStatuses) {
        this.data.statuses = newStatuses?.map(({ value }) => value)
      },
    },
  },
  watch: {
    getActiveMunicipality: {
      immediate: true,
      handler() {
        this.setValues()
      },
    },
    data: {
      handler(data) {
        this.difference = this.initialDataValues
          ? JSON.stringify(data) !== JSON.stringify(this.initialDataValues)
          : false

        this.$emit('input', { data: this.data, difference: this.difference })
        this.initialDataValues = JSON.parse(JSON.stringify(this.data))
      },
      deep: true,
    },
  },
  async created() {
    this.setValues()
  },
  methods: {
    async applyFilters() {
      await this.filterChargingPoints()

      this.generateHTML(this.chargingPoints)

      this.$emit('saveFilters')

      Vue.notify({
        type: 'success',
        title: 'Klaar!',
        text: 'Filters zijn succesvol toegepast.',
      })
    },
    setValues() {
      this.trackChanges = false
      this.data = this.values
      this.initialDataValues = JSON.parse(JSON.stringify(this.data))

      this.$nextTick(() => {
        this.trackChanges = true
      })
    },
    async filterChargingPoints() {
      const allChargingPoints =
        await this.$_chargingpointsLoadMixin_loadChargingPoints({
          code: this.getActiveMunicipality,
        })

      if (!allChargingPoints) return

      const { phases, cpos, statuses, inProcess } = this.data
      const hasFilters = {
        phase: phases.length > 0,
        cpo: cpos.length > 0,
        status: statuses.length > 0,
      }
      const activeFilters = Object.values(hasFilters).filter(Boolean).length

      this.chargingPoints = allChargingPoints.filter((point) => {
        const { properties, prio, workflowCaseRef, externalProcessId } =
          point.data
        const { status, stakeholders = [] } = properties
        const customPhase = Array.isArray(prio?.customPhase)
          ? prio.customPhase
          : prio?.customPhase
          ? [prio.customPhase]
          : []
        const cpo = stakeholders.find(({ type }) => type === 'cpo')?.uuid

        const matchesFilters =
          activeFilters <= 1 ||
          ((!hasFilters.phase ||
            customPhase.some((phase) => phases.includes(phase))) &&
            (!hasFilters.cpo || (cpo && cpos.includes(cpo))) &&
            (!hasFilters.status || statuses.includes(status)))

        const isInProcess = workflowCaseRef || externalProcessId
        if (
          (inProcess === 'in-process' && !isInProcess) ||
          (inProcess === 'no-process' && isInProcess)
        ) {
          return false
        }

        return matchesFilters
      })
    },
    generateHTML(chargingPoints) {
      const chargingPointsListHtml = chargingPoints
        .map(
          (point) =>
           { const addressObj = point.data.address

            if (!addressObj){
              return
            }

            const address =`${addressObj.street} ${addressObj.number}, ${addressObj.postalcode} ${addressObj.city}`

            return `<li>Laadpaal ID ${point.data.properties.id}: Twee parkeerplaatsen ter hoogte van de ${address}</li>` },
        )
        .join('')

      this.replaceChargingPointsList(chargingPointsListHtml)
    },
    replaceChargingPointsList(chargingPointsHtml) {
      const quill = this.$refs.quillEditor.$children[0].quill

      let chargingPointsList = quill.root.querySelector('.chargingPointsList')

      if (!chargingPointsList) {
        chargingPointsList = document.createElement('ul')
        chargingPointsList.className = 'chargingPointsList'
        quill.root.appendChild(chargingPointsList)
      }

      chargingPointsList.innerHTML = chargingPointsHtml
    },
    onProcessStateChange(newState) {
      this.data.inProcess = newState
    },
    handleStatusSelect({ selected }) {
      this.statuses = selected
    },
    handleEditorChange({ content }) {
      this.data.bulkTrafficDecisionText = content
    },
    exportDocx() {
      const quill = this.$refs.quillEditor.$children[0].quill
      const delta = quill.getContents()

      const converter = new QuillDeltaToHtmlConverter(delta.ops, {
        inlineStyles: true,
      })
      const htmlContent = converter.convert()

      const fullHtml = `
        <html>
          <body>${htmlContent}</body>
        </html>
      `
      const label = labelByCode({ code: this.getActiveMunicipality })

      const docxBlob = htmlDocx.asBlob(fullHtml)
      saveAs(docxBlob, `Verzamel VKB ${label}`)
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.vs__selected-options) {
  display: block;
}

:deep(.vs__selected-options input) {
  width: 100%;
}

:deep(.vs__actions) {
  cursor: pointer;
}

.panel {
  .aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }

  .main {
    font-size: 1.25rem;
  }

  .label {
    flex-shrink: 0;
    width: 175px;
  }
}
</style>
