const { isString } = require('../services/validation')
const formatSimpleAddress = ({ address }) => {
  const street = address?.street?.trim() || ''
  const number = address?.number || ''
  const suffix = address?.suffix || ''

  return `${street} ${number} ${suffix}`.replace(/\s,/gm, ',').trim() // remove unnecessary whitespaces
}

const formatFormattedAddress = ({ address, country }) => {
  const postalcode = address.postalcode || ''
  const city = address.city || ''
  country = country || 'Nederland' // todo:: substitute with data per deployment

  return `${formatSimpleAddress({ address })}, ${postalcode} ${city}, ${country}`.replace(/\s,/gm, ',').trim() // remove unnecessary whitespaces
}

const splitNumberAndSuffix = ({ number }) => {
  if (! number || ! isString(number)) {
    return { number }
  }

  let segments = number.split(/(\d+)/).filter(a => a !== '')
  number = segments.shift()
  let suffix = segments.join('').trim()
  if (suffix === '') {
    suffix = null
  }

  return {
    number,
    suffix,
  }
}

module.exports = {
  formatSimpleAddress,
  formatFormattedAddress,
  splitNumberAndSuffix,
}
