import { isProxyByCode, proxyCodesByCode } from '@/services/municipalities'
import { fetchCompressedData } from '@/helpers/api'
import { mapMutations, mapGetters } from 'vuex'
import { Bugfender } from '@bugfender/sdk'
import Vue from 'vue'

export default {
  data() {
    return {
      isLoadingFauna: false,
      allChargingpoints: [],
    }
  },
  computed: {
    ...mapGetters('planmode', ['getChargingPoints']),
  },
  methods: {
    ...mapMutations('planmode', [
      'setChargingPoints',
    ]),
    async $_chargingpointsLoadMixin_getChargingPointsByCodes ({ code, codes, token, after }) {
      if (!after) {
        this.allChargingpoints = []
      }

      const api = await fetch('/api/chargingpointsload', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({ after, codes, code }),
      })

      if (!api.ok) {
        return Vue.notify({
          type: 'error',
          title: 'Er is iets misgegaan!',
          text: 'De locaties konden niet worden geladen',
        })
      }

      const response = await api.json()

      this.allChargingpoints = [...this.allChargingpoints, ...response.chargingpoints]
      this.setChargingPoints({ chargingpoints: this.allChargingpoints })

      if (response.after) {
        // To avoid a newly Fauna generated 429 too many requests error //
        setTimeout (async () => {
          await this.$_chargingpointsLoadMixin_getChargingPointsByCodes ({ code, codes, token, after: response.after })
        }, 100)
        return
      }

      this.setChargingPoints({ chargingpoints: this.allChargingpoints })

      this.isLoadingFauna = false
      this.$store.dispatch('planmode/setLoadingState', { value: true })
    },

    async $_chargingpointsLoadMixin_getChargingPointsByCodeStatus({ code, statuses }) {
      const token = await this.$auth.getTokenSilently()

      const requests = statuses.map(status =>
        fetchCompressedData('/api/chargingpoints-by-code-status', {
          method: 'POST',
          headers: { authorization: 'Bearer ' + token },
          body: JSON.stringify({ code, status }),
        }).then(response => response.records || [])
         .catch(e => {
            Bugfender.error('$_chargingpointsLoadMixin_getChargingPointsByCodeStatus: ', e)
            Vue.notify({
              type: 'error',
              title: 'Er is iets misgegaan!',
              text: `De locaties voor status ${status} konden niet worden geladen`,
            })
            return []
        }),
      )

      try {
        const results = await Promise.all(requests)
        return results.flat()
      } catch (e) {
        Bugfender.error('$_chargingpointsLoadMixin_getChargingPointsByCodeStatus: ', e)
        Vue.notify({
          type: 'error',
          title: 'Er is iets misgegaan!',
          text: 'De locaties konden niet worden geladen',
        })
        return []
      }
    },

    /**
     * Load the charging points for a municipality by code
     */
    async $_chargingpointsLoadMixin_loadChargingPoints ({ code }) {
      if (this.getChargingPoints.length && this.getChargingPoints[0].data.code === code) {
        return this.getChargingPoints
      }

      this.isLoadingFauna = true
      this.$store.dispatch('planmode/setLoadingState', { value: false })

      let codes = [code]
      if (isProxyByCode({ code })) {
        codes = proxyCodesByCode({ code })
      }

      const token = await this.$auth.getTokenSilently()
      await this.$_chargingpointsLoadMixin_getChargingPointsByCodes({ code, codes, token })

      return this.getChargingPoints
    },
    /**
     * Check if specific import source exists within given municipality ()
     */
    async chargingpointsBySource ({ code, source }) {
      const token = await this.$auth.getTokenSilently()
      const api = await fetch('/api/chargingpoints_by_source', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ code, source }),
      })

      return api.ok && await api.json()
    },
  },
}
